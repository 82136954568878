import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AddDeployment = {
  accountId: Scalars['String']['input'];
  awsRegion: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ApiV1StackInput = {
  deploymentId: Scalars['String']['input'];
  excludeStageFromPath: Scalars['Boolean']['input'];
};

export type ApiV2StackInput = {
  deploymentId: Scalars['String']['input'];
};

export type CreateGitConnectionInput = {
  deploymentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  providerType: Scalars['String']['input'];
};

export type DeployAnyCfnStack = {
  deploymentId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  parameters: Scalars['String']['input'];
  template: Scalars['String']['input'];
};

export type DeployCdnStack = {
  altCertificateArn?: InputMaybe<Scalars['String']['input']>;
  altFullDomainName?: InputMaybe<Scalars['String']['input']>;
  buildspecPath: Scalars['String']['input'];
  buildtimeJson: Scalars['String']['input'];
  deploymentId: Scalars['String']['input'];
  gitBranch: Scalars['String']['input'];
  gitConnectionArn: Scalars['String']['input'];
  gitRepositoryId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  webHostname: Scalars['String']['input'];
};

export type DeployCfnStack = {
  buildspecPath: Scalars['String']['input'];
  buildtimeJson: Scalars['String']['input'];
  deploymentId: Scalars['String']['input'];
  gitBranch: Scalars['String']['input'];
  gitConnectionArn: Scalars['String']['input'];
  gitRepositoryId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type DeployDbStack = {
  alarmsEnabled: Scalars['Boolean']['input'];
  allocatedStorage: Scalars['Int']['input'];
  backupRetentionPeriod: Scalars['Int']['input'];
  createReplica: Scalars['Boolean']['input'];
  dbType: Scalars['String']['input'];
  deploymentId: Scalars['String']['input'];
  engineVersion: Scalars['String']['input'];
  family: Scalars['String']['input'];
  id: Scalars['String']['input'];
  instanceClass: Scalars['String']['input'];
  logRetentionDays: Scalars['Int']['input'];
  multiAz: Scalars['Boolean']['input'];
  snapshotId: Scalars['String']['input'];
};

export type DeployEcsFargateStack = {
  alarmsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  apiKeyRequired: Scalars['Boolean']['input'];
  apiResourceEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  apiV2ResourceEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  apiV2WsResourceEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  autoscaleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  buildspecPath?: InputMaybe<Scalars['String']['input']>;
  buildtimeJson: Scalars['String']['input'];
  cicdManualApproval?: InputMaybe<Scalars['Boolean']['input']>;
  deploymentId: Scalars['String']['input'];
  dockerfilePath?: InputMaybe<Scalars['String']['input']>;
  ecsTaskCpu: Scalars['Int']['input'];
  ecsTaskRam: Scalars['Int']['input'];
  gitBranch: Scalars['String']['input'];
  gitConnectionArn: Scalars['String']['input'];
  gitRepositoryId: Scalars['String']['input'];
  healthCheckPath?: InputMaybe<Scalars['String']['input']>;
  healthCheckPort?: InputMaybe<Scalars['Int']['input']>;
  iamAuthRequired: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  instanceCount: Scalars['Int']['input'];
  logRetentionDays?: InputMaybe<Scalars['Int']['input']>;
  mapToRootPath?: InputMaybe<Scalars['Boolean']['input']>;
  maxInstanceCount?: InputMaybe<Scalars['Int']['input']>;
  runtimeJson: Scalars['String']['input'];
  servicePort: Scalars['Int']['input'];
  stickySessionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  stickySessionType?: InputMaybe<Scalars['String']['input']>;
};

export type DeployEcsWsFargateStack = {
  alarmsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  autoscaleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  buildspecPath?: InputMaybe<Scalars['String']['input']>;
  buildtimeJson: Scalars['String']['input'];
  cicdManualApproval?: InputMaybe<Scalars['Boolean']['input']>;
  deploymentId: Scalars['String']['input'];
  dockerfilePath?: InputMaybe<Scalars['String']['input']>;
  ecsTaskCpu: Scalars['Int']['input'];
  ecsTaskRam: Scalars['Int']['input'];
  gitBranch: Scalars['String']['input'];
  gitConnectionArn: Scalars['String']['input'];
  gitRepositoryId: Scalars['String']['input'];
  healthCheckPath?: InputMaybe<Scalars['String']['input']>;
  healthCheckPort?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  instanceCount: Scalars['Int']['input'];
  logRetentionDays?: InputMaybe<Scalars['Int']['input']>;
  maxInstanceCount?: InputMaybe<Scalars['Int']['input']>;
  runtimeJson: Scalars['String']['input'];
  servicePort: Scalars['Int']['input'];
  stickySessionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  stickySessionType?: InputMaybe<Scalars['String']['input']>;
};

export type Deployment = {
  __typename?: 'Deployment';
  accountId?: Maybe<Scalars['String']['output']>;
  awsRegion?: Maybe<Scalars['String']['output']>;
  defaultAlarmsEnabled?: Maybe<Scalars['Boolean']['output']>;
  defaultDatabaseRetention?: Maybe<Scalars['Int']['output']>;
  defaultLogRetentionDays?: Maybe<Scalars['Int']['output']>;
  defaultRequireCicdManualApproval?: Maybe<Scalars['Boolean']['output']>;
  fullDomainName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type EditDeployment = {
  accountId: Scalars['String']['input'];
  awsRegion: Scalars['String']['input'];
  defaultAlarmsEnabled: Scalars['Boolean']['input'];
  defaultDatabaseRetention: Scalars['Int']['input'];
  defaultLogRetentionDays: Scalars['Int']['input'];
  defaultRequireCicdManualApproval: Scalars['Boolean']['input'];
  fullDomainName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type EditOrganization = {
  apiV1Enabled: Scalars['Boolean']['input'];
  customDomainEnabled: Scalars['Boolean']['input'];
  databaseEnabled: Scalars['Boolean']['input'];
  httpApiEnabled: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  onboardingComplete: Scalars['Boolean']['input'];
  singleDeployment: Scalars['Boolean']['input'];
  workInProgressFeaturesEnabled: Scalars['Boolean']['input'];
  wsApiEnabled: Scalars['Boolean']['input'];
};

export type EditServiceStack = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type Features = {
  __typename?: 'Features';
  apiV1Enabled?: Maybe<Scalars['Boolean']['output']>;
  customDomainEnabled?: Maybe<Scalars['Boolean']['output']>;
  databaseEnabled?: Maybe<Scalars['Boolean']['output']>;
  httpApiEnabled?: Maybe<Scalars['Boolean']['output']>;
  singleDeployment?: Maybe<Scalars['Boolean']['output']>;
  workInProgressFeaturesEnabled?: Maybe<Scalars['Boolean']['output']>;
  wsApiEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type FullDomainNameCertificate = {
  __typename?: 'FullDomainNameCertificate';
  arn?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type FullDomainNameHostedZone = {
  __typename?: 'FullDomainNameHostedZone';
  hostedZoneId?: Maybe<Scalars['String']['output']>;
  nameServers?: Maybe<Array<Scalars['String']['output']>>;
};

export type GitConnection = {
  __typename?: 'GitConnection';
  connectionStatus?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerAccountId?: Maybe<Scalars['String']['output']>;
  pendingConnectionLink?: Maybe<Scalars['String']['output']>;
  providerType?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addMyDeployment?: Maybe<Deployment>;
  addMyServiceStack?: Maybe<ServiceStack>;
  addOrganization?: Maybe<Organization>;
  createMyAlertsStack?: Maybe<Scalars['String']['output']>;
  createMyApiStack?: Maybe<Scalars['String']['output']>;
  createMyAuthenticationStack?: Maybe<Scalars['String']['output']>;
  createMyCertificateCnameRecord?: Maybe<Scalars['String']['output']>;
  createMyDeploymentStack?: Maybe<Scalars['String']['output']>;
  createMyFullDomainNameHostedZone?: Maybe<Scalars['String']['output']>;
  createMyGitConnection?: Maybe<GitConnection>;
  createMyHttpApiStack?: Maybe<Scalars['String']['output']>;
  createMyVpcStack?: Maybe<Scalars['String']['output']>;
  createMyWaffleFullDomainNameHostedZone?: Maybe<Scalars['String']['output']>;
  createMyWaffleFullDomainNameNsRecord?: Maybe<Scalars['String']['output']>;
  createMyWsApiStack?: Maybe<Scalars['String']['output']>;
  deployMyAnyCfnStack?: Maybe<Scalars['String']['output']>;
  deployMyApiGatewayStage?: Maybe<Scalars['String']['output']>;
  deployMyApiStack?: Maybe<Scalars['String']['output']>;
  deployMyAuthenticationStack?: Maybe<Scalars['String']['output']>;
  deployMyCdnStack?: Maybe<Scalars['String']['output']>;
  deployMyCfnStack?: Maybe<Scalars['String']['output']>;
  deployMyDbStack?: Maybe<Scalars['String']['output']>;
  deployMyEcsFargateStack?: Maybe<Scalars['String']['output']>;
  deployMyEcsWsFargateStack?: Maybe<Scalars['String']['output']>;
  deployMyHttpApiStack?: Maybe<Scalars['String']['output']>;
  deployMyWsApiStack?: Maybe<Scalars['String']['output']>;
  editMyDeployment?: Maybe<Deployment>;
  editMyServiceStack?: Maybe<ServiceStack>;
  editOrganization?: Maybe<Organization>;
  inviteMyTeamMember?: Maybe<User>;
  removeMyCdnStack?: Maybe<Scalars['String']['output']>;
  removeMyCfnStack?: Maybe<Scalars['String']['output']>;
  removeMyDbStack?: Maybe<Scalars['String']['output']>;
  removeMyEcsFargateStack?: Maybe<Scalars['String']['output']>;
  removeMyEcsWsFargateStack?: Maybe<Scalars['String']['output']>;
  requestMyCertificate?: Maybe<Scalars['String']['output']>;
  setMyOnboardingComplete?: Maybe<Scalars['Boolean']['output']>;
  setMyOrganization?: Maybe<Organization>;
};


export type MutationAddMyDeploymentArgs = {
  deploymentInput: AddDeployment;
};


export type MutationAddMyServiceStackArgs = {
  stackId: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationAddOrganizationArgs = {
  organizationId: Scalars['String']['input'];
};


export type MutationCreateMyAlertsStackArgs = {
  deploymentId: Scalars['String']['input'];
  emailNotificationList: Scalars['String']['input'];
};


export type MutationCreateMyApiStackArgs = {
  apiStackInput: ApiV1StackInput;
};


export type MutationCreateMyAuthenticationStackArgs = {
  allowAdminCreateUserOnly: Scalars['Boolean']['input'];
  deploymentId: Scalars['String']['input'];
};


export type MutationCreateMyCertificateCnameRecordArgs = {
  deploymentId: Scalars['String']['input'];
};


export type MutationCreateMyDeploymentStackArgs = {
  deploymentId: Scalars['String']['input'];
};


export type MutationCreateMyFullDomainNameHostedZoneArgs = {
  deploymentId: Scalars['String']['input'];
  fullDomainName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateMyGitConnectionArgs = {
  gitConnectionInput: CreateGitConnectionInput;
};


export type MutationCreateMyHttpApiStackArgs = {
  apiStackInput: ApiV2StackInput;
};


export type MutationCreateMyVpcStackArgs = {
  deploymentId: Scalars['String']['input'];
  subnetIp?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreateMyWaffleFullDomainNameHostedZoneArgs = {
  deploymentId: Scalars['String']['input'];
};


export type MutationCreateMyWaffleFullDomainNameNsRecordArgs = {
  deploymentId: Scalars['String']['input'];
  nameServers: Array<Scalars['String']['input']>;
};


export type MutationCreateMyWsApiStackArgs = {
  apiStackInput: ApiV2StackInput;
};


export type MutationDeployMyAnyCfnStackArgs = {
  stackDeploymentInput: DeployAnyCfnStack;
};


export type MutationDeployMyApiGatewayStageArgs = {
  deploymentId: Scalars['String']['input'];
};


export type MutationDeployMyApiStackArgs = {
  apiStackInput: ApiV1StackInput;
};


export type MutationDeployMyAuthenticationStackArgs = {
  allowAdminCreateUserOnly: Scalars['Boolean']['input'];
  deploymentId: Scalars['String']['input'];
};


export type MutationDeployMyCdnStackArgs = {
  stackDeploymentInput: DeployCdnStack;
};


export type MutationDeployMyCfnStackArgs = {
  stackDeploymentInput: DeployCfnStack;
};


export type MutationDeployMyDbStackArgs = {
  stackDeploymentInput: DeployDbStack;
};


export type MutationDeployMyEcsFargateStackArgs = {
  stackDeploymentInput: DeployEcsFargateStack;
};


export type MutationDeployMyEcsWsFargateStackArgs = {
  stackDeploymentInput: DeployEcsWsFargateStack;
};


export type MutationDeployMyHttpApiStackArgs = {
  apiStackInput: ApiV2StackInput;
};


export type MutationDeployMyWsApiStackArgs = {
  apiStackInput: ApiV2StackInput;
};


export type MutationEditMyDeploymentArgs = {
  deploymentInput: EditDeployment;
};


export type MutationEditMyServiceStackArgs = {
  stackInput: EditServiceStack;
};


export type MutationEditOrganizationArgs = {
  organizationInput: EditOrganization;
};


export type MutationInviteMyTeamMemberArgs = {
  email: Scalars['String']['input'];
};


export type MutationRemoveMyCdnStackArgs = {
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRemoveMyCfnStackArgs = {
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRemoveMyDbStackArgs = {
  databaseId: Scalars['String']['input'];
  deploymentId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRemoveMyEcsFargateStackArgs = {
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRemoveMyEcsWsFargateStackArgs = {
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRequestMyCertificateArgs = {
  deploymentId: Scalars['String']['input'];
};


export type MutationSetMyOnboardingCompleteArgs = {
  complete: Scalars['Boolean']['input'];
};


export type MutationSetMyOrganizationArgs = {
  organizationName: Scalars['String']['input'];
};

export type Organization = {
  __typename?: 'Organization';
  features?: Maybe<Features>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  onboardingComplete?: Maybe<Scalars['Boolean']['output']>;
};

export type Query = {
  __typename?: 'Query';
  findMyAlertsStackValues?: Maybe<Scalars['String']['output']>;
  findMyApiStackValues?: Maybe<Scalars['String']['output']>;
  findMyAuthenticationStackValues?: Maybe<Scalars['String']['output']>;
  findMyDbStackId?: Maybe<Scalars['String']['output']>;
  findMyDeploymentStackValues?: Maybe<Scalars['String']['output']>;
  findMyHttpApiStackValues?: Maybe<Scalars['String']['output']>;
  findMyServiceStackId?: Maybe<Scalars['String']['output']>;
  findMyVpcStackValues?: Maybe<Scalars['String']['output']>;
  findMyWsApiStackValues?: Maybe<Scalars['String']['output']>;
  getAllOrganizations?: Maybe<Array<Organization>>;
  getMyAlertsStackStatus?: Maybe<StackStatus>;
  getMyApiStackStatus?: Maybe<StackStatus>;
  getMyAuthenticationStackStatus?: Maybe<StackStatus>;
  getMyCdnServiceStackStatus?: Maybe<StackStatus>;
  getMyCertificate?: Maybe<FullDomainNameCertificate>;
  getMyCertificateCnameRecordStatus?: Maybe<Scalars['String']['output']>;
  getMyCfnServiceStackStatus?: Maybe<StackStatus>;
  getMyCfnStackIds: Array<Scalars['String']['output']>;
  getMyCfnStackParameters?: Maybe<Scalars['String']['output']>;
  getMyCfnStackStatus?: Maybe<StackStatus>;
  getMyCfnStackTemplate?: Maybe<Scalars['String']['output']>;
  getMyDbEngineVersions?: Maybe<Array<Scalars['String']['output']>>;
  getMyDbFamilies?: Maybe<Array<Scalars['String']['output']>>;
  getMyDbInstanceClasses?: Maybe<Array<Scalars['String']['output']>>;
  getMyDbServiceStackStatus?: Maybe<StackStatus>;
  getMyDbSnapshotIds?: Maybe<Array<Scalars['String']['output']>>;
  getMyDeploymentStackStatus?: Maybe<StackStatus>;
  getMyDeployments?: Maybe<Array<Deployment>>;
  getMyEcsServiceStackStatus?: Maybe<StackStatus>;
  getMyEcsWsServiceStackStatus?: Maybe<StackStatus>;
  getMyFullDomainNameHostedZoneStatus?: Maybe<Scalars['String']['output']>;
  getMyFullDomainNameHostedZones?: Maybe<Array<FullDomainNameHostedZone>>;
  getMyGitConnections?: Maybe<Array<GitConnection>>;
  getMyHttpApiStackStatus?: Maybe<StackStatus>;
  getMyOrganization?: Maybe<Organization>;
  getMyServiceStacks?: Maybe<Array<ServiceStack>>;
  getMyTeamMembers?: Maybe<Array<User>>;
  getMyVpcStackStatus?: Maybe<StackStatus>;
  getMyWaffleCfnTemplate?: Maybe<Scalars['String']['output']>;
  getMyWaffleFullDomainNameNsRecord?: Maybe<Scalars['Boolean']['output']>;
  getMyWaffleFullDomainNameNsRecordStatus?: Maybe<Scalars['String']['output']>;
  getMyWsApiStackStatus?: Maybe<StackStatus>;
  getOrganization?: Maybe<Organization>;
  getWcaUserById?: Maybe<User>;
  helloWorld?: Maybe<Scalars['String']['output']>;
  testMyWaffleRole?: Maybe<TestResult>;
  whoAmI?: Maybe<User>;
};


export type QueryFindMyAlertsStackValuesArgs = {
  deploymentId: Scalars['String']['input'];
};


export type QueryFindMyApiStackValuesArgs = {
  deploymentId: Scalars['String']['input'];
};


export type QueryFindMyAuthenticationStackValuesArgs = {
  deploymentId: Scalars['String']['input'];
};


export type QueryFindMyDbStackIdArgs = {
  databaseId: Scalars['String']['input'];
  deploymentId: Scalars['String']['input'];
};


export type QueryFindMyDeploymentStackValuesArgs = {
  deploymentId: Scalars['String']['input'];
};


export type QueryFindMyHttpApiStackValuesArgs = {
  deploymentId: Scalars['String']['input'];
};


export type QueryFindMyServiceStackIdArgs = {
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
};


export type QueryFindMyVpcStackValuesArgs = {
  deploymentId: Scalars['String']['input'];
};


export type QueryFindMyWsApiStackValuesArgs = {
  deploymentId: Scalars['String']['input'];
};


export type QueryGetMyAlertsStackStatusArgs = {
  deploymentId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMyApiStackStatusArgs = {
  deploymentId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMyAuthenticationStackStatusArgs = {
  deploymentId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMyCdnServiceStackStatusArgs = {
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMyCertificateArgs = {
  deploymentId: Scalars['String']['input'];
};


export type QueryGetMyCertificateCnameRecordStatusArgs = {
  changeInfoId: Scalars['String']['input'];
  deploymentId: Scalars['String']['input'];
};


export type QueryGetMyCfnServiceStackStatusArgs = {
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMyCfnStackIdsArgs = {
  deploymentId: Scalars['String']['input'];
};


export type QueryGetMyCfnStackParametersArgs = {
  deploymentId: Scalars['String']['input'];
  stackId: Scalars['String']['input'];
};


export type QueryGetMyCfnStackStatusArgs = {
  deploymentId: Scalars['String']['input'];
  stackId: Scalars['String']['input'];
};


export type QueryGetMyCfnStackTemplateArgs = {
  deploymentId: Scalars['String']['input'];
  stackId: Scalars['String']['input'];
};


export type QueryGetMyDbEngineVersionsArgs = {
  dbType: Scalars['String']['input'];
  deploymentId: Scalars['String']['input'];
  family: Scalars['String']['input'];
};


export type QueryGetMyDbFamiliesArgs = {
  dbType: Scalars['String']['input'];
  deploymentId: Scalars['String']['input'];
};


export type QueryGetMyDbInstanceClassesArgs = {
  dbType: Scalars['String']['input'];
  deploymentId: Scalars['String']['input'];
  engineVersion: Scalars['String']['input'];
};


export type QueryGetMyDbServiceStackStatusArgs = {
  databaseId: Scalars['String']['input'];
  deploymentId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMyDbSnapshotIdsArgs = {
  deploymentId: Scalars['String']['input'];
};


export type QueryGetMyDeploymentStackStatusArgs = {
  deploymentId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMyEcsServiceStackStatusArgs = {
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMyEcsWsServiceStackStatusArgs = {
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMyFullDomainNameHostedZoneStatusArgs = {
  changeInfoId: Scalars['String']['input'];
  deploymentId: Scalars['String']['input'];
};


export type QueryGetMyFullDomainNameHostedZonesArgs = {
  deploymentId: Scalars['String']['input'];
};


export type QueryGetMyGitConnectionsArgs = {
  deploymentId: Scalars['String']['input'];
};


export type QueryGetMyHttpApiStackStatusArgs = {
  deploymentId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMyVpcStackStatusArgs = {
  deploymentId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMyWaffleCfnTemplateArgs = {
  deploymentId: Scalars['String']['input'];
};


export type QueryGetMyWaffleFullDomainNameNsRecordArgs = {
  deploymentId: Scalars['String']['input'];
};


export type QueryGetMyWaffleFullDomainNameNsRecordStatusArgs = {
  changeInfoId: Scalars['String']['input'];
  deploymentId: Scalars['String']['input'];
};


export type QueryGetMyWsApiStackStatusArgs = {
  deploymentId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetOrganizationArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetWcaUserByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryTestMyWaffleRoleArgs = {
  deploymentId: Scalars['String']['input'];
};

export type ServiceStack = {
  __typename?: 'ServiceStack';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type StackStatus = {
  __typename?: 'StackStatus';
  parametersJSON?: Maybe<Scalars['String']['output']>;
  stackId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type TestResult = {
  __typename?: 'TestResult';
  details?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  passed: Scalars['Boolean']['output'];
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type AddMyDeploymentMutationVariables = Exact<{
  deploymentInput: AddDeployment;
}>;


export type AddMyDeploymentMutation = { __typename?: 'Mutation', addMyDeployment?: { __typename?: 'Deployment', id: string, name?: string | null, accountId?: string | null, awsRegion?: string | null, defaultLogRetentionDays?: number | null, defaultAlarmsEnabled?: boolean | null, defaultDatabaseRetention?: number | null, defaultRequireCicdManualApproval?: boolean | null, fullDomainName?: string | null } | null };

export type CreateMyAlertsStackMutationVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  emailNotificationList: Scalars['String']['input'];
}>;


export type CreateMyAlertsStackMutation = { __typename?: 'Mutation', createMyAlertsStack?: string | null };

export type CreateMyApiStackMutationVariables = Exact<{
  apiStackInput: ApiV1StackInput;
}>;


export type CreateMyApiStackMutation = { __typename?: 'Mutation', createMyApiStack?: string | null };

export type CreateMyAuthenticationStackMutationVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  allowAdminCreateUserOnly: Scalars['Boolean']['input'];
}>;


export type CreateMyAuthenticationStackMutation = { __typename?: 'Mutation', createMyAuthenticationStack?: string | null };

export type CreateMyCertificateCnameRecordMutationVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type CreateMyCertificateCnameRecordMutation = { __typename?: 'Mutation', createMyCertificateCnameRecord?: string | null };

export type CreateMyDeploymentStackMutationVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type CreateMyDeploymentStackMutation = { __typename?: 'Mutation', createMyDeploymentStack?: string | null };

export type CreateMyFullDomainNameHostedZoneMutationVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  fullDomainName?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateMyFullDomainNameHostedZoneMutation = { __typename?: 'Mutation', createMyFullDomainNameHostedZone?: string | null };

export type CreateMyGitConnectionMutationVariables = Exact<{
  gitConnectionInput: CreateGitConnectionInput;
}>;


export type CreateMyGitConnectionMutation = { __typename?: 'Mutation', createMyGitConnection?: { __typename?: 'GitConnection', id?: string | null, name?: string | null, connectionStatus?: string | null, ownerAccountId?: string | null, providerType?: string | null, pendingConnectionLink?: string | null } | null };

export type CreateMyHttpApiStackMutationVariables = Exact<{
  apiStackInput: ApiV2StackInput;
}>;


export type CreateMyHttpApiStackMutation = { __typename?: 'Mutation', createMyHttpApiStack?: string | null };

export type CreateMyVpcStackMutationVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  subnetIp?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CreateMyVpcStackMutation = { __typename?: 'Mutation', createMyVpcStack?: string | null };

export type CreateMyWaffleFullDomainNameHostedZoneMutationVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type CreateMyWaffleFullDomainNameHostedZoneMutation = { __typename?: 'Mutation', createMyWaffleFullDomainNameHostedZone?: string | null };

export type CreateMyWaffleFullDomainNameNsRecordMutationVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  nameServers: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type CreateMyWaffleFullDomainNameNsRecordMutation = { __typename?: 'Mutation', createMyWaffleFullDomainNameNsRecord?: string | null };

export type CreateMyWsApiStackMutationVariables = Exact<{
  apiStackInput: ApiV2StackInput;
}>;


export type CreateMyWsApiStackMutation = { __typename?: 'Mutation', createMyWsApiStack?: string | null };

export type DeployMyApiGatewayStageMutationVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type DeployMyApiGatewayStageMutation = { __typename?: 'Mutation', deployMyApiGatewayStage?: string | null };

export type DeployMyApiStackMutationVariables = Exact<{
  apiStackInput: ApiV1StackInput;
}>;


export type DeployMyApiStackMutation = { __typename?: 'Mutation', deployMyApiStack?: string | null };

export type DeployMyAuthenticationStackMutationVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  allowAdminCreateUserOnly: Scalars['Boolean']['input'];
}>;


export type DeployMyAuthenticationStackMutation = { __typename?: 'Mutation', deployMyAuthenticationStack?: string | null };

export type DeployMyHttpApiStackMutationVariables = Exact<{
  apiStackInput: ApiV2StackInput;
}>;


export type DeployMyHttpApiStackMutation = { __typename?: 'Mutation', deployMyHttpApiStack?: string | null };

export type DeployMyWsApiStackMutationVariables = Exact<{
  apiStackInput: ApiV2StackInput;
}>;


export type DeployMyWsApiStackMutation = { __typename?: 'Mutation', deployMyWsApiStack?: string | null };

export type EditMyDeploymentMutationVariables = Exact<{
  deploymentInput: EditDeployment;
}>;


export type EditMyDeploymentMutation = { __typename?: 'Mutation', editMyDeployment?: { __typename?: 'Deployment', id: string, name?: string | null, accountId?: string | null, awsRegion?: string | null, defaultLogRetentionDays?: number | null, defaultAlarmsEnabled?: boolean | null, defaultDatabaseRetention?: number | null, defaultRequireCicdManualApproval?: boolean | null, fullDomainName?: string | null } | null };

export type FindMyAlertsStackValuesQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type FindMyAlertsStackValuesQuery = { __typename?: 'Query', findMyAlertsStackValues?: string | null };

export type FindMyApiStackValuesQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type FindMyApiStackValuesQuery = { __typename?: 'Query', findMyApiStackValues?: string | null };

export type FindMyAuthenticationStackValuesQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type FindMyAuthenticationStackValuesQuery = { __typename?: 'Query', findMyAuthenticationStackValues?: string | null };

export type FindMyDeploymentStackValuesQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type FindMyDeploymentStackValuesQuery = { __typename?: 'Query', findMyDeploymentStackValues?: string | null };

export type FindMyHttpApiStackValuesQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type FindMyHttpApiStackValuesQuery = { __typename?: 'Query', findMyHttpApiStackValues?: string | null };

export type FindMyVpcStackValuesQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type FindMyVpcStackValuesQuery = { __typename?: 'Query', findMyVpcStackValues?: string | null };

export type FindMyWsApiStackValuesQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type FindMyWsApiStackValuesQuery = { __typename?: 'Query', findMyWsApiStackValues?: string | null };

export type GetMyAlertsStackStatusQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetMyAlertsStackStatusQuery = { __typename?: 'Query', getMyAlertsStackStatus?: { __typename?: 'StackStatus', status?: string | null, parametersJSON?: string | null, stackId?: string | null } | null };

export type GetMyApiStackStatusQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetMyApiStackStatusQuery = { __typename?: 'Query', getMyApiStackStatus?: { __typename?: 'StackStatus', status?: string | null, parametersJSON?: string | null, stackId?: string | null } | null };

export type GetMyAuthenticationStackStatusQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetMyAuthenticationStackStatusQuery = { __typename?: 'Query', getMyAuthenticationStackStatus?: { __typename?: 'StackStatus', status?: string | null, parametersJSON?: string | null, stackId?: string | null } | null };

export type GetMyCertificateQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type GetMyCertificateQuery = { __typename?: 'Query', getMyCertificate?: { __typename?: 'FullDomainNameCertificate', arn?: string | null, status?: string | null } | null };

export type GetMyCertificateCnameRecordStatusQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  changeInfoId: Scalars['String']['input'];
}>;


export type GetMyCertificateCnameRecordStatusQuery = { __typename?: 'Query', getMyCertificateCnameRecordStatus?: string | null };

export type GetMyDeploymentStackStatusQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetMyDeploymentStackStatusQuery = { __typename?: 'Query', getMyDeploymentStackStatus?: { __typename?: 'StackStatus', status?: string | null, parametersJSON?: string | null, stackId?: string | null } | null };

export type GetMyDeploymentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyDeploymentsQuery = { __typename?: 'Query', getMyDeployments?: Array<{ __typename?: 'Deployment', id: string, name?: string | null, accountId?: string | null, awsRegion?: string | null, defaultLogRetentionDays?: number | null, defaultAlarmsEnabled?: boolean | null, defaultDatabaseRetention?: number | null, defaultRequireCicdManualApproval?: boolean | null, fullDomainName?: string | null }> | null };

export type GetMyFullDomainNameHostedZoneStatusQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  changeInfoId: Scalars['String']['input'];
}>;


export type GetMyFullDomainNameHostedZoneStatusQuery = { __typename?: 'Query', getMyFullDomainNameHostedZoneStatus?: string | null };

export type GetMyFullDomainNameHostedZonesQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type GetMyFullDomainNameHostedZonesQuery = { __typename?: 'Query', getMyFullDomainNameHostedZones?: Array<{ __typename?: 'FullDomainNameHostedZone', hostedZoneId?: string | null, nameServers?: Array<string> | null }> | null };

export type GetMyGitConnectionsQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type GetMyGitConnectionsQuery = { __typename?: 'Query', getMyGitConnections?: Array<{ __typename?: 'GitConnection', id?: string | null, name?: string | null, connectionStatus?: string | null, ownerAccountId?: string | null, providerType?: string | null, pendingConnectionLink?: string | null }> | null };

export type GetMyHttpApiStackStatusQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetMyHttpApiStackStatusQuery = { __typename?: 'Query', getMyHttpApiStackStatus?: { __typename?: 'StackStatus', status?: string | null, parametersJSON?: string | null, stackId?: string | null } | null };

export type GetMyVpcStackStatusQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetMyVpcStackStatusQuery = { __typename?: 'Query', getMyVpcStackStatus?: { __typename?: 'StackStatus', status?: string | null, parametersJSON?: string | null, stackId?: string | null } | null };

export type GetMyWaffleCfnTemplateQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type GetMyWaffleCfnTemplateQuery = { __typename?: 'Query', getMyWaffleCfnTemplate?: string | null };

export type GetMyWaffleFullDomainNameNsRecordQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type GetMyWaffleFullDomainNameNsRecordQuery = { __typename?: 'Query', getMyWaffleFullDomainNameNsRecord?: boolean | null };

export type GetMyWaffleFullDomainNameNsRecordStatusQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  changeInfoId: Scalars['String']['input'];
}>;


export type GetMyWaffleFullDomainNameNsRecordStatusQuery = { __typename?: 'Query', getMyWaffleFullDomainNameNsRecordStatus?: string | null };

export type GetMyWsApiStackStatusQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetMyWsApiStackStatusQuery = { __typename?: 'Query', getMyWsApiStackStatus?: { __typename?: 'StackStatus', status?: string | null, parametersJSON?: string | null, stackId?: string | null } | null };

export type RequestMyCertificateMutationVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type RequestMyCertificateMutation = { __typename?: 'Mutation', requestMyCertificate?: string | null };

export type TestMyWaffleRoleQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type TestMyWaffleRoleQuery = { __typename?: 'Query', testMyWaffleRole?: { __typename?: 'TestResult', id: string, passed: boolean, details?: string | null } | null };

export type HelloWorldQueryVariables = Exact<{ [key: string]: never; }>;


export type HelloWorldQuery = { __typename?: 'Query', helloWorld?: string | null };

export type AddOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type AddOrganizationMutation = { __typename?: 'Mutation', addOrganization?: { __typename?: 'Organization', id: string, name: string } | null };

export type EditOrganizationMutationVariables = Exact<{
  organizationInput: EditOrganization;
}>;


export type EditOrganizationMutation = { __typename?: 'Mutation', editOrganization?: { __typename?: 'Organization', id: string, name: string, onboardingComplete?: boolean | null, features?: { __typename?: 'Features', workInProgressFeaturesEnabled?: boolean | null, singleDeployment?: boolean | null, databaseEnabled?: boolean | null, apiV1Enabled?: boolean | null, wsApiEnabled?: boolean | null, httpApiEnabled?: boolean | null, customDomainEnabled?: boolean | null } | null } | null };

export type GetAllOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllOrganizationsQuery = { __typename?: 'Query', getAllOrganizations?: Array<{ __typename?: 'Organization', id: string, name: string }> | null };

export type GetMyOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyOrganizationQuery = { __typename?: 'Query', getMyOrganization?: { __typename?: 'Organization', name: string, id: string, onboardingComplete?: boolean | null, features?: { __typename?: 'Features', workInProgressFeaturesEnabled?: boolean | null, singleDeployment?: boolean | null, databaseEnabled?: boolean | null, apiV1Enabled?: boolean | null, wsApiEnabled?: boolean | null, httpApiEnabled?: boolean | null, customDomainEnabled?: boolean | null } | null } | null };

export type GetOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type GetOrganizationQuery = { __typename?: 'Query', getOrganization?: { __typename?: 'Organization', id: string, name: string, onboardingComplete?: boolean | null, features?: { __typename?: 'Features', workInProgressFeaturesEnabled?: boolean | null, singleDeployment?: boolean | null, databaseEnabled?: boolean | null, apiV1Enabled?: boolean | null, wsApiEnabled?: boolean | null, httpApiEnabled?: boolean | null, customDomainEnabled?: boolean | null } | null } | null };

export type AddMyServiceStackMutationVariables = Exact<{
  stackId: Scalars['String']['input'];
  type: Scalars['String']['input'];
}>;


export type AddMyServiceStackMutation = { __typename?: 'Mutation', addMyServiceStack?: { __typename?: 'ServiceStack', id: string, name?: string | null, type: string } | null };

export type DeployMyAnyCfnStackMutationVariables = Exact<{
  stackDeploymentInput: DeployAnyCfnStack;
}>;


export type DeployMyAnyCfnStackMutation = { __typename?: 'Mutation', deployMyAnyCfnStack?: string | null };

export type DeployMyCdnStackMutationVariables = Exact<{
  stackDeploymentInput: DeployCdnStack;
}>;


export type DeployMyCdnStackMutation = { __typename?: 'Mutation', deployMyCdnStack?: string | null };

export type DeployMyCfnStackMutationVariables = Exact<{
  stackDeploymentInput: DeployCfnStack;
}>;


export type DeployMyCfnStackMutation = { __typename?: 'Mutation', deployMyCfnStack?: string | null };

export type DeployMyDbStackMutationVariables = Exact<{
  stackDeploymentInput: DeployDbStack;
}>;


export type DeployMyDbStackMutation = { __typename?: 'Mutation', deployMyDbStack?: string | null };

export type DeployMyEcsFargateStackMutationVariables = Exact<{
  stackDeploymentInput: DeployEcsFargateStack;
}>;


export type DeployMyEcsFargateStackMutation = { __typename?: 'Mutation', deployMyEcsFargateStack?: string | null };

export type DeployMyEcsWsFargateStackMutationVariables = Exact<{
  stackDeploymentInput: DeployEcsWsFargateStack;
}>;


export type DeployMyEcsWsFargateStackMutation = { __typename?: 'Mutation', deployMyEcsWsFargateStack?: string | null };

export type EditMyServiceStackMutationVariables = Exact<{
  stackInput: EditServiceStack;
}>;


export type EditMyServiceStackMutation = { __typename?: 'Mutation', editMyServiceStack?: { __typename?: 'ServiceStack', id: string, name?: string | null, type: string } | null };

export type GetMyCdnServiceStackStatusQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetMyCdnServiceStackStatusQuery = { __typename?: 'Query', getMyCdnServiceStackStatus?: { __typename?: 'StackStatus', status?: string | null, parametersJSON?: string | null, stackId?: string | null } | null };

export type GetMyCfnServiceStackStatusQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetMyCfnServiceStackStatusQuery = { __typename?: 'Query', getMyCfnServiceStackStatus?: { __typename?: 'StackStatus', status?: string | null, parametersJSON?: string | null, stackId?: string | null } | null };

export type GetMyCfnStackIdsQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type GetMyCfnStackIdsQuery = { __typename?: 'Query', getMyCfnStackIds: Array<string> };

export type GetMyCfnStackParametersQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  stackId: Scalars['String']['input'];
}>;


export type GetMyCfnStackParametersQuery = { __typename?: 'Query', getMyCfnStackParameters?: string | null };

export type GetMyCfnStackStatusQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  stackId: Scalars['String']['input'];
}>;


export type GetMyCfnStackStatusQuery = { __typename?: 'Query', getMyCfnStackStatus?: { __typename?: 'StackStatus', status?: string | null, parametersJSON?: string | null, stackId?: string | null } | null };

export type GetMyCfnStackTemplateQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  stackId: Scalars['String']['input'];
}>;


export type GetMyCfnStackTemplateQuery = { __typename?: 'Query', getMyCfnStackTemplate?: string | null };

export type GetMyDbEngineVersionsQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  dbType: Scalars['String']['input'];
  family: Scalars['String']['input'];
}>;


export type GetMyDbEngineVersionsQuery = { __typename?: 'Query', getMyDbEngineVersions?: Array<string> | null };

export type GetMyDbFamiliesQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  dbType: Scalars['String']['input'];
}>;


export type GetMyDbFamiliesQuery = { __typename?: 'Query', getMyDbFamilies?: Array<string> | null };

export type GetMyDbInstanceClassesQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  dbType: Scalars['String']['input'];
  engineVersion: Scalars['String']['input'];
}>;


export type GetMyDbInstanceClassesQuery = { __typename?: 'Query', getMyDbInstanceClasses?: Array<string> | null };

export type GetMyDbServiceStackStatusQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  databaseId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetMyDbServiceStackStatusQuery = { __typename?: 'Query', getMyDbServiceStackStatus?: { __typename?: 'StackStatus', status?: string | null, parametersJSON?: string | null, stackId?: string | null } | null };

export type GetMyDbSnapshotIdsQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
}>;


export type GetMyDbSnapshotIdsQuery = { __typename?: 'Query', getMyDbSnapshotIds?: Array<string> | null };

export type GetMyEcsServiceStackStatusQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetMyEcsServiceStackStatusQuery = { __typename?: 'Query', getMyEcsServiceStackStatus?: { __typename?: 'StackStatus', status?: string | null, parametersJSON?: string | null, stackId?: string | null } | null };

export type GetMyEcsWsServiceStackStatusQueryVariables = Exact<{
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
  stackId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetMyEcsWsServiceStackStatusQuery = { __typename?: 'Query', getMyEcsWsServiceStackStatus?: { __typename?: 'StackStatus', status?: string | null, parametersJSON?: string | null, stackId?: string | null } | null };

export type GetMyServiceStacksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyServiceStacksQuery = { __typename?: 'Query', getMyServiceStacks?: Array<{ __typename?: 'ServiceStack', id: string, name?: string | null, type: string }> | null };

export type RemoveMyCdnStackMutationVariables = Exact<{
  stackId?: InputMaybe<Scalars['String']['input']>;
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
}>;


export type RemoveMyCdnStackMutation = { __typename?: 'Mutation', removeMyCdnStack?: string | null };

export type RemoveMyCfnStackMutationVariables = Exact<{
  stackId?: InputMaybe<Scalars['String']['input']>;
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
}>;


export type RemoveMyCfnStackMutation = { __typename?: 'Mutation', removeMyCfnStack?: string | null };

export type RemoveMyDbStackMutationVariables = Exact<{
  stackId?: InputMaybe<Scalars['String']['input']>;
  deploymentId: Scalars['String']['input'];
  databaseId: Scalars['String']['input'];
}>;


export type RemoveMyDbStackMutation = { __typename?: 'Mutation', removeMyDbStack?: string | null };

export type RemoveMyEcsFargateStackMutationVariables = Exact<{
  stackId?: InputMaybe<Scalars['String']['input']>;
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
}>;


export type RemoveMyEcsFargateStackMutation = { __typename?: 'Mutation', removeMyEcsFargateStack?: string | null };

export type RemoveMyEcsWsFargateStackMutationVariables = Exact<{
  stackId?: InputMaybe<Scalars['String']['input']>;
  deploymentId: Scalars['String']['input'];
  pipelineId: Scalars['String']['input'];
}>;


export type RemoveMyEcsWsFargateStackMutation = { __typename?: 'Mutation', removeMyEcsWsFargateStack?: string | null };

export type GetMyTeamMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyTeamMembersQuery = { __typename?: 'Query', getMyTeamMembers?: Array<{ __typename?: 'User', id: string, name?: string | null, email?: string | null, status?: string | null }> | null };

export type InviteMyTeamMemberMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type InviteMyTeamMemberMutation = { __typename?: 'Mutation', inviteMyTeamMember?: { __typename?: 'User', id: string, name?: string | null, email?: string | null, status?: string | null } | null };

export type SetMyOrganizationMutationVariables = Exact<{
  organizationName: Scalars['String']['input'];
}>;


export type SetMyOrganizationMutation = { __typename?: 'Mutation', setMyOrganization?: { __typename?: 'Organization', id: string, name: string, onboardingComplete?: boolean | null, features?: { __typename?: 'Features', singleDeployment?: boolean | null } | null } | null };

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = { __typename?: 'Query', whoAmI?: { __typename?: 'User', id: string, name?: string | null, email?: string | null, phoneNumber?: string | null, picture?: string | null, role?: string | null, organizationId?: string | null } | null };


export const AddMyDeploymentDocument = gql`
    mutation AddMyDeployment($deploymentInput: AddDeployment!) {
  addMyDeployment(deploymentInput: $deploymentInput) {
    id
    name
    accountId
    awsRegion
    defaultLogRetentionDays
    defaultAlarmsEnabled
    defaultDatabaseRetention
    defaultRequireCicdManualApproval
    fullDomainName
  }
}
    `;
export type AddMyDeploymentMutationFn = Apollo.MutationFunction<AddMyDeploymentMutation, AddMyDeploymentMutationVariables>;

/**
 * __useAddMyDeploymentMutation__
 *
 * To run a mutation, you first call `useAddMyDeploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMyDeploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMyDeploymentMutation, { data, loading, error }] = useAddMyDeploymentMutation({
 *   variables: {
 *      deploymentInput: // value for 'deploymentInput'
 *   },
 * });
 */
export function useAddMyDeploymentMutation(baseOptions?: Apollo.MutationHookOptions<AddMyDeploymentMutation, AddMyDeploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMyDeploymentMutation, AddMyDeploymentMutationVariables>(AddMyDeploymentDocument, options);
      }
export type AddMyDeploymentMutationHookResult = ReturnType<typeof useAddMyDeploymentMutation>;
export type AddMyDeploymentMutationResult = Apollo.MutationResult<AddMyDeploymentMutation>;
export type AddMyDeploymentMutationOptions = Apollo.BaseMutationOptions<AddMyDeploymentMutation, AddMyDeploymentMutationVariables>;
export const CreateMyAlertsStackDocument = gql`
    mutation CreateMyAlertsStack($deploymentId: String!, $emailNotificationList: String!) {
  createMyAlertsStack(
    deploymentId: $deploymentId
    emailNotificationList: $emailNotificationList
  )
}
    `;
export type CreateMyAlertsStackMutationFn = Apollo.MutationFunction<CreateMyAlertsStackMutation, CreateMyAlertsStackMutationVariables>;

/**
 * __useCreateMyAlertsStackMutation__
 *
 * To run a mutation, you first call `useCreateMyAlertsStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyAlertsStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyAlertsStackMutation, { data, loading, error }] = useCreateMyAlertsStackMutation({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      emailNotificationList: // value for 'emailNotificationList'
 *   },
 * });
 */
export function useCreateMyAlertsStackMutation(baseOptions?: Apollo.MutationHookOptions<CreateMyAlertsStackMutation, CreateMyAlertsStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMyAlertsStackMutation, CreateMyAlertsStackMutationVariables>(CreateMyAlertsStackDocument, options);
      }
export type CreateMyAlertsStackMutationHookResult = ReturnType<typeof useCreateMyAlertsStackMutation>;
export type CreateMyAlertsStackMutationResult = Apollo.MutationResult<CreateMyAlertsStackMutation>;
export type CreateMyAlertsStackMutationOptions = Apollo.BaseMutationOptions<CreateMyAlertsStackMutation, CreateMyAlertsStackMutationVariables>;
export const CreateMyApiStackDocument = gql`
    mutation CreateMyApiStack($apiStackInput: ApiV1StackInput!) {
  createMyApiStack(apiStackInput: $apiStackInput)
}
    `;
export type CreateMyApiStackMutationFn = Apollo.MutationFunction<CreateMyApiStackMutation, CreateMyApiStackMutationVariables>;

/**
 * __useCreateMyApiStackMutation__
 *
 * To run a mutation, you first call `useCreateMyApiStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyApiStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyApiStackMutation, { data, loading, error }] = useCreateMyApiStackMutation({
 *   variables: {
 *      apiStackInput: // value for 'apiStackInput'
 *   },
 * });
 */
export function useCreateMyApiStackMutation(baseOptions?: Apollo.MutationHookOptions<CreateMyApiStackMutation, CreateMyApiStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMyApiStackMutation, CreateMyApiStackMutationVariables>(CreateMyApiStackDocument, options);
      }
export type CreateMyApiStackMutationHookResult = ReturnType<typeof useCreateMyApiStackMutation>;
export type CreateMyApiStackMutationResult = Apollo.MutationResult<CreateMyApiStackMutation>;
export type CreateMyApiStackMutationOptions = Apollo.BaseMutationOptions<CreateMyApiStackMutation, CreateMyApiStackMutationVariables>;
export const CreateMyAuthenticationStackDocument = gql`
    mutation CreateMyAuthenticationStack($deploymentId: String!, $allowAdminCreateUserOnly: Boolean!) {
  createMyAuthenticationStack(
    deploymentId: $deploymentId
    allowAdminCreateUserOnly: $allowAdminCreateUserOnly
  )
}
    `;
export type CreateMyAuthenticationStackMutationFn = Apollo.MutationFunction<CreateMyAuthenticationStackMutation, CreateMyAuthenticationStackMutationVariables>;

/**
 * __useCreateMyAuthenticationStackMutation__
 *
 * To run a mutation, you first call `useCreateMyAuthenticationStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyAuthenticationStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyAuthenticationStackMutation, { data, loading, error }] = useCreateMyAuthenticationStackMutation({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      allowAdminCreateUserOnly: // value for 'allowAdminCreateUserOnly'
 *   },
 * });
 */
export function useCreateMyAuthenticationStackMutation(baseOptions?: Apollo.MutationHookOptions<CreateMyAuthenticationStackMutation, CreateMyAuthenticationStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMyAuthenticationStackMutation, CreateMyAuthenticationStackMutationVariables>(CreateMyAuthenticationStackDocument, options);
      }
export type CreateMyAuthenticationStackMutationHookResult = ReturnType<typeof useCreateMyAuthenticationStackMutation>;
export type CreateMyAuthenticationStackMutationResult = Apollo.MutationResult<CreateMyAuthenticationStackMutation>;
export type CreateMyAuthenticationStackMutationOptions = Apollo.BaseMutationOptions<CreateMyAuthenticationStackMutation, CreateMyAuthenticationStackMutationVariables>;
export const CreateMyCertificateCnameRecordDocument = gql`
    mutation CreateMyCertificateCnameRecord($deploymentId: String!) {
  createMyCertificateCnameRecord(deploymentId: $deploymentId)
}
    `;
export type CreateMyCertificateCnameRecordMutationFn = Apollo.MutationFunction<CreateMyCertificateCnameRecordMutation, CreateMyCertificateCnameRecordMutationVariables>;

/**
 * __useCreateMyCertificateCnameRecordMutation__
 *
 * To run a mutation, you first call `useCreateMyCertificateCnameRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyCertificateCnameRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyCertificateCnameRecordMutation, { data, loading, error }] = useCreateMyCertificateCnameRecordMutation({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useCreateMyCertificateCnameRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateMyCertificateCnameRecordMutation, CreateMyCertificateCnameRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMyCertificateCnameRecordMutation, CreateMyCertificateCnameRecordMutationVariables>(CreateMyCertificateCnameRecordDocument, options);
      }
export type CreateMyCertificateCnameRecordMutationHookResult = ReturnType<typeof useCreateMyCertificateCnameRecordMutation>;
export type CreateMyCertificateCnameRecordMutationResult = Apollo.MutationResult<CreateMyCertificateCnameRecordMutation>;
export type CreateMyCertificateCnameRecordMutationOptions = Apollo.BaseMutationOptions<CreateMyCertificateCnameRecordMutation, CreateMyCertificateCnameRecordMutationVariables>;
export const CreateMyDeploymentStackDocument = gql`
    mutation CreateMyDeploymentStack($deploymentId: String!) {
  createMyDeploymentStack(deploymentId: $deploymentId)
}
    `;
export type CreateMyDeploymentStackMutationFn = Apollo.MutationFunction<CreateMyDeploymentStackMutation, CreateMyDeploymentStackMutationVariables>;

/**
 * __useCreateMyDeploymentStackMutation__
 *
 * To run a mutation, you first call `useCreateMyDeploymentStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyDeploymentStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyDeploymentStackMutation, { data, loading, error }] = useCreateMyDeploymentStackMutation({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useCreateMyDeploymentStackMutation(baseOptions?: Apollo.MutationHookOptions<CreateMyDeploymentStackMutation, CreateMyDeploymentStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMyDeploymentStackMutation, CreateMyDeploymentStackMutationVariables>(CreateMyDeploymentStackDocument, options);
      }
export type CreateMyDeploymentStackMutationHookResult = ReturnType<typeof useCreateMyDeploymentStackMutation>;
export type CreateMyDeploymentStackMutationResult = Apollo.MutationResult<CreateMyDeploymentStackMutation>;
export type CreateMyDeploymentStackMutationOptions = Apollo.BaseMutationOptions<CreateMyDeploymentStackMutation, CreateMyDeploymentStackMutationVariables>;
export const CreateMyFullDomainNameHostedZoneDocument = gql`
    mutation CreateMyFullDomainNameHostedZone($deploymentId: String!, $fullDomainName: String) {
  createMyFullDomainNameHostedZone(
    deploymentId: $deploymentId
    fullDomainName: $fullDomainName
  )
}
    `;
export type CreateMyFullDomainNameHostedZoneMutationFn = Apollo.MutationFunction<CreateMyFullDomainNameHostedZoneMutation, CreateMyFullDomainNameHostedZoneMutationVariables>;

/**
 * __useCreateMyFullDomainNameHostedZoneMutation__
 *
 * To run a mutation, you first call `useCreateMyFullDomainNameHostedZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyFullDomainNameHostedZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyFullDomainNameHostedZoneMutation, { data, loading, error }] = useCreateMyFullDomainNameHostedZoneMutation({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      fullDomainName: // value for 'fullDomainName'
 *   },
 * });
 */
export function useCreateMyFullDomainNameHostedZoneMutation(baseOptions?: Apollo.MutationHookOptions<CreateMyFullDomainNameHostedZoneMutation, CreateMyFullDomainNameHostedZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMyFullDomainNameHostedZoneMutation, CreateMyFullDomainNameHostedZoneMutationVariables>(CreateMyFullDomainNameHostedZoneDocument, options);
      }
export type CreateMyFullDomainNameHostedZoneMutationHookResult = ReturnType<typeof useCreateMyFullDomainNameHostedZoneMutation>;
export type CreateMyFullDomainNameHostedZoneMutationResult = Apollo.MutationResult<CreateMyFullDomainNameHostedZoneMutation>;
export type CreateMyFullDomainNameHostedZoneMutationOptions = Apollo.BaseMutationOptions<CreateMyFullDomainNameHostedZoneMutation, CreateMyFullDomainNameHostedZoneMutationVariables>;
export const CreateMyGitConnectionDocument = gql`
    mutation CreateMyGitConnection($gitConnectionInput: CreateGitConnectionInput!) {
  createMyGitConnection(gitConnectionInput: $gitConnectionInput) {
    id
    name
    connectionStatus
    ownerAccountId
    providerType
    pendingConnectionLink
  }
}
    `;
export type CreateMyGitConnectionMutationFn = Apollo.MutationFunction<CreateMyGitConnectionMutation, CreateMyGitConnectionMutationVariables>;

/**
 * __useCreateMyGitConnectionMutation__
 *
 * To run a mutation, you first call `useCreateMyGitConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyGitConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyGitConnectionMutation, { data, loading, error }] = useCreateMyGitConnectionMutation({
 *   variables: {
 *      gitConnectionInput: // value for 'gitConnectionInput'
 *   },
 * });
 */
export function useCreateMyGitConnectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateMyGitConnectionMutation, CreateMyGitConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMyGitConnectionMutation, CreateMyGitConnectionMutationVariables>(CreateMyGitConnectionDocument, options);
      }
export type CreateMyGitConnectionMutationHookResult = ReturnType<typeof useCreateMyGitConnectionMutation>;
export type CreateMyGitConnectionMutationResult = Apollo.MutationResult<CreateMyGitConnectionMutation>;
export type CreateMyGitConnectionMutationOptions = Apollo.BaseMutationOptions<CreateMyGitConnectionMutation, CreateMyGitConnectionMutationVariables>;
export const CreateMyHttpApiStackDocument = gql`
    mutation CreateMyHttpApiStack($apiStackInput: ApiV2StackInput!) {
  createMyHttpApiStack(apiStackInput: $apiStackInput)
}
    `;
export type CreateMyHttpApiStackMutationFn = Apollo.MutationFunction<CreateMyHttpApiStackMutation, CreateMyHttpApiStackMutationVariables>;

/**
 * __useCreateMyHttpApiStackMutation__
 *
 * To run a mutation, you first call `useCreateMyHttpApiStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyHttpApiStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyHttpApiStackMutation, { data, loading, error }] = useCreateMyHttpApiStackMutation({
 *   variables: {
 *      apiStackInput: // value for 'apiStackInput'
 *   },
 * });
 */
export function useCreateMyHttpApiStackMutation(baseOptions?: Apollo.MutationHookOptions<CreateMyHttpApiStackMutation, CreateMyHttpApiStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMyHttpApiStackMutation, CreateMyHttpApiStackMutationVariables>(CreateMyHttpApiStackDocument, options);
      }
export type CreateMyHttpApiStackMutationHookResult = ReturnType<typeof useCreateMyHttpApiStackMutation>;
export type CreateMyHttpApiStackMutationResult = Apollo.MutationResult<CreateMyHttpApiStackMutation>;
export type CreateMyHttpApiStackMutationOptions = Apollo.BaseMutationOptions<CreateMyHttpApiStackMutation, CreateMyHttpApiStackMutationVariables>;
export const CreateMyVpcStackDocument = gql`
    mutation CreateMyVpcStack($deploymentId: String!, $subnetIp: Int) {
  createMyVpcStack(deploymentId: $deploymentId, subnetIp: $subnetIp)
}
    `;
export type CreateMyVpcStackMutationFn = Apollo.MutationFunction<CreateMyVpcStackMutation, CreateMyVpcStackMutationVariables>;

/**
 * __useCreateMyVpcStackMutation__
 *
 * To run a mutation, you first call `useCreateMyVpcStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyVpcStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyVpcStackMutation, { data, loading, error }] = useCreateMyVpcStackMutation({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      subnetIp: // value for 'subnetIp'
 *   },
 * });
 */
export function useCreateMyVpcStackMutation(baseOptions?: Apollo.MutationHookOptions<CreateMyVpcStackMutation, CreateMyVpcStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMyVpcStackMutation, CreateMyVpcStackMutationVariables>(CreateMyVpcStackDocument, options);
      }
export type CreateMyVpcStackMutationHookResult = ReturnType<typeof useCreateMyVpcStackMutation>;
export type CreateMyVpcStackMutationResult = Apollo.MutationResult<CreateMyVpcStackMutation>;
export type CreateMyVpcStackMutationOptions = Apollo.BaseMutationOptions<CreateMyVpcStackMutation, CreateMyVpcStackMutationVariables>;
export const CreateMyWaffleFullDomainNameHostedZoneDocument = gql`
    mutation CreateMyWaffleFullDomainNameHostedZone($deploymentId: String!) {
  createMyWaffleFullDomainNameHostedZone(deploymentId: $deploymentId)
}
    `;
export type CreateMyWaffleFullDomainNameHostedZoneMutationFn = Apollo.MutationFunction<CreateMyWaffleFullDomainNameHostedZoneMutation, CreateMyWaffleFullDomainNameHostedZoneMutationVariables>;

/**
 * __useCreateMyWaffleFullDomainNameHostedZoneMutation__
 *
 * To run a mutation, you first call `useCreateMyWaffleFullDomainNameHostedZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyWaffleFullDomainNameHostedZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyWaffleFullDomainNameHostedZoneMutation, { data, loading, error }] = useCreateMyWaffleFullDomainNameHostedZoneMutation({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useCreateMyWaffleFullDomainNameHostedZoneMutation(baseOptions?: Apollo.MutationHookOptions<CreateMyWaffleFullDomainNameHostedZoneMutation, CreateMyWaffleFullDomainNameHostedZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMyWaffleFullDomainNameHostedZoneMutation, CreateMyWaffleFullDomainNameHostedZoneMutationVariables>(CreateMyWaffleFullDomainNameHostedZoneDocument, options);
      }
export type CreateMyWaffleFullDomainNameHostedZoneMutationHookResult = ReturnType<typeof useCreateMyWaffleFullDomainNameHostedZoneMutation>;
export type CreateMyWaffleFullDomainNameHostedZoneMutationResult = Apollo.MutationResult<CreateMyWaffleFullDomainNameHostedZoneMutation>;
export type CreateMyWaffleFullDomainNameHostedZoneMutationOptions = Apollo.BaseMutationOptions<CreateMyWaffleFullDomainNameHostedZoneMutation, CreateMyWaffleFullDomainNameHostedZoneMutationVariables>;
export const CreateMyWaffleFullDomainNameNsRecordDocument = gql`
    mutation CreateMyWaffleFullDomainNameNsRecord($deploymentId: String!, $nameServers: [String!]!) {
  createMyWaffleFullDomainNameNsRecord(
    deploymentId: $deploymentId
    nameServers: $nameServers
  )
}
    `;
export type CreateMyWaffleFullDomainNameNsRecordMutationFn = Apollo.MutationFunction<CreateMyWaffleFullDomainNameNsRecordMutation, CreateMyWaffleFullDomainNameNsRecordMutationVariables>;

/**
 * __useCreateMyWaffleFullDomainNameNsRecordMutation__
 *
 * To run a mutation, you first call `useCreateMyWaffleFullDomainNameNsRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyWaffleFullDomainNameNsRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyWaffleFullDomainNameNsRecordMutation, { data, loading, error }] = useCreateMyWaffleFullDomainNameNsRecordMutation({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      nameServers: // value for 'nameServers'
 *   },
 * });
 */
export function useCreateMyWaffleFullDomainNameNsRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateMyWaffleFullDomainNameNsRecordMutation, CreateMyWaffleFullDomainNameNsRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMyWaffleFullDomainNameNsRecordMutation, CreateMyWaffleFullDomainNameNsRecordMutationVariables>(CreateMyWaffleFullDomainNameNsRecordDocument, options);
      }
export type CreateMyWaffleFullDomainNameNsRecordMutationHookResult = ReturnType<typeof useCreateMyWaffleFullDomainNameNsRecordMutation>;
export type CreateMyWaffleFullDomainNameNsRecordMutationResult = Apollo.MutationResult<CreateMyWaffleFullDomainNameNsRecordMutation>;
export type CreateMyWaffleFullDomainNameNsRecordMutationOptions = Apollo.BaseMutationOptions<CreateMyWaffleFullDomainNameNsRecordMutation, CreateMyWaffleFullDomainNameNsRecordMutationVariables>;
export const CreateMyWsApiStackDocument = gql`
    mutation CreateMyWsApiStack($apiStackInput: ApiV2StackInput!) {
  createMyWsApiStack(apiStackInput: $apiStackInput)
}
    `;
export type CreateMyWsApiStackMutationFn = Apollo.MutationFunction<CreateMyWsApiStackMutation, CreateMyWsApiStackMutationVariables>;

/**
 * __useCreateMyWsApiStackMutation__
 *
 * To run a mutation, you first call `useCreateMyWsApiStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyWsApiStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyWsApiStackMutation, { data, loading, error }] = useCreateMyWsApiStackMutation({
 *   variables: {
 *      apiStackInput: // value for 'apiStackInput'
 *   },
 * });
 */
export function useCreateMyWsApiStackMutation(baseOptions?: Apollo.MutationHookOptions<CreateMyWsApiStackMutation, CreateMyWsApiStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMyWsApiStackMutation, CreateMyWsApiStackMutationVariables>(CreateMyWsApiStackDocument, options);
      }
export type CreateMyWsApiStackMutationHookResult = ReturnType<typeof useCreateMyWsApiStackMutation>;
export type CreateMyWsApiStackMutationResult = Apollo.MutationResult<CreateMyWsApiStackMutation>;
export type CreateMyWsApiStackMutationOptions = Apollo.BaseMutationOptions<CreateMyWsApiStackMutation, CreateMyWsApiStackMutationVariables>;
export const DeployMyApiGatewayStageDocument = gql`
    mutation DeployMyApiGatewayStage($deploymentId: String!) {
  deployMyApiGatewayStage(deploymentId: $deploymentId)
}
    `;
export type DeployMyApiGatewayStageMutationFn = Apollo.MutationFunction<DeployMyApiGatewayStageMutation, DeployMyApiGatewayStageMutationVariables>;

/**
 * __useDeployMyApiGatewayStageMutation__
 *
 * To run a mutation, you first call `useDeployMyApiGatewayStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployMyApiGatewayStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployMyApiGatewayStageMutation, { data, loading, error }] = useDeployMyApiGatewayStageMutation({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useDeployMyApiGatewayStageMutation(baseOptions?: Apollo.MutationHookOptions<DeployMyApiGatewayStageMutation, DeployMyApiGatewayStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeployMyApiGatewayStageMutation, DeployMyApiGatewayStageMutationVariables>(DeployMyApiGatewayStageDocument, options);
      }
export type DeployMyApiGatewayStageMutationHookResult = ReturnType<typeof useDeployMyApiGatewayStageMutation>;
export type DeployMyApiGatewayStageMutationResult = Apollo.MutationResult<DeployMyApiGatewayStageMutation>;
export type DeployMyApiGatewayStageMutationOptions = Apollo.BaseMutationOptions<DeployMyApiGatewayStageMutation, DeployMyApiGatewayStageMutationVariables>;
export const DeployMyApiStackDocument = gql`
    mutation DeployMyApiStack($apiStackInput: ApiV1StackInput!) {
  deployMyApiStack(apiStackInput: $apiStackInput)
}
    `;
export type DeployMyApiStackMutationFn = Apollo.MutationFunction<DeployMyApiStackMutation, DeployMyApiStackMutationVariables>;

/**
 * __useDeployMyApiStackMutation__
 *
 * To run a mutation, you first call `useDeployMyApiStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployMyApiStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployMyApiStackMutation, { data, loading, error }] = useDeployMyApiStackMutation({
 *   variables: {
 *      apiStackInput: // value for 'apiStackInput'
 *   },
 * });
 */
export function useDeployMyApiStackMutation(baseOptions?: Apollo.MutationHookOptions<DeployMyApiStackMutation, DeployMyApiStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeployMyApiStackMutation, DeployMyApiStackMutationVariables>(DeployMyApiStackDocument, options);
      }
export type DeployMyApiStackMutationHookResult = ReturnType<typeof useDeployMyApiStackMutation>;
export type DeployMyApiStackMutationResult = Apollo.MutationResult<DeployMyApiStackMutation>;
export type DeployMyApiStackMutationOptions = Apollo.BaseMutationOptions<DeployMyApiStackMutation, DeployMyApiStackMutationVariables>;
export const DeployMyAuthenticationStackDocument = gql`
    mutation DeployMyAuthenticationStack($deploymentId: String!, $allowAdminCreateUserOnly: Boolean!) {
  deployMyAuthenticationStack(
    deploymentId: $deploymentId
    allowAdminCreateUserOnly: $allowAdminCreateUserOnly
  )
}
    `;
export type DeployMyAuthenticationStackMutationFn = Apollo.MutationFunction<DeployMyAuthenticationStackMutation, DeployMyAuthenticationStackMutationVariables>;

/**
 * __useDeployMyAuthenticationStackMutation__
 *
 * To run a mutation, you first call `useDeployMyAuthenticationStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployMyAuthenticationStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployMyAuthenticationStackMutation, { data, loading, error }] = useDeployMyAuthenticationStackMutation({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      allowAdminCreateUserOnly: // value for 'allowAdminCreateUserOnly'
 *   },
 * });
 */
export function useDeployMyAuthenticationStackMutation(baseOptions?: Apollo.MutationHookOptions<DeployMyAuthenticationStackMutation, DeployMyAuthenticationStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeployMyAuthenticationStackMutation, DeployMyAuthenticationStackMutationVariables>(DeployMyAuthenticationStackDocument, options);
      }
export type DeployMyAuthenticationStackMutationHookResult = ReturnType<typeof useDeployMyAuthenticationStackMutation>;
export type DeployMyAuthenticationStackMutationResult = Apollo.MutationResult<DeployMyAuthenticationStackMutation>;
export type DeployMyAuthenticationStackMutationOptions = Apollo.BaseMutationOptions<DeployMyAuthenticationStackMutation, DeployMyAuthenticationStackMutationVariables>;
export const DeployMyHttpApiStackDocument = gql`
    mutation DeployMyHttpApiStack($apiStackInput: ApiV2StackInput!) {
  deployMyHttpApiStack(apiStackInput: $apiStackInput)
}
    `;
export type DeployMyHttpApiStackMutationFn = Apollo.MutationFunction<DeployMyHttpApiStackMutation, DeployMyHttpApiStackMutationVariables>;

/**
 * __useDeployMyHttpApiStackMutation__
 *
 * To run a mutation, you first call `useDeployMyHttpApiStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployMyHttpApiStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployMyHttpApiStackMutation, { data, loading, error }] = useDeployMyHttpApiStackMutation({
 *   variables: {
 *      apiStackInput: // value for 'apiStackInput'
 *   },
 * });
 */
export function useDeployMyHttpApiStackMutation(baseOptions?: Apollo.MutationHookOptions<DeployMyHttpApiStackMutation, DeployMyHttpApiStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeployMyHttpApiStackMutation, DeployMyHttpApiStackMutationVariables>(DeployMyHttpApiStackDocument, options);
      }
export type DeployMyHttpApiStackMutationHookResult = ReturnType<typeof useDeployMyHttpApiStackMutation>;
export type DeployMyHttpApiStackMutationResult = Apollo.MutationResult<DeployMyHttpApiStackMutation>;
export type DeployMyHttpApiStackMutationOptions = Apollo.BaseMutationOptions<DeployMyHttpApiStackMutation, DeployMyHttpApiStackMutationVariables>;
export const DeployMyWsApiStackDocument = gql`
    mutation DeployMyWsApiStack($apiStackInput: ApiV2StackInput!) {
  deployMyWsApiStack(apiStackInput: $apiStackInput)
}
    `;
export type DeployMyWsApiStackMutationFn = Apollo.MutationFunction<DeployMyWsApiStackMutation, DeployMyWsApiStackMutationVariables>;

/**
 * __useDeployMyWsApiStackMutation__
 *
 * To run a mutation, you first call `useDeployMyWsApiStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployMyWsApiStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployMyWsApiStackMutation, { data, loading, error }] = useDeployMyWsApiStackMutation({
 *   variables: {
 *      apiStackInput: // value for 'apiStackInput'
 *   },
 * });
 */
export function useDeployMyWsApiStackMutation(baseOptions?: Apollo.MutationHookOptions<DeployMyWsApiStackMutation, DeployMyWsApiStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeployMyWsApiStackMutation, DeployMyWsApiStackMutationVariables>(DeployMyWsApiStackDocument, options);
      }
export type DeployMyWsApiStackMutationHookResult = ReturnType<typeof useDeployMyWsApiStackMutation>;
export type DeployMyWsApiStackMutationResult = Apollo.MutationResult<DeployMyWsApiStackMutation>;
export type DeployMyWsApiStackMutationOptions = Apollo.BaseMutationOptions<DeployMyWsApiStackMutation, DeployMyWsApiStackMutationVariables>;
export const EditMyDeploymentDocument = gql`
    mutation EditMyDeployment($deploymentInput: EditDeployment!) {
  editMyDeployment(deploymentInput: $deploymentInput) {
    id
    name
    accountId
    awsRegion
    defaultLogRetentionDays
    defaultAlarmsEnabled
    defaultDatabaseRetention
    defaultRequireCicdManualApproval
    fullDomainName
  }
}
    `;
export type EditMyDeploymentMutationFn = Apollo.MutationFunction<EditMyDeploymentMutation, EditMyDeploymentMutationVariables>;

/**
 * __useEditMyDeploymentMutation__
 *
 * To run a mutation, you first call `useEditMyDeploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMyDeploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMyDeploymentMutation, { data, loading, error }] = useEditMyDeploymentMutation({
 *   variables: {
 *      deploymentInput: // value for 'deploymentInput'
 *   },
 * });
 */
export function useEditMyDeploymentMutation(baseOptions?: Apollo.MutationHookOptions<EditMyDeploymentMutation, EditMyDeploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMyDeploymentMutation, EditMyDeploymentMutationVariables>(EditMyDeploymentDocument, options);
      }
export type EditMyDeploymentMutationHookResult = ReturnType<typeof useEditMyDeploymentMutation>;
export type EditMyDeploymentMutationResult = Apollo.MutationResult<EditMyDeploymentMutation>;
export type EditMyDeploymentMutationOptions = Apollo.BaseMutationOptions<EditMyDeploymentMutation, EditMyDeploymentMutationVariables>;
export const FindMyAlertsStackValuesDocument = gql`
    query FindMyAlertsStackValues($deploymentId: String!) {
  findMyAlertsStackValues(deploymentId: $deploymentId)
}
    `;

/**
 * __useFindMyAlertsStackValuesQuery__
 *
 * To run a query within a React component, call `useFindMyAlertsStackValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMyAlertsStackValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMyAlertsStackValuesQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useFindMyAlertsStackValuesQuery(baseOptions: Apollo.QueryHookOptions<FindMyAlertsStackValuesQuery, FindMyAlertsStackValuesQueryVariables> & ({ variables: FindMyAlertsStackValuesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindMyAlertsStackValuesQuery, FindMyAlertsStackValuesQueryVariables>(FindMyAlertsStackValuesDocument, options);
      }
export function useFindMyAlertsStackValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindMyAlertsStackValuesQuery, FindMyAlertsStackValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindMyAlertsStackValuesQuery, FindMyAlertsStackValuesQueryVariables>(FindMyAlertsStackValuesDocument, options);
        }
export function useFindMyAlertsStackValuesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindMyAlertsStackValuesQuery, FindMyAlertsStackValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindMyAlertsStackValuesQuery, FindMyAlertsStackValuesQueryVariables>(FindMyAlertsStackValuesDocument, options);
        }
export type FindMyAlertsStackValuesQueryHookResult = ReturnType<typeof useFindMyAlertsStackValuesQuery>;
export type FindMyAlertsStackValuesLazyQueryHookResult = ReturnType<typeof useFindMyAlertsStackValuesLazyQuery>;
export type FindMyAlertsStackValuesSuspenseQueryHookResult = ReturnType<typeof useFindMyAlertsStackValuesSuspenseQuery>;
export type FindMyAlertsStackValuesQueryResult = Apollo.QueryResult<FindMyAlertsStackValuesQuery, FindMyAlertsStackValuesQueryVariables>;
export const FindMyApiStackValuesDocument = gql`
    query FindMyApiStackValues($deploymentId: String!) {
  findMyApiStackValues(deploymentId: $deploymentId)
}
    `;

/**
 * __useFindMyApiStackValuesQuery__
 *
 * To run a query within a React component, call `useFindMyApiStackValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMyApiStackValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMyApiStackValuesQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useFindMyApiStackValuesQuery(baseOptions: Apollo.QueryHookOptions<FindMyApiStackValuesQuery, FindMyApiStackValuesQueryVariables> & ({ variables: FindMyApiStackValuesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindMyApiStackValuesQuery, FindMyApiStackValuesQueryVariables>(FindMyApiStackValuesDocument, options);
      }
export function useFindMyApiStackValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindMyApiStackValuesQuery, FindMyApiStackValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindMyApiStackValuesQuery, FindMyApiStackValuesQueryVariables>(FindMyApiStackValuesDocument, options);
        }
export function useFindMyApiStackValuesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindMyApiStackValuesQuery, FindMyApiStackValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindMyApiStackValuesQuery, FindMyApiStackValuesQueryVariables>(FindMyApiStackValuesDocument, options);
        }
export type FindMyApiStackValuesQueryHookResult = ReturnType<typeof useFindMyApiStackValuesQuery>;
export type FindMyApiStackValuesLazyQueryHookResult = ReturnType<typeof useFindMyApiStackValuesLazyQuery>;
export type FindMyApiStackValuesSuspenseQueryHookResult = ReturnType<typeof useFindMyApiStackValuesSuspenseQuery>;
export type FindMyApiStackValuesQueryResult = Apollo.QueryResult<FindMyApiStackValuesQuery, FindMyApiStackValuesQueryVariables>;
export const FindMyAuthenticationStackValuesDocument = gql`
    query FindMyAuthenticationStackValues($deploymentId: String!) {
  findMyAuthenticationStackValues(deploymentId: $deploymentId)
}
    `;

/**
 * __useFindMyAuthenticationStackValuesQuery__
 *
 * To run a query within a React component, call `useFindMyAuthenticationStackValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMyAuthenticationStackValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMyAuthenticationStackValuesQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useFindMyAuthenticationStackValuesQuery(baseOptions: Apollo.QueryHookOptions<FindMyAuthenticationStackValuesQuery, FindMyAuthenticationStackValuesQueryVariables> & ({ variables: FindMyAuthenticationStackValuesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindMyAuthenticationStackValuesQuery, FindMyAuthenticationStackValuesQueryVariables>(FindMyAuthenticationStackValuesDocument, options);
      }
export function useFindMyAuthenticationStackValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindMyAuthenticationStackValuesQuery, FindMyAuthenticationStackValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindMyAuthenticationStackValuesQuery, FindMyAuthenticationStackValuesQueryVariables>(FindMyAuthenticationStackValuesDocument, options);
        }
export function useFindMyAuthenticationStackValuesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindMyAuthenticationStackValuesQuery, FindMyAuthenticationStackValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindMyAuthenticationStackValuesQuery, FindMyAuthenticationStackValuesQueryVariables>(FindMyAuthenticationStackValuesDocument, options);
        }
export type FindMyAuthenticationStackValuesQueryHookResult = ReturnType<typeof useFindMyAuthenticationStackValuesQuery>;
export type FindMyAuthenticationStackValuesLazyQueryHookResult = ReturnType<typeof useFindMyAuthenticationStackValuesLazyQuery>;
export type FindMyAuthenticationStackValuesSuspenseQueryHookResult = ReturnType<typeof useFindMyAuthenticationStackValuesSuspenseQuery>;
export type FindMyAuthenticationStackValuesQueryResult = Apollo.QueryResult<FindMyAuthenticationStackValuesQuery, FindMyAuthenticationStackValuesQueryVariables>;
export const FindMyDeploymentStackValuesDocument = gql`
    query FindMyDeploymentStackValues($deploymentId: String!) {
  findMyDeploymentStackValues(deploymentId: $deploymentId)
}
    `;

/**
 * __useFindMyDeploymentStackValuesQuery__
 *
 * To run a query within a React component, call `useFindMyDeploymentStackValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMyDeploymentStackValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMyDeploymentStackValuesQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useFindMyDeploymentStackValuesQuery(baseOptions: Apollo.QueryHookOptions<FindMyDeploymentStackValuesQuery, FindMyDeploymentStackValuesQueryVariables> & ({ variables: FindMyDeploymentStackValuesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindMyDeploymentStackValuesQuery, FindMyDeploymentStackValuesQueryVariables>(FindMyDeploymentStackValuesDocument, options);
      }
export function useFindMyDeploymentStackValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindMyDeploymentStackValuesQuery, FindMyDeploymentStackValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindMyDeploymentStackValuesQuery, FindMyDeploymentStackValuesQueryVariables>(FindMyDeploymentStackValuesDocument, options);
        }
export function useFindMyDeploymentStackValuesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindMyDeploymentStackValuesQuery, FindMyDeploymentStackValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindMyDeploymentStackValuesQuery, FindMyDeploymentStackValuesQueryVariables>(FindMyDeploymentStackValuesDocument, options);
        }
export type FindMyDeploymentStackValuesQueryHookResult = ReturnType<typeof useFindMyDeploymentStackValuesQuery>;
export type FindMyDeploymentStackValuesLazyQueryHookResult = ReturnType<typeof useFindMyDeploymentStackValuesLazyQuery>;
export type FindMyDeploymentStackValuesSuspenseQueryHookResult = ReturnType<typeof useFindMyDeploymentStackValuesSuspenseQuery>;
export type FindMyDeploymentStackValuesQueryResult = Apollo.QueryResult<FindMyDeploymentStackValuesQuery, FindMyDeploymentStackValuesQueryVariables>;
export const FindMyHttpApiStackValuesDocument = gql`
    query FindMyHttpApiStackValues($deploymentId: String!) {
  findMyHttpApiStackValues(deploymentId: $deploymentId)
}
    `;

/**
 * __useFindMyHttpApiStackValuesQuery__
 *
 * To run a query within a React component, call `useFindMyHttpApiStackValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMyHttpApiStackValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMyHttpApiStackValuesQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useFindMyHttpApiStackValuesQuery(baseOptions: Apollo.QueryHookOptions<FindMyHttpApiStackValuesQuery, FindMyHttpApiStackValuesQueryVariables> & ({ variables: FindMyHttpApiStackValuesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindMyHttpApiStackValuesQuery, FindMyHttpApiStackValuesQueryVariables>(FindMyHttpApiStackValuesDocument, options);
      }
export function useFindMyHttpApiStackValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindMyHttpApiStackValuesQuery, FindMyHttpApiStackValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindMyHttpApiStackValuesQuery, FindMyHttpApiStackValuesQueryVariables>(FindMyHttpApiStackValuesDocument, options);
        }
export function useFindMyHttpApiStackValuesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindMyHttpApiStackValuesQuery, FindMyHttpApiStackValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindMyHttpApiStackValuesQuery, FindMyHttpApiStackValuesQueryVariables>(FindMyHttpApiStackValuesDocument, options);
        }
export type FindMyHttpApiStackValuesQueryHookResult = ReturnType<typeof useFindMyHttpApiStackValuesQuery>;
export type FindMyHttpApiStackValuesLazyQueryHookResult = ReturnType<typeof useFindMyHttpApiStackValuesLazyQuery>;
export type FindMyHttpApiStackValuesSuspenseQueryHookResult = ReturnType<typeof useFindMyHttpApiStackValuesSuspenseQuery>;
export type FindMyHttpApiStackValuesQueryResult = Apollo.QueryResult<FindMyHttpApiStackValuesQuery, FindMyHttpApiStackValuesQueryVariables>;
export const FindMyVpcStackValuesDocument = gql`
    query FindMyVpcStackValues($deploymentId: String!) {
  findMyVpcStackValues(deploymentId: $deploymentId)
}
    `;

/**
 * __useFindMyVpcStackValuesQuery__
 *
 * To run a query within a React component, call `useFindMyVpcStackValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMyVpcStackValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMyVpcStackValuesQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useFindMyVpcStackValuesQuery(baseOptions: Apollo.QueryHookOptions<FindMyVpcStackValuesQuery, FindMyVpcStackValuesQueryVariables> & ({ variables: FindMyVpcStackValuesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindMyVpcStackValuesQuery, FindMyVpcStackValuesQueryVariables>(FindMyVpcStackValuesDocument, options);
      }
export function useFindMyVpcStackValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindMyVpcStackValuesQuery, FindMyVpcStackValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindMyVpcStackValuesQuery, FindMyVpcStackValuesQueryVariables>(FindMyVpcStackValuesDocument, options);
        }
export function useFindMyVpcStackValuesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindMyVpcStackValuesQuery, FindMyVpcStackValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindMyVpcStackValuesQuery, FindMyVpcStackValuesQueryVariables>(FindMyVpcStackValuesDocument, options);
        }
export type FindMyVpcStackValuesQueryHookResult = ReturnType<typeof useFindMyVpcStackValuesQuery>;
export type FindMyVpcStackValuesLazyQueryHookResult = ReturnType<typeof useFindMyVpcStackValuesLazyQuery>;
export type FindMyVpcStackValuesSuspenseQueryHookResult = ReturnType<typeof useFindMyVpcStackValuesSuspenseQuery>;
export type FindMyVpcStackValuesQueryResult = Apollo.QueryResult<FindMyVpcStackValuesQuery, FindMyVpcStackValuesQueryVariables>;
export const FindMyWsApiStackValuesDocument = gql`
    query FindMyWsApiStackValues($deploymentId: String!) {
  findMyWsApiStackValues(deploymentId: $deploymentId)
}
    `;

/**
 * __useFindMyWsApiStackValuesQuery__
 *
 * To run a query within a React component, call `useFindMyWsApiStackValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMyWsApiStackValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMyWsApiStackValuesQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useFindMyWsApiStackValuesQuery(baseOptions: Apollo.QueryHookOptions<FindMyWsApiStackValuesQuery, FindMyWsApiStackValuesQueryVariables> & ({ variables: FindMyWsApiStackValuesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindMyWsApiStackValuesQuery, FindMyWsApiStackValuesQueryVariables>(FindMyWsApiStackValuesDocument, options);
      }
export function useFindMyWsApiStackValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindMyWsApiStackValuesQuery, FindMyWsApiStackValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindMyWsApiStackValuesQuery, FindMyWsApiStackValuesQueryVariables>(FindMyWsApiStackValuesDocument, options);
        }
export function useFindMyWsApiStackValuesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindMyWsApiStackValuesQuery, FindMyWsApiStackValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindMyWsApiStackValuesQuery, FindMyWsApiStackValuesQueryVariables>(FindMyWsApiStackValuesDocument, options);
        }
export type FindMyWsApiStackValuesQueryHookResult = ReturnType<typeof useFindMyWsApiStackValuesQuery>;
export type FindMyWsApiStackValuesLazyQueryHookResult = ReturnType<typeof useFindMyWsApiStackValuesLazyQuery>;
export type FindMyWsApiStackValuesSuspenseQueryHookResult = ReturnType<typeof useFindMyWsApiStackValuesSuspenseQuery>;
export type FindMyWsApiStackValuesQueryResult = Apollo.QueryResult<FindMyWsApiStackValuesQuery, FindMyWsApiStackValuesQueryVariables>;
export const GetMyAlertsStackStatusDocument = gql`
    query GetMyAlertsStackStatus($deploymentId: String!, $stackId: String) {
  getMyAlertsStackStatus(deploymentId: $deploymentId, stackId: $stackId) {
    status
    parametersJSON
    stackId
  }
}
    `;

/**
 * __useGetMyAlertsStackStatusQuery__
 *
 * To run a query within a React component, call `useGetMyAlertsStackStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyAlertsStackStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyAlertsStackStatusQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      stackId: // value for 'stackId'
 *   },
 * });
 */
export function useGetMyAlertsStackStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMyAlertsStackStatusQuery, GetMyAlertsStackStatusQueryVariables> & ({ variables: GetMyAlertsStackStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyAlertsStackStatusQuery, GetMyAlertsStackStatusQueryVariables>(GetMyAlertsStackStatusDocument, options);
      }
export function useGetMyAlertsStackStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyAlertsStackStatusQuery, GetMyAlertsStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyAlertsStackStatusQuery, GetMyAlertsStackStatusQueryVariables>(GetMyAlertsStackStatusDocument, options);
        }
export function useGetMyAlertsStackStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyAlertsStackStatusQuery, GetMyAlertsStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyAlertsStackStatusQuery, GetMyAlertsStackStatusQueryVariables>(GetMyAlertsStackStatusDocument, options);
        }
export type GetMyAlertsStackStatusQueryHookResult = ReturnType<typeof useGetMyAlertsStackStatusQuery>;
export type GetMyAlertsStackStatusLazyQueryHookResult = ReturnType<typeof useGetMyAlertsStackStatusLazyQuery>;
export type GetMyAlertsStackStatusSuspenseQueryHookResult = ReturnType<typeof useGetMyAlertsStackStatusSuspenseQuery>;
export type GetMyAlertsStackStatusQueryResult = Apollo.QueryResult<GetMyAlertsStackStatusQuery, GetMyAlertsStackStatusQueryVariables>;
export const GetMyApiStackStatusDocument = gql`
    query GetMyApiStackStatus($deploymentId: String!, $stackId: String) {
  getMyApiStackStatus(deploymentId: $deploymentId, stackId: $stackId) {
    status
    parametersJSON
    stackId
  }
}
    `;

/**
 * __useGetMyApiStackStatusQuery__
 *
 * To run a query within a React component, call `useGetMyApiStackStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyApiStackStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyApiStackStatusQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      stackId: // value for 'stackId'
 *   },
 * });
 */
export function useGetMyApiStackStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMyApiStackStatusQuery, GetMyApiStackStatusQueryVariables> & ({ variables: GetMyApiStackStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyApiStackStatusQuery, GetMyApiStackStatusQueryVariables>(GetMyApiStackStatusDocument, options);
      }
export function useGetMyApiStackStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyApiStackStatusQuery, GetMyApiStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyApiStackStatusQuery, GetMyApiStackStatusQueryVariables>(GetMyApiStackStatusDocument, options);
        }
export function useGetMyApiStackStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyApiStackStatusQuery, GetMyApiStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyApiStackStatusQuery, GetMyApiStackStatusQueryVariables>(GetMyApiStackStatusDocument, options);
        }
export type GetMyApiStackStatusQueryHookResult = ReturnType<typeof useGetMyApiStackStatusQuery>;
export type GetMyApiStackStatusLazyQueryHookResult = ReturnType<typeof useGetMyApiStackStatusLazyQuery>;
export type GetMyApiStackStatusSuspenseQueryHookResult = ReturnType<typeof useGetMyApiStackStatusSuspenseQuery>;
export type GetMyApiStackStatusQueryResult = Apollo.QueryResult<GetMyApiStackStatusQuery, GetMyApiStackStatusQueryVariables>;
export const GetMyAuthenticationStackStatusDocument = gql`
    query GetMyAuthenticationStackStatus($deploymentId: String!, $stackId: String) {
  getMyAuthenticationStackStatus(deploymentId: $deploymentId, stackId: $stackId) {
    status
    parametersJSON
    stackId
  }
}
    `;

/**
 * __useGetMyAuthenticationStackStatusQuery__
 *
 * To run a query within a React component, call `useGetMyAuthenticationStackStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyAuthenticationStackStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyAuthenticationStackStatusQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      stackId: // value for 'stackId'
 *   },
 * });
 */
export function useGetMyAuthenticationStackStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMyAuthenticationStackStatusQuery, GetMyAuthenticationStackStatusQueryVariables> & ({ variables: GetMyAuthenticationStackStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyAuthenticationStackStatusQuery, GetMyAuthenticationStackStatusQueryVariables>(GetMyAuthenticationStackStatusDocument, options);
      }
export function useGetMyAuthenticationStackStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyAuthenticationStackStatusQuery, GetMyAuthenticationStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyAuthenticationStackStatusQuery, GetMyAuthenticationStackStatusQueryVariables>(GetMyAuthenticationStackStatusDocument, options);
        }
export function useGetMyAuthenticationStackStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyAuthenticationStackStatusQuery, GetMyAuthenticationStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyAuthenticationStackStatusQuery, GetMyAuthenticationStackStatusQueryVariables>(GetMyAuthenticationStackStatusDocument, options);
        }
export type GetMyAuthenticationStackStatusQueryHookResult = ReturnType<typeof useGetMyAuthenticationStackStatusQuery>;
export type GetMyAuthenticationStackStatusLazyQueryHookResult = ReturnType<typeof useGetMyAuthenticationStackStatusLazyQuery>;
export type GetMyAuthenticationStackStatusSuspenseQueryHookResult = ReturnType<typeof useGetMyAuthenticationStackStatusSuspenseQuery>;
export type GetMyAuthenticationStackStatusQueryResult = Apollo.QueryResult<GetMyAuthenticationStackStatusQuery, GetMyAuthenticationStackStatusQueryVariables>;
export const GetMyCertificateDocument = gql`
    query GetMyCertificate($deploymentId: String!) {
  getMyCertificate(deploymentId: $deploymentId) {
    arn
    status
  }
}
    `;

/**
 * __useGetMyCertificateQuery__
 *
 * To run a query within a React component, call `useGetMyCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCertificateQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useGetMyCertificateQuery(baseOptions: Apollo.QueryHookOptions<GetMyCertificateQuery, GetMyCertificateQueryVariables> & ({ variables: GetMyCertificateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyCertificateQuery, GetMyCertificateQueryVariables>(GetMyCertificateDocument, options);
      }
export function useGetMyCertificateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCertificateQuery, GetMyCertificateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyCertificateQuery, GetMyCertificateQueryVariables>(GetMyCertificateDocument, options);
        }
export function useGetMyCertificateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyCertificateQuery, GetMyCertificateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyCertificateQuery, GetMyCertificateQueryVariables>(GetMyCertificateDocument, options);
        }
export type GetMyCertificateQueryHookResult = ReturnType<typeof useGetMyCertificateQuery>;
export type GetMyCertificateLazyQueryHookResult = ReturnType<typeof useGetMyCertificateLazyQuery>;
export type GetMyCertificateSuspenseQueryHookResult = ReturnType<typeof useGetMyCertificateSuspenseQuery>;
export type GetMyCertificateQueryResult = Apollo.QueryResult<GetMyCertificateQuery, GetMyCertificateQueryVariables>;
export const GetMyCertificateCnameRecordStatusDocument = gql`
    query GetMyCertificateCnameRecordStatus($deploymentId: String!, $changeInfoId: String!) {
  getMyCertificateCnameRecordStatus(
    deploymentId: $deploymentId
    changeInfoId: $changeInfoId
  )
}
    `;

/**
 * __useGetMyCertificateCnameRecordStatusQuery__
 *
 * To run a query within a React component, call `useGetMyCertificateCnameRecordStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCertificateCnameRecordStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCertificateCnameRecordStatusQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      changeInfoId: // value for 'changeInfoId'
 *   },
 * });
 */
export function useGetMyCertificateCnameRecordStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMyCertificateCnameRecordStatusQuery, GetMyCertificateCnameRecordStatusQueryVariables> & ({ variables: GetMyCertificateCnameRecordStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyCertificateCnameRecordStatusQuery, GetMyCertificateCnameRecordStatusQueryVariables>(GetMyCertificateCnameRecordStatusDocument, options);
      }
export function useGetMyCertificateCnameRecordStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCertificateCnameRecordStatusQuery, GetMyCertificateCnameRecordStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyCertificateCnameRecordStatusQuery, GetMyCertificateCnameRecordStatusQueryVariables>(GetMyCertificateCnameRecordStatusDocument, options);
        }
export function useGetMyCertificateCnameRecordStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyCertificateCnameRecordStatusQuery, GetMyCertificateCnameRecordStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyCertificateCnameRecordStatusQuery, GetMyCertificateCnameRecordStatusQueryVariables>(GetMyCertificateCnameRecordStatusDocument, options);
        }
export type GetMyCertificateCnameRecordStatusQueryHookResult = ReturnType<typeof useGetMyCertificateCnameRecordStatusQuery>;
export type GetMyCertificateCnameRecordStatusLazyQueryHookResult = ReturnType<typeof useGetMyCertificateCnameRecordStatusLazyQuery>;
export type GetMyCertificateCnameRecordStatusSuspenseQueryHookResult = ReturnType<typeof useGetMyCertificateCnameRecordStatusSuspenseQuery>;
export type GetMyCertificateCnameRecordStatusQueryResult = Apollo.QueryResult<GetMyCertificateCnameRecordStatusQuery, GetMyCertificateCnameRecordStatusQueryVariables>;
export const GetMyDeploymentStackStatusDocument = gql`
    query GetMyDeploymentStackStatus($deploymentId: String!, $stackId: String) {
  getMyDeploymentStackStatus(deploymentId: $deploymentId, stackId: $stackId) {
    status
    parametersJSON
    stackId
  }
}
    `;

/**
 * __useGetMyDeploymentStackStatusQuery__
 *
 * To run a query within a React component, call `useGetMyDeploymentStackStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDeploymentStackStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDeploymentStackStatusQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      stackId: // value for 'stackId'
 *   },
 * });
 */
export function useGetMyDeploymentStackStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMyDeploymentStackStatusQuery, GetMyDeploymentStackStatusQueryVariables> & ({ variables: GetMyDeploymentStackStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyDeploymentStackStatusQuery, GetMyDeploymentStackStatusQueryVariables>(GetMyDeploymentStackStatusDocument, options);
      }
export function useGetMyDeploymentStackStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyDeploymentStackStatusQuery, GetMyDeploymentStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyDeploymentStackStatusQuery, GetMyDeploymentStackStatusQueryVariables>(GetMyDeploymentStackStatusDocument, options);
        }
export function useGetMyDeploymentStackStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyDeploymentStackStatusQuery, GetMyDeploymentStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyDeploymentStackStatusQuery, GetMyDeploymentStackStatusQueryVariables>(GetMyDeploymentStackStatusDocument, options);
        }
export type GetMyDeploymentStackStatusQueryHookResult = ReturnType<typeof useGetMyDeploymentStackStatusQuery>;
export type GetMyDeploymentStackStatusLazyQueryHookResult = ReturnType<typeof useGetMyDeploymentStackStatusLazyQuery>;
export type GetMyDeploymentStackStatusSuspenseQueryHookResult = ReturnType<typeof useGetMyDeploymentStackStatusSuspenseQuery>;
export type GetMyDeploymentStackStatusQueryResult = Apollo.QueryResult<GetMyDeploymentStackStatusQuery, GetMyDeploymentStackStatusQueryVariables>;
export const GetMyDeploymentsDocument = gql`
    query GetMyDeployments {
  getMyDeployments {
    id
    name
    accountId
    awsRegion
    defaultLogRetentionDays
    defaultAlarmsEnabled
    defaultDatabaseRetention
    defaultRequireCicdManualApproval
    fullDomainName
  }
}
    `;

/**
 * __useGetMyDeploymentsQuery__
 *
 * To run a query within a React component, call `useGetMyDeploymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDeploymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDeploymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyDeploymentsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyDeploymentsQuery, GetMyDeploymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyDeploymentsQuery, GetMyDeploymentsQueryVariables>(GetMyDeploymentsDocument, options);
      }
export function useGetMyDeploymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyDeploymentsQuery, GetMyDeploymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyDeploymentsQuery, GetMyDeploymentsQueryVariables>(GetMyDeploymentsDocument, options);
        }
export function useGetMyDeploymentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyDeploymentsQuery, GetMyDeploymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyDeploymentsQuery, GetMyDeploymentsQueryVariables>(GetMyDeploymentsDocument, options);
        }
export type GetMyDeploymentsQueryHookResult = ReturnType<typeof useGetMyDeploymentsQuery>;
export type GetMyDeploymentsLazyQueryHookResult = ReturnType<typeof useGetMyDeploymentsLazyQuery>;
export type GetMyDeploymentsSuspenseQueryHookResult = ReturnType<typeof useGetMyDeploymentsSuspenseQuery>;
export type GetMyDeploymentsQueryResult = Apollo.QueryResult<GetMyDeploymentsQuery, GetMyDeploymentsQueryVariables>;
export const GetMyFullDomainNameHostedZoneStatusDocument = gql`
    query GetMyFullDomainNameHostedZoneStatus($deploymentId: String!, $changeInfoId: String!) {
  getMyFullDomainNameHostedZoneStatus(
    deploymentId: $deploymentId
    changeInfoId: $changeInfoId
  )
}
    `;

/**
 * __useGetMyFullDomainNameHostedZoneStatusQuery__
 *
 * To run a query within a React component, call `useGetMyFullDomainNameHostedZoneStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyFullDomainNameHostedZoneStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyFullDomainNameHostedZoneStatusQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      changeInfoId: // value for 'changeInfoId'
 *   },
 * });
 */
export function useGetMyFullDomainNameHostedZoneStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMyFullDomainNameHostedZoneStatusQuery, GetMyFullDomainNameHostedZoneStatusQueryVariables> & ({ variables: GetMyFullDomainNameHostedZoneStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyFullDomainNameHostedZoneStatusQuery, GetMyFullDomainNameHostedZoneStatusQueryVariables>(GetMyFullDomainNameHostedZoneStatusDocument, options);
      }
export function useGetMyFullDomainNameHostedZoneStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyFullDomainNameHostedZoneStatusQuery, GetMyFullDomainNameHostedZoneStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyFullDomainNameHostedZoneStatusQuery, GetMyFullDomainNameHostedZoneStatusQueryVariables>(GetMyFullDomainNameHostedZoneStatusDocument, options);
        }
export function useGetMyFullDomainNameHostedZoneStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyFullDomainNameHostedZoneStatusQuery, GetMyFullDomainNameHostedZoneStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyFullDomainNameHostedZoneStatusQuery, GetMyFullDomainNameHostedZoneStatusQueryVariables>(GetMyFullDomainNameHostedZoneStatusDocument, options);
        }
export type GetMyFullDomainNameHostedZoneStatusQueryHookResult = ReturnType<typeof useGetMyFullDomainNameHostedZoneStatusQuery>;
export type GetMyFullDomainNameHostedZoneStatusLazyQueryHookResult = ReturnType<typeof useGetMyFullDomainNameHostedZoneStatusLazyQuery>;
export type GetMyFullDomainNameHostedZoneStatusSuspenseQueryHookResult = ReturnType<typeof useGetMyFullDomainNameHostedZoneStatusSuspenseQuery>;
export type GetMyFullDomainNameHostedZoneStatusQueryResult = Apollo.QueryResult<GetMyFullDomainNameHostedZoneStatusQuery, GetMyFullDomainNameHostedZoneStatusQueryVariables>;
export const GetMyFullDomainNameHostedZonesDocument = gql`
    query GetMyFullDomainNameHostedZones($deploymentId: String!) {
  getMyFullDomainNameHostedZones(deploymentId: $deploymentId) {
    hostedZoneId
    nameServers
  }
}
    `;

/**
 * __useGetMyFullDomainNameHostedZonesQuery__
 *
 * To run a query within a React component, call `useGetMyFullDomainNameHostedZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyFullDomainNameHostedZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyFullDomainNameHostedZonesQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useGetMyFullDomainNameHostedZonesQuery(baseOptions: Apollo.QueryHookOptions<GetMyFullDomainNameHostedZonesQuery, GetMyFullDomainNameHostedZonesQueryVariables> & ({ variables: GetMyFullDomainNameHostedZonesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyFullDomainNameHostedZonesQuery, GetMyFullDomainNameHostedZonesQueryVariables>(GetMyFullDomainNameHostedZonesDocument, options);
      }
export function useGetMyFullDomainNameHostedZonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyFullDomainNameHostedZonesQuery, GetMyFullDomainNameHostedZonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyFullDomainNameHostedZonesQuery, GetMyFullDomainNameHostedZonesQueryVariables>(GetMyFullDomainNameHostedZonesDocument, options);
        }
export function useGetMyFullDomainNameHostedZonesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyFullDomainNameHostedZonesQuery, GetMyFullDomainNameHostedZonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyFullDomainNameHostedZonesQuery, GetMyFullDomainNameHostedZonesQueryVariables>(GetMyFullDomainNameHostedZonesDocument, options);
        }
export type GetMyFullDomainNameHostedZonesQueryHookResult = ReturnType<typeof useGetMyFullDomainNameHostedZonesQuery>;
export type GetMyFullDomainNameHostedZonesLazyQueryHookResult = ReturnType<typeof useGetMyFullDomainNameHostedZonesLazyQuery>;
export type GetMyFullDomainNameHostedZonesSuspenseQueryHookResult = ReturnType<typeof useGetMyFullDomainNameHostedZonesSuspenseQuery>;
export type GetMyFullDomainNameHostedZonesQueryResult = Apollo.QueryResult<GetMyFullDomainNameHostedZonesQuery, GetMyFullDomainNameHostedZonesQueryVariables>;
export const GetMyGitConnectionsDocument = gql`
    query GetMyGitConnections($deploymentId: String!) {
  getMyGitConnections(deploymentId: $deploymentId) {
    id
    name
    connectionStatus
    ownerAccountId
    providerType
    pendingConnectionLink
  }
}
    `;

/**
 * __useGetMyGitConnectionsQuery__
 *
 * To run a query within a React component, call `useGetMyGitConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyGitConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyGitConnectionsQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useGetMyGitConnectionsQuery(baseOptions: Apollo.QueryHookOptions<GetMyGitConnectionsQuery, GetMyGitConnectionsQueryVariables> & ({ variables: GetMyGitConnectionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyGitConnectionsQuery, GetMyGitConnectionsQueryVariables>(GetMyGitConnectionsDocument, options);
      }
export function useGetMyGitConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyGitConnectionsQuery, GetMyGitConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyGitConnectionsQuery, GetMyGitConnectionsQueryVariables>(GetMyGitConnectionsDocument, options);
        }
export function useGetMyGitConnectionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyGitConnectionsQuery, GetMyGitConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyGitConnectionsQuery, GetMyGitConnectionsQueryVariables>(GetMyGitConnectionsDocument, options);
        }
export type GetMyGitConnectionsQueryHookResult = ReturnType<typeof useGetMyGitConnectionsQuery>;
export type GetMyGitConnectionsLazyQueryHookResult = ReturnType<typeof useGetMyGitConnectionsLazyQuery>;
export type GetMyGitConnectionsSuspenseQueryHookResult = ReturnType<typeof useGetMyGitConnectionsSuspenseQuery>;
export type GetMyGitConnectionsQueryResult = Apollo.QueryResult<GetMyGitConnectionsQuery, GetMyGitConnectionsQueryVariables>;
export const GetMyHttpApiStackStatusDocument = gql`
    query GetMyHttpApiStackStatus($deploymentId: String!, $stackId: String) {
  getMyHttpApiStackStatus(deploymentId: $deploymentId, stackId: $stackId) {
    status
    parametersJSON
    stackId
  }
}
    `;

/**
 * __useGetMyHttpApiStackStatusQuery__
 *
 * To run a query within a React component, call `useGetMyHttpApiStackStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyHttpApiStackStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyHttpApiStackStatusQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      stackId: // value for 'stackId'
 *   },
 * });
 */
export function useGetMyHttpApiStackStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMyHttpApiStackStatusQuery, GetMyHttpApiStackStatusQueryVariables> & ({ variables: GetMyHttpApiStackStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyHttpApiStackStatusQuery, GetMyHttpApiStackStatusQueryVariables>(GetMyHttpApiStackStatusDocument, options);
      }
export function useGetMyHttpApiStackStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyHttpApiStackStatusQuery, GetMyHttpApiStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyHttpApiStackStatusQuery, GetMyHttpApiStackStatusQueryVariables>(GetMyHttpApiStackStatusDocument, options);
        }
export function useGetMyHttpApiStackStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyHttpApiStackStatusQuery, GetMyHttpApiStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyHttpApiStackStatusQuery, GetMyHttpApiStackStatusQueryVariables>(GetMyHttpApiStackStatusDocument, options);
        }
export type GetMyHttpApiStackStatusQueryHookResult = ReturnType<typeof useGetMyHttpApiStackStatusQuery>;
export type GetMyHttpApiStackStatusLazyQueryHookResult = ReturnType<typeof useGetMyHttpApiStackStatusLazyQuery>;
export type GetMyHttpApiStackStatusSuspenseQueryHookResult = ReturnType<typeof useGetMyHttpApiStackStatusSuspenseQuery>;
export type GetMyHttpApiStackStatusQueryResult = Apollo.QueryResult<GetMyHttpApiStackStatusQuery, GetMyHttpApiStackStatusQueryVariables>;
export const GetMyVpcStackStatusDocument = gql`
    query GetMyVpcStackStatus($deploymentId: String!, $stackId: String) {
  getMyVpcStackStatus(deploymentId: $deploymentId, stackId: $stackId) {
    status
    parametersJSON
    stackId
  }
}
    `;

/**
 * __useGetMyVpcStackStatusQuery__
 *
 * To run a query within a React component, call `useGetMyVpcStackStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyVpcStackStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyVpcStackStatusQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      stackId: // value for 'stackId'
 *   },
 * });
 */
export function useGetMyVpcStackStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMyVpcStackStatusQuery, GetMyVpcStackStatusQueryVariables> & ({ variables: GetMyVpcStackStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyVpcStackStatusQuery, GetMyVpcStackStatusQueryVariables>(GetMyVpcStackStatusDocument, options);
      }
export function useGetMyVpcStackStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyVpcStackStatusQuery, GetMyVpcStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyVpcStackStatusQuery, GetMyVpcStackStatusQueryVariables>(GetMyVpcStackStatusDocument, options);
        }
export function useGetMyVpcStackStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyVpcStackStatusQuery, GetMyVpcStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyVpcStackStatusQuery, GetMyVpcStackStatusQueryVariables>(GetMyVpcStackStatusDocument, options);
        }
export type GetMyVpcStackStatusQueryHookResult = ReturnType<typeof useGetMyVpcStackStatusQuery>;
export type GetMyVpcStackStatusLazyQueryHookResult = ReturnType<typeof useGetMyVpcStackStatusLazyQuery>;
export type GetMyVpcStackStatusSuspenseQueryHookResult = ReturnType<typeof useGetMyVpcStackStatusSuspenseQuery>;
export type GetMyVpcStackStatusQueryResult = Apollo.QueryResult<GetMyVpcStackStatusQuery, GetMyVpcStackStatusQueryVariables>;
export const GetMyWaffleCfnTemplateDocument = gql`
    query GetMyWaffleCfnTemplate($deploymentId: String!) {
  getMyWaffleCfnTemplate(deploymentId: $deploymentId)
}
    `;

/**
 * __useGetMyWaffleCfnTemplateQuery__
 *
 * To run a query within a React component, call `useGetMyWaffleCfnTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyWaffleCfnTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyWaffleCfnTemplateQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useGetMyWaffleCfnTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetMyWaffleCfnTemplateQuery, GetMyWaffleCfnTemplateQueryVariables> & ({ variables: GetMyWaffleCfnTemplateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyWaffleCfnTemplateQuery, GetMyWaffleCfnTemplateQueryVariables>(GetMyWaffleCfnTemplateDocument, options);
      }
export function useGetMyWaffleCfnTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyWaffleCfnTemplateQuery, GetMyWaffleCfnTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyWaffleCfnTemplateQuery, GetMyWaffleCfnTemplateQueryVariables>(GetMyWaffleCfnTemplateDocument, options);
        }
export function useGetMyWaffleCfnTemplateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyWaffleCfnTemplateQuery, GetMyWaffleCfnTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyWaffleCfnTemplateQuery, GetMyWaffleCfnTemplateQueryVariables>(GetMyWaffleCfnTemplateDocument, options);
        }
export type GetMyWaffleCfnTemplateQueryHookResult = ReturnType<typeof useGetMyWaffleCfnTemplateQuery>;
export type GetMyWaffleCfnTemplateLazyQueryHookResult = ReturnType<typeof useGetMyWaffleCfnTemplateLazyQuery>;
export type GetMyWaffleCfnTemplateSuspenseQueryHookResult = ReturnType<typeof useGetMyWaffleCfnTemplateSuspenseQuery>;
export type GetMyWaffleCfnTemplateQueryResult = Apollo.QueryResult<GetMyWaffleCfnTemplateQuery, GetMyWaffleCfnTemplateQueryVariables>;
export const GetMyWaffleFullDomainNameNsRecordDocument = gql`
    query GetMyWaffleFullDomainNameNsRecord($deploymentId: String!) {
  getMyWaffleFullDomainNameNsRecord(deploymentId: $deploymentId)
}
    `;

/**
 * __useGetMyWaffleFullDomainNameNsRecordQuery__
 *
 * To run a query within a React component, call `useGetMyWaffleFullDomainNameNsRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyWaffleFullDomainNameNsRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyWaffleFullDomainNameNsRecordQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useGetMyWaffleFullDomainNameNsRecordQuery(baseOptions: Apollo.QueryHookOptions<GetMyWaffleFullDomainNameNsRecordQuery, GetMyWaffleFullDomainNameNsRecordQueryVariables> & ({ variables: GetMyWaffleFullDomainNameNsRecordQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyWaffleFullDomainNameNsRecordQuery, GetMyWaffleFullDomainNameNsRecordQueryVariables>(GetMyWaffleFullDomainNameNsRecordDocument, options);
      }
export function useGetMyWaffleFullDomainNameNsRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyWaffleFullDomainNameNsRecordQuery, GetMyWaffleFullDomainNameNsRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyWaffleFullDomainNameNsRecordQuery, GetMyWaffleFullDomainNameNsRecordQueryVariables>(GetMyWaffleFullDomainNameNsRecordDocument, options);
        }
export function useGetMyWaffleFullDomainNameNsRecordSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyWaffleFullDomainNameNsRecordQuery, GetMyWaffleFullDomainNameNsRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyWaffleFullDomainNameNsRecordQuery, GetMyWaffleFullDomainNameNsRecordQueryVariables>(GetMyWaffleFullDomainNameNsRecordDocument, options);
        }
export type GetMyWaffleFullDomainNameNsRecordQueryHookResult = ReturnType<typeof useGetMyWaffleFullDomainNameNsRecordQuery>;
export type GetMyWaffleFullDomainNameNsRecordLazyQueryHookResult = ReturnType<typeof useGetMyWaffleFullDomainNameNsRecordLazyQuery>;
export type GetMyWaffleFullDomainNameNsRecordSuspenseQueryHookResult = ReturnType<typeof useGetMyWaffleFullDomainNameNsRecordSuspenseQuery>;
export type GetMyWaffleFullDomainNameNsRecordQueryResult = Apollo.QueryResult<GetMyWaffleFullDomainNameNsRecordQuery, GetMyWaffleFullDomainNameNsRecordQueryVariables>;
export const GetMyWaffleFullDomainNameNsRecordStatusDocument = gql`
    query GetMyWaffleFullDomainNameNsRecordStatus($deploymentId: String!, $changeInfoId: String!) {
  getMyWaffleFullDomainNameNsRecordStatus(
    deploymentId: $deploymentId
    changeInfoId: $changeInfoId
  )
}
    `;

/**
 * __useGetMyWaffleFullDomainNameNsRecordStatusQuery__
 *
 * To run a query within a React component, call `useGetMyWaffleFullDomainNameNsRecordStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyWaffleFullDomainNameNsRecordStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyWaffleFullDomainNameNsRecordStatusQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      changeInfoId: // value for 'changeInfoId'
 *   },
 * });
 */
export function useGetMyWaffleFullDomainNameNsRecordStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMyWaffleFullDomainNameNsRecordStatusQuery, GetMyWaffleFullDomainNameNsRecordStatusQueryVariables> & ({ variables: GetMyWaffleFullDomainNameNsRecordStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyWaffleFullDomainNameNsRecordStatusQuery, GetMyWaffleFullDomainNameNsRecordStatusQueryVariables>(GetMyWaffleFullDomainNameNsRecordStatusDocument, options);
      }
export function useGetMyWaffleFullDomainNameNsRecordStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyWaffleFullDomainNameNsRecordStatusQuery, GetMyWaffleFullDomainNameNsRecordStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyWaffleFullDomainNameNsRecordStatusQuery, GetMyWaffleFullDomainNameNsRecordStatusQueryVariables>(GetMyWaffleFullDomainNameNsRecordStatusDocument, options);
        }
export function useGetMyWaffleFullDomainNameNsRecordStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyWaffleFullDomainNameNsRecordStatusQuery, GetMyWaffleFullDomainNameNsRecordStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyWaffleFullDomainNameNsRecordStatusQuery, GetMyWaffleFullDomainNameNsRecordStatusQueryVariables>(GetMyWaffleFullDomainNameNsRecordStatusDocument, options);
        }
export type GetMyWaffleFullDomainNameNsRecordStatusQueryHookResult = ReturnType<typeof useGetMyWaffleFullDomainNameNsRecordStatusQuery>;
export type GetMyWaffleFullDomainNameNsRecordStatusLazyQueryHookResult = ReturnType<typeof useGetMyWaffleFullDomainNameNsRecordStatusLazyQuery>;
export type GetMyWaffleFullDomainNameNsRecordStatusSuspenseQueryHookResult = ReturnType<typeof useGetMyWaffleFullDomainNameNsRecordStatusSuspenseQuery>;
export type GetMyWaffleFullDomainNameNsRecordStatusQueryResult = Apollo.QueryResult<GetMyWaffleFullDomainNameNsRecordStatusQuery, GetMyWaffleFullDomainNameNsRecordStatusQueryVariables>;
export const GetMyWsApiStackStatusDocument = gql`
    query GetMyWsApiStackStatus($deploymentId: String!, $stackId: String) {
  getMyWsApiStackStatus(deploymentId: $deploymentId, stackId: $stackId) {
    status
    parametersJSON
    stackId
  }
}
    `;

/**
 * __useGetMyWsApiStackStatusQuery__
 *
 * To run a query within a React component, call `useGetMyWsApiStackStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyWsApiStackStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyWsApiStackStatusQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      stackId: // value for 'stackId'
 *   },
 * });
 */
export function useGetMyWsApiStackStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMyWsApiStackStatusQuery, GetMyWsApiStackStatusQueryVariables> & ({ variables: GetMyWsApiStackStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyWsApiStackStatusQuery, GetMyWsApiStackStatusQueryVariables>(GetMyWsApiStackStatusDocument, options);
      }
export function useGetMyWsApiStackStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyWsApiStackStatusQuery, GetMyWsApiStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyWsApiStackStatusQuery, GetMyWsApiStackStatusQueryVariables>(GetMyWsApiStackStatusDocument, options);
        }
export function useGetMyWsApiStackStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyWsApiStackStatusQuery, GetMyWsApiStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyWsApiStackStatusQuery, GetMyWsApiStackStatusQueryVariables>(GetMyWsApiStackStatusDocument, options);
        }
export type GetMyWsApiStackStatusQueryHookResult = ReturnType<typeof useGetMyWsApiStackStatusQuery>;
export type GetMyWsApiStackStatusLazyQueryHookResult = ReturnType<typeof useGetMyWsApiStackStatusLazyQuery>;
export type GetMyWsApiStackStatusSuspenseQueryHookResult = ReturnType<typeof useGetMyWsApiStackStatusSuspenseQuery>;
export type GetMyWsApiStackStatusQueryResult = Apollo.QueryResult<GetMyWsApiStackStatusQuery, GetMyWsApiStackStatusQueryVariables>;
export const RequestMyCertificateDocument = gql`
    mutation RequestMyCertificate($deploymentId: String!) {
  requestMyCertificate(deploymentId: $deploymentId)
}
    `;
export type RequestMyCertificateMutationFn = Apollo.MutationFunction<RequestMyCertificateMutation, RequestMyCertificateMutationVariables>;

/**
 * __useRequestMyCertificateMutation__
 *
 * To run a mutation, you first call `useRequestMyCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMyCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMyCertificateMutation, { data, loading, error }] = useRequestMyCertificateMutation({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useRequestMyCertificateMutation(baseOptions?: Apollo.MutationHookOptions<RequestMyCertificateMutation, RequestMyCertificateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestMyCertificateMutation, RequestMyCertificateMutationVariables>(RequestMyCertificateDocument, options);
      }
export type RequestMyCertificateMutationHookResult = ReturnType<typeof useRequestMyCertificateMutation>;
export type RequestMyCertificateMutationResult = Apollo.MutationResult<RequestMyCertificateMutation>;
export type RequestMyCertificateMutationOptions = Apollo.BaseMutationOptions<RequestMyCertificateMutation, RequestMyCertificateMutationVariables>;
export const TestMyWaffleRoleDocument = gql`
    query TestMyWaffleRole($deploymentId: String!) {
  testMyWaffleRole(deploymentId: $deploymentId) {
    id
    passed
    details
  }
}
    `;

/**
 * __useTestMyWaffleRoleQuery__
 *
 * To run a query within a React component, call `useTestMyWaffleRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestMyWaffleRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestMyWaffleRoleQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useTestMyWaffleRoleQuery(baseOptions: Apollo.QueryHookOptions<TestMyWaffleRoleQuery, TestMyWaffleRoleQueryVariables> & ({ variables: TestMyWaffleRoleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestMyWaffleRoleQuery, TestMyWaffleRoleQueryVariables>(TestMyWaffleRoleDocument, options);
      }
export function useTestMyWaffleRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestMyWaffleRoleQuery, TestMyWaffleRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestMyWaffleRoleQuery, TestMyWaffleRoleQueryVariables>(TestMyWaffleRoleDocument, options);
        }
export function useTestMyWaffleRoleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TestMyWaffleRoleQuery, TestMyWaffleRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TestMyWaffleRoleQuery, TestMyWaffleRoleQueryVariables>(TestMyWaffleRoleDocument, options);
        }
export type TestMyWaffleRoleQueryHookResult = ReturnType<typeof useTestMyWaffleRoleQuery>;
export type TestMyWaffleRoleLazyQueryHookResult = ReturnType<typeof useTestMyWaffleRoleLazyQuery>;
export type TestMyWaffleRoleSuspenseQueryHookResult = ReturnType<typeof useTestMyWaffleRoleSuspenseQuery>;
export type TestMyWaffleRoleQueryResult = Apollo.QueryResult<TestMyWaffleRoleQuery, TestMyWaffleRoleQueryVariables>;
export const HelloWorldDocument = gql`
    query HelloWorld {
  helloWorld
}
    `;

/**
 * __useHelloWorldQuery__
 *
 * To run a query within a React component, call `useHelloWorldQuery` and pass it any options that fit your needs.
 * When your component renders, `useHelloWorldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHelloWorldQuery({
 *   variables: {
 *   },
 * });
 */
export function useHelloWorldQuery(baseOptions?: Apollo.QueryHookOptions<HelloWorldQuery, HelloWorldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HelloWorldQuery, HelloWorldQueryVariables>(HelloWorldDocument, options);
      }
export function useHelloWorldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HelloWorldQuery, HelloWorldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HelloWorldQuery, HelloWorldQueryVariables>(HelloWorldDocument, options);
        }
export function useHelloWorldSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HelloWorldQuery, HelloWorldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HelloWorldQuery, HelloWorldQueryVariables>(HelloWorldDocument, options);
        }
export type HelloWorldQueryHookResult = ReturnType<typeof useHelloWorldQuery>;
export type HelloWorldLazyQueryHookResult = ReturnType<typeof useHelloWorldLazyQuery>;
export type HelloWorldSuspenseQueryHookResult = ReturnType<typeof useHelloWorldSuspenseQuery>;
export type HelloWorldQueryResult = Apollo.QueryResult<HelloWorldQuery, HelloWorldQueryVariables>;
export const AddOrganizationDocument = gql`
    mutation AddOrganization($organizationId: String!) {
  addOrganization(organizationId: $organizationId) {
    id
    name
  }
}
    `;
export type AddOrganizationMutationFn = Apollo.MutationFunction<AddOrganizationMutation, AddOrganizationMutationVariables>;

/**
 * __useAddOrganizationMutation__
 *
 * To run a mutation, you first call `useAddOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationMutation, { data, loading, error }] = useAddOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useAddOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<AddOrganizationMutation, AddOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrganizationMutation, AddOrganizationMutationVariables>(AddOrganizationDocument, options);
      }
export type AddOrganizationMutationHookResult = ReturnType<typeof useAddOrganizationMutation>;
export type AddOrganizationMutationResult = Apollo.MutationResult<AddOrganizationMutation>;
export type AddOrganizationMutationOptions = Apollo.BaseMutationOptions<AddOrganizationMutation, AddOrganizationMutationVariables>;
export const EditOrganizationDocument = gql`
    mutation EditOrganization($organizationInput: EditOrganization!) {
  editOrganization(organizationInput: $organizationInput) {
    id
    name
    features {
      workInProgressFeaturesEnabled
      singleDeployment
      databaseEnabled
      apiV1Enabled
      wsApiEnabled
      httpApiEnabled
      customDomainEnabled
    }
    onboardingComplete
  }
}
    `;
export type EditOrganizationMutationFn = Apollo.MutationFunction<EditOrganizationMutation, EditOrganizationMutationVariables>;

/**
 * __useEditOrganizationMutation__
 *
 * To run a mutation, you first call `useEditOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrganizationMutation, { data, loading, error }] = useEditOrganizationMutation({
 *   variables: {
 *      organizationInput: // value for 'organizationInput'
 *   },
 * });
 */
export function useEditOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<EditOrganizationMutation, EditOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditOrganizationMutation, EditOrganizationMutationVariables>(EditOrganizationDocument, options);
      }
export type EditOrganizationMutationHookResult = ReturnType<typeof useEditOrganizationMutation>;
export type EditOrganizationMutationResult = Apollo.MutationResult<EditOrganizationMutation>;
export type EditOrganizationMutationOptions = Apollo.BaseMutationOptions<EditOrganizationMutation, EditOrganizationMutationVariables>;
export const GetAllOrganizationsDocument = gql`
    query GetAllOrganizations {
  getAllOrganizations {
    id
    name
  }
}
    `;

/**
 * __useGetAllOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetAllOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllOrganizationsQuery, GetAllOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOrganizationsQuery, GetAllOrganizationsQueryVariables>(GetAllOrganizationsDocument, options);
      }
export function useGetAllOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOrganizationsQuery, GetAllOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOrganizationsQuery, GetAllOrganizationsQueryVariables>(GetAllOrganizationsDocument, options);
        }
export function useGetAllOrganizationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllOrganizationsQuery, GetAllOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllOrganizationsQuery, GetAllOrganizationsQueryVariables>(GetAllOrganizationsDocument, options);
        }
export type GetAllOrganizationsQueryHookResult = ReturnType<typeof useGetAllOrganizationsQuery>;
export type GetAllOrganizationsLazyQueryHookResult = ReturnType<typeof useGetAllOrganizationsLazyQuery>;
export type GetAllOrganizationsSuspenseQueryHookResult = ReturnType<typeof useGetAllOrganizationsSuspenseQuery>;
export type GetAllOrganizationsQueryResult = Apollo.QueryResult<GetAllOrganizationsQuery, GetAllOrganizationsQueryVariables>;
export const GetMyOrganizationDocument = gql`
    query GetMyOrganization {
  getMyOrganization {
    name
    id
    features {
      workInProgressFeaturesEnabled
      singleDeployment
      databaseEnabled
      apiV1Enabled
      wsApiEnabled
      httpApiEnabled
      customDomainEnabled
    }
    onboardingComplete
  }
}
    `;

/**
 * __useGetMyOrganizationQuery__
 *
 * To run a query within a React component, call `useGetMyOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<GetMyOrganizationQuery, GetMyOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyOrganizationQuery, GetMyOrganizationQueryVariables>(GetMyOrganizationDocument, options);
      }
export function useGetMyOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyOrganizationQuery, GetMyOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyOrganizationQuery, GetMyOrganizationQueryVariables>(GetMyOrganizationDocument, options);
        }
export function useGetMyOrganizationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyOrganizationQuery, GetMyOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyOrganizationQuery, GetMyOrganizationQueryVariables>(GetMyOrganizationDocument, options);
        }
export type GetMyOrganizationQueryHookResult = ReturnType<typeof useGetMyOrganizationQuery>;
export type GetMyOrganizationLazyQueryHookResult = ReturnType<typeof useGetMyOrganizationLazyQuery>;
export type GetMyOrganizationSuspenseQueryHookResult = ReturnType<typeof useGetMyOrganizationSuspenseQuery>;
export type GetMyOrganizationQueryResult = Apollo.QueryResult<GetMyOrganizationQuery, GetMyOrganizationQueryVariables>;
export const GetOrganizationDocument = gql`
    query GetOrganization($organizationId: String!) {
  getOrganization(organizationId: $organizationId) {
    id
    name
    features {
      workInProgressFeaturesEnabled
      singleDeployment
      databaseEnabled
      apiV1Enabled
      wsApiEnabled
      httpApiEnabled
      customDomainEnabled
    }
    onboardingComplete
  }
}
    `;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables> & ({ variables: GetOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
      }
export function useGetOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
        }
export function useGetOrganizationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
        }
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationSuspenseQuery>;
export type GetOrganizationQueryResult = Apollo.QueryResult<GetOrganizationQuery, GetOrganizationQueryVariables>;
export const AddMyServiceStackDocument = gql`
    mutation AddMyServiceStack($stackId: String!, $type: String!) {
  addMyServiceStack(stackId: $stackId, type: $type) {
    id
    name
    type
  }
}
    `;
export type AddMyServiceStackMutationFn = Apollo.MutationFunction<AddMyServiceStackMutation, AddMyServiceStackMutationVariables>;

/**
 * __useAddMyServiceStackMutation__
 *
 * To run a mutation, you first call `useAddMyServiceStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMyServiceStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMyServiceStackMutation, { data, loading, error }] = useAddMyServiceStackMutation({
 *   variables: {
 *      stackId: // value for 'stackId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAddMyServiceStackMutation(baseOptions?: Apollo.MutationHookOptions<AddMyServiceStackMutation, AddMyServiceStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMyServiceStackMutation, AddMyServiceStackMutationVariables>(AddMyServiceStackDocument, options);
      }
export type AddMyServiceStackMutationHookResult = ReturnType<typeof useAddMyServiceStackMutation>;
export type AddMyServiceStackMutationResult = Apollo.MutationResult<AddMyServiceStackMutation>;
export type AddMyServiceStackMutationOptions = Apollo.BaseMutationOptions<AddMyServiceStackMutation, AddMyServiceStackMutationVariables>;
export const DeployMyAnyCfnStackDocument = gql`
    mutation deployMyAnyCfnStack($stackDeploymentInput: DeployAnyCfnStack!) {
  deployMyAnyCfnStack(stackDeploymentInput: $stackDeploymentInput)
}
    `;
export type DeployMyAnyCfnStackMutationFn = Apollo.MutationFunction<DeployMyAnyCfnStackMutation, DeployMyAnyCfnStackMutationVariables>;

/**
 * __useDeployMyAnyCfnStackMutation__
 *
 * To run a mutation, you first call `useDeployMyAnyCfnStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployMyAnyCfnStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployMyAnyCfnStackMutation, { data, loading, error }] = useDeployMyAnyCfnStackMutation({
 *   variables: {
 *      stackDeploymentInput: // value for 'stackDeploymentInput'
 *   },
 * });
 */
export function useDeployMyAnyCfnStackMutation(baseOptions?: Apollo.MutationHookOptions<DeployMyAnyCfnStackMutation, DeployMyAnyCfnStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeployMyAnyCfnStackMutation, DeployMyAnyCfnStackMutationVariables>(DeployMyAnyCfnStackDocument, options);
      }
export type DeployMyAnyCfnStackMutationHookResult = ReturnType<typeof useDeployMyAnyCfnStackMutation>;
export type DeployMyAnyCfnStackMutationResult = Apollo.MutationResult<DeployMyAnyCfnStackMutation>;
export type DeployMyAnyCfnStackMutationOptions = Apollo.BaseMutationOptions<DeployMyAnyCfnStackMutation, DeployMyAnyCfnStackMutationVariables>;
export const DeployMyCdnStackDocument = gql`
    mutation DeployMyCdnStack($stackDeploymentInput: DeployCdnStack!) {
  deployMyCdnStack(stackDeploymentInput: $stackDeploymentInput)
}
    `;
export type DeployMyCdnStackMutationFn = Apollo.MutationFunction<DeployMyCdnStackMutation, DeployMyCdnStackMutationVariables>;

/**
 * __useDeployMyCdnStackMutation__
 *
 * To run a mutation, you first call `useDeployMyCdnStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployMyCdnStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployMyCdnStackMutation, { data, loading, error }] = useDeployMyCdnStackMutation({
 *   variables: {
 *      stackDeploymentInput: // value for 'stackDeploymentInput'
 *   },
 * });
 */
export function useDeployMyCdnStackMutation(baseOptions?: Apollo.MutationHookOptions<DeployMyCdnStackMutation, DeployMyCdnStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeployMyCdnStackMutation, DeployMyCdnStackMutationVariables>(DeployMyCdnStackDocument, options);
      }
export type DeployMyCdnStackMutationHookResult = ReturnType<typeof useDeployMyCdnStackMutation>;
export type DeployMyCdnStackMutationResult = Apollo.MutationResult<DeployMyCdnStackMutation>;
export type DeployMyCdnStackMutationOptions = Apollo.BaseMutationOptions<DeployMyCdnStackMutation, DeployMyCdnStackMutationVariables>;
export const DeployMyCfnStackDocument = gql`
    mutation DeployMyCfnStack($stackDeploymentInput: DeployCfnStack!) {
  deployMyCfnStack(stackDeploymentInput: $stackDeploymentInput)
}
    `;
export type DeployMyCfnStackMutationFn = Apollo.MutationFunction<DeployMyCfnStackMutation, DeployMyCfnStackMutationVariables>;

/**
 * __useDeployMyCfnStackMutation__
 *
 * To run a mutation, you first call `useDeployMyCfnStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployMyCfnStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployMyCfnStackMutation, { data, loading, error }] = useDeployMyCfnStackMutation({
 *   variables: {
 *      stackDeploymentInput: // value for 'stackDeploymentInput'
 *   },
 * });
 */
export function useDeployMyCfnStackMutation(baseOptions?: Apollo.MutationHookOptions<DeployMyCfnStackMutation, DeployMyCfnStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeployMyCfnStackMutation, DeployMyCfnStackMutationVariables>(DeployMyCfnStackDocument, options);
      }
export type DeployMyCfnStackMutationHookResult = ReturnType<typeof useDeployMyCfnStackMutation>;
export type DeployMyCfnStackMutationResult = Apollo.MutationResult<DeployMyCfnStackMutation>;
export type DeployMyCfnStackMutationOptions = Apollo.BaseMutationOptions<DeployMyCfnStackMutation, DeployMyCfnStackMutationVariables>;
export const DeployMyDbStackDocument = gql`
    mutation DeployMyDbStack($stackDeploymentInput: DeployDbStack!) {
  deployMyDbStack(stackDeploymentInput: $stackDeploymentInput)
}
    `;
export type DeployMyDbStackMutationFn = Apollo.MutationFunction<DeployMyDbStackMutation, DeployMyDbStackMutationVariables>;

/**
 * __useDeployMyDbStackMutation__
 *
 * To run a mutation, you first call `useDeployMyDbStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployMyDbStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployMyDbStackMutation, { data, loading, error }] = useDeployMyDbStackMutation({
 *   variables: {
 *      stackDeploymentInput: // value for 'stackDeploymentInput'
 *   },
 * });
 */
export function useDeployMyDbStackMutation(baseOptions?: Apollo.MutationHookOptions<DeployMyDbStackMutation, DeployMyDbStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeployMyDbStackMutation, DeployMyDbStackMutationVariables>(DeployMyDbStackDocument, options);
      }
export type DeployMyDbStackMutationHookResult = ReturnType<typeof useDeployMyDbStackMutation>;
export type DeployMyDbStackMutationResult = Apollo.MutationResult<DeployMyDbStackMutation>;
export type DeployMyDbStackMutationOptions = Apollo.BaseMutationOptions<DeployMyDbStackMutation, DeployMyDbStackMutationVariables>;
export const DeployMyEcsFargateStackDocument = gql`
    mutation DeployMyEcsFargateStack($stackDeploymentInput: DeployEcsFargateStack!) {
  deployMyEcsFargateStack(stackDeploymentInput: $stackDeploymentInput)
}
    `;
export type DeployMyEcsFargateStackMutationFn = Apollo.MutationFunction<DeployMyEcsFargateStackMutation, DeployMyEcsFargateStackMutationVariables>;

/**
 * __useDeployMyEcsFargateStackMutation__
 *
 * To run a mutation, you first call `useDeployMyEcsFargateStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployMyEcsFargateStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployMyEcsFargateStackMutation, { data, loading, error }] = useDeployMyEcsFargateStackMutation({
 *   variables: {
 *      stackDeploymentInput: // value for 'stackDeploymentInput'
 *   },
 * });
 */
export function useDeployMyEcsFargateStackMutation(baseOptions?: Apollo.MutationHookOptions<DeployMyEcsFargateStackMutation, DeployMyEcsFargateStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeployMyEcsFargateStackMutation, DeployMyEcsFargateStackMutationVariables>(DeployMyEcsFargateStackDocument, options);
      }
export type DeployMyEcsFargateStackMutationHookResult = ReturnType<typeof useDeployMyEcsFargateStackMutation>;
export type DeployMyEcsFargateStackMutationResult = Apollo.MutationResult<DeployMyEcsFargateStackMutation>;
export type DeployMyEcsFargateStackMutationOptions = Apollo.BaseMutationOptions<DeployMyEcsFargateStackMutation, DeployMyEcsFargateStackMutationVariables>;
export const DeployMyEcsWsFargateStackDocument = gql`
    mutation DeployMyEcsWsFargateStack($stackDeploymentInput: DeployEcsWsFargateStack!) {
  deployMyEcsWsFargateStack(stackDeploymentInput: $stackDeploymentInput)
}
    `;
export type DeployMyEcsWsFargateStackMutationFn = Apollo.MutationFunction<DeployMyEcsWsFargateStackMutation, DeployMyEcsWsFargateStackMutationVariables>;

/**
 * __useDeployMyEcsWsFargateStackMutation__
 *
 * To run a mutation, you first call `useDeployMyEcsWsFargateStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployMyEcsWsFargateStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployMyEcsWsFargateStackMutation, { data, loading, error }] = useDeployMyEcsWsFargateStackMutation({
 *   variables: {
 *      stackDeploymentInput: // value for 'stackDeploymentInput'
 *   },
 * });
 */
export function useDeployMyEcsWsFargateStackMutation(baseOptions?: Apollo.MutationHookOptions<DeployMyEcsWsFargateStackMutation, DeployMyEcsWsFargateStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeployMyEcsWsFargateStackMutation, DeployMyEcsWsFargateStackMutationVariables>(DeployMyEcsWsFargateStackDocument, options);
      }
export type DeployMyEcsWsFargateStackMutationHookResult = ReturnType<typeof useDeployMyEcsWsFargateStackMutation>;
export type DeployMyEcsWsFargateStackMutationResult = Apollo.MutationResult<DeployMyEcsWsFargateStackMutation>;
export type DeployMyEcsWsFargateStackMutationOptions = Apollo.BaseMutationOptions<DeployMyEcsWsFargateStackMutation, DeployMyEcsWsFargateStackMutationVariables>;
export const EditMyServiceStackDocument = gql`
    mutation EditMyServiceStack($stackInput: EditServiceStack!) {
  editMyServiceStack(stackInput: $stackInput) {
    id
    name
    type
  }
}
    `;
export type EditMyServiceStackMutationFn = Apollo.MutationFunction<EditMyServiceStackMutation, EditMyServiceStackMutationVariables>;

/**
 * __useEditMyServiceStackMutation__
 *
 * To run a mutation, you first call `useEditMyServiceStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMyServiceStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMyServiceStackMutation, { data, loading, error }] = useEditMyServiceStackMutation({
 *   variables: {
 *      stackInput: // value for 'stackInput'
 *   },
 * });
 */
export function useEditMyServiceStackMutation(baseOptions?: Apollo.MutationHookOptions<EditMyServiceStackMutation, EditMyServiceStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditMyServiceStackMutation, EditMyServiceStackMutationVariables>(EditMyServiceStackDocument, options);
      }
export type EditMyServiceStackMutationHookResult = ReturnType<typeof useEditMyServiceStackMutation>;
export type EditMyServiceStackMutationResult = Apollo.MutationResult<EditMyServiceStackMutation>;
export type EditMyServiceStackMutationOptions = Apollo.BaseMutationOptions<EditMyServiceStackMutation, EditMyServiceStackMutationVariables>;
export const GetMyCdnServiceStackStatusDocument = gql`
    query GetMyCdnServiceStackStatus($deploymentId: String!, $pipelineId: String!, $stackId: String) {
  getMyCdnServiceStackStatus(
    deploymentId: $deploymentId
    pipelineId: $pipelineId
    stackId: $stackId
  ) {
    status
    parametersJSON
    stackId
  }
}
    `;

/**
 * __useGetMyCdnServiceStackStatusQuery__
 *
 * To run a query within a React component, call `useGetMyCdnServiceStackStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCdnServiceStackStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCdnServiceStackStatusQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      pipelineId: // value for 'pipelineId'
 *      stackId: // value for 'stackId'
 *   },
 * });
 */
export function useGetMyCdnServiceStackStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMyCdnServiceStackStatusQuery, GetMyCdnServiceStackStatusQueryVariables> & ({ variables: GetMyCdnServiceStackStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyCdnServiceStackStatusQuery, GetMyCdnServiceStackStatusQueryVariables>(GetMyCdnServiceStackStatusDocument, options);
      }
export function useGetMyCdnServiceStackStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCdnServiceStackStatusQuery, GetMyCdnServiceStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyCdnServiceStackStatusQuery, GetMyCdnServiceStackStatusQueryVariables>(GetMyCdnServiceStackStatusDocument, options);
        }
export function useGetMyCdnServiceStackStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyCdnServiceStackStatusQuery, GetMyCdnServiceStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyCdnServiceStackStatusQuery, GetMyCdnServiceStackStatusQueryVariables>(GetMyCdnServiceStackStatusDocument, options);
        }
export type GetMyCdnServiceStackStatusQueryHookResult = ReturnType<typeof useGetMyCdnServiceStackStatusQuery>;
export type GetMyCdnServiceStackStatusLazyQueryHookResult = ReturnType<typeof useGetMyCdnServiceStackStatusLazyQuery>;
export type GetMyCdnServiceStackStatusSuspenseQueryHookResult = ReturnType<typeof useGetMyCdnServiceStackStatusSuspenseQuery>;
export type GetMyCdnServiceStackStatusQueryResult = Apollo.QueryResult<GetMyCdnServiceStackStatusQuery, GetMyCdnServiceStackStatusQueryVariables>;
export const GetMyCfnServiceStackStatusDocument = gql`
    query GetMyCfnServiceStackStatus($deploymentId: String!, $pipelineId: String!, $stackId: String) {
  getMyCfnServiceStackStatus(
    deploymentId: $deploymentId
    pipelineId: $pipelineId
    stackId: $stackId
  ) {
    status
    parametersJSON
    stackId
  }
}
    `;

/**
 * __useGetMyCfnServiceStackStatusQuery__
 *
 * To run a query within a React component, call `useGetMyCfnServiceStackStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCfnServiceStackStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCfnServiceStackStatusQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      pipelineId: // value for 'pipelineId'
 *      stackId: // value for 'stackId'
 *   },
 * });
 */
export function useGetMyCfnServiceStackStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMyCfnServiceStackStatusQuery, GetMyCfnServiceStackStatusQueryVariables> & ({ variables: GetMyCfnServiceStackStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyCfnServiceStackStatusQuery, GetMyCfnServiceStackStatusQueryVariables>(GetMyCfnServiceStackStatusDocument, options);
      }
export function useGetMyCfnServiceStackStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCfnServiceStackStatusQuery, GetMyCfnServiceStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyCfnServiceStackStatusQuery, GetMyCfnServiceStackStatusQueryVariables>(GetMyCfnServiceStackStatusDocument, options);
        }
export function useGetMyCfnServiceStackStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyCfnServiceStackStatusQuery, GetMyCfnServiceStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyCfnServiceStackStatusQuery, GetMyCfnServiceStackStatusQueryVariables>(GetMyCfnServiceStackStatusDocument, options);
        }
export type GetMyCfnServiceStackStatusQueryHookResult = ReturnType<typeof useGetMyCfnServiceStackStatusQuery>;
export type GetMyCfnServiceStackStatusLazyQueryHookResult = ReturnType<typeof useGetMyCfnServiceStackStatusLazyQuery>;
export type GetMyCfnServiceStackStatusSuspenseQueryHookResult = ReturnType<typeof useGetMyCfnServiceStackStatusSuspenseQuery>;
export type GetMyCfnServiceStackStatusQueryResult = Apollo.QueryResult<GetMyCfnServiceStackStatusQuery, GetMyCfnServiceStackStatusQueryVariables>;
export const GetMyCfnStackIdsDocument = gql`
    query getMyCfnStackIds($deploymentId: String!) {
  getMyCfnStackIds(deploymentId: $deploymentId)
}
    `;

/**
 * __useGetMyCfnStackIdsQuery__
 *
 * To run a query within a React component, call `useGetMyCfnStackIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCfnStackIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCfnStackIdsQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useGetMyCfnStackIdsQuery(baseOptions: Apollo.QueryHookOptions<GetMyCfnStackIdsQuery, GetMyCfnStackIdsQueryVariables> & ({ variables: GetMyCfnStackIdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyCfnStackIdsQuery, GetMyCfnStackIdsQueryVariables>(GetMyCfnStackIdsDocument, options);
      }
export function useGetMyCfnStackIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCfnStackIdsQuery, GetMyCfnStackIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyCfnStackIdsQuery, GetMyCfnStackIdsQueryVariables>(GetMyCfnStackIdsDocument, options);
        }
export function useGetMyCfnStackIdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyCfnStackIdsQuery, GetMyCfnStackIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyCfnStackIdsQuery, GetMyCfnStackIdsQueryVariables>(GetMyCfnStackIdsDocument, options);
        }
export type GetMyCfnStackIdsQueryHookResult = ReturnType<typeof useGetMyCfnStackIdsQuery>;
export type GetMyCfnStackIdsLazyQueryHookResult = ReturnType<typeof useGetMyCfnStackIdsLazyQuery>;
export type GetMyCfnStackIdsSuspenseQueryHookResult = ReturnType<typeof useGetMyCfnStackIdsSuspenseQuery>;
export type GetMyCfnStackIdsQueryResult = Apollo.QueryResult<GetMyCfnStackIdsQuery, GetMyCfnStackIdsQueryVariables>;
export const GetMyCfnStackParametersDocument = gql`
    query getMyCfnStackParameters($deploymentId: String!, $stackId: String!) {
  getMyCfnStackParameters(deploymentId: $deploymentId, stackId: $stackId)
}
    `;

/**
 * __useGetMyCfnStackParametersQuery__
 *
 * To run a query within a React component, call `useGetMyCfnStackParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCfnStackParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCfnStackParametersQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      stackId: // value for 'stackId'
 *   },
 * });
 */
export function useGetMyCfnStackParametersQuery(baseOptions: Apollo.QueryHookOptions<GetMyCfnStackParametersQuery, GetMyCfnStackParametersQueryVariables> & ({ variables: GetMyCfnStackParametersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyCfnStackParametersQuery, GetMyCfnStackParametersQueryVariables>(GetMyCfnStackParametersDocument, options);
      }
export function useGetMyCfnStackParametersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCfnStackParametersQuery, GetMyCfnStackParametersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyCfnStackParametersQuery, GetMyCfnStackParametersQueryVariables>(GetMyCfnStackParametersDocument, options);
        }
export function useGetMyCfnStackParametersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyCfnStackParametersQuery, GetMyCfnStackParametersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyCfnStackParametersQuery, GetMyCfnStackParametersQueryVariables>(GetMyCfnStackParametersDocument, options);
        }
export type GetMyCfnStackParametersQueryHookResult = ReturnType<typeof useGetMyCfnStackParametersQuery>;
export type GetMyCfnStackParametersLazyQueryHookResult = ReturnType<typeof useGetMyCfnStackParametersLazyQuery>;
export type GetMyCfnStackParametersSuspenseQueryHookResult = ReturnType<typeof useGetMyCfnStackParametersSuspenseQuery>;
export type GetMyCfnStackParametersQueryResult = Apollo.QueryResult<GetMyCfnStackParametersQuery, GetMyCfnStackParametersQueryVariables>;
export const GetMyCfnStackStatusDocument = gql`
    query getMyCfnStackStatus($deploymentId: String!, $stackId: String!) {
  getMyCfnStackStatus(deploymentId: $deploymentId, stackId: $stackId) {
    status
    parametersJSON
    stackId
  }
}
    `;

/**
 * __useGetMyCfnStackStatusQuery__
 *
 * To run a query within a React component, call `useGetMyCfnStackStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCfnStackStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCfnStackStatusQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      stackId: // value for 'stackId'
 *   },
 * });
 */
export function useGetMyCfnStackStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMyCfnStackStatusQuery, GetMyCfnStackStatusQueryVariables> & ({ variables: GetMyCfnStackStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyCfnStackStatusQuery, GetMyCfnStackStatusQueryVariables>(GetMyCfnStackStatusDocument, options);
      }
export function useGetMyCfnStackStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCfnStackStatusQuery, GetMyCfnStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyCfnStackStatusQuery, GetMyCfnStackStatusQueryVariables>(GetMyCfnStackStatusDocument, options);
        }
export function useGetMyCfnStackStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyCfnStackStatusQuery, GetMyCfnStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyCfnStackStatusQuery, GetMyCfnStackStatusQueryVariables>(GetMyCfnStackStatusDocument, options);
        }
export type GetMyCfnStackStatusQueryHookResult = ReturnType<typeof useGetMyCfnStackStatusQuery>;
export type GetMyCfnStackStatusLazyQueryHookResult = ReturnType<typeof useGetMyCfnStackStatusLazyQuery>;
export type GetMyCfnStackStatusSuspenseQueryHookResult = ReturnType<typeof useGetMyCfnStackStatusSuspenseQuery>;
export type GetMyCfnStackStatusQueryResult = Apollo.QueryResult<GetMyCfnStackStatusQuery, GetMyCfnStackStatusQueryVariables>;
export const GetMyCfnStackTemplateDocument = gql`
    query getMyCfnStackTemplate($deploymentId: String!, $stackId: String!) {
  getMyCfnStackTemplate(deploymentId: $deploymentId, stackId: $stackId)
}
    `;

/**
 * __useGetMyCfnStackTemplateQuery__
 *
 * To run a query within a React component, call `useGetMyCfnStackTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCfnStackTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCfnStackTemplateQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      stackId: // value for 'stackId'
 *   },
 * });
 */
export function useGetMyCfnStackTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetMyCfnStackTemplateQuery, GetMyCfnStackTemplateQueryVariables> & ({ variables: GetMyCfnStackTemplateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyCfnStackTemplateQuery, GetMyCfnStackTemplateQueryVariables>(GetMyCfnStackTemplateDocument, options);
      }
export function useGetMyCfnStackTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCfnStackTemplateQuery, GetMyCfnStackTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyCfnStackTemplateQuery, GetMyCfnStackTemplateQueryVariables>(GetMyCfnStackTemplateDocument, options);
        }
export function useGetMyCfnStackTemplateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyCfnStackTemplateQuery, GetMyCfnStackTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyCfnStackTemplateQuery, GetMyCfnStackTemplateQueryVariables>(GetMyCfnStackTemplateDocument, options);
        }
export type GetMyCfnStackTemplateQueryHookResult = ReturnType<typeof useGetMyCfnStackTemplateQuery>;
export type GetMyCfnStackTemplateLazyQueryHookResult = ReturnType<typeof useGetMyCfnStackTemplateLazyQuery>;
export type GetMyCfnStackTemplateSuspenseQueryHookResult = ReturnType<typeof useGetMyCfnStackTemplateSuspenseQuery>;
export type GetMyCfnStackTemplateQueryResult = Apollo.QueryResult<GetMyCfnStackTemplateQuery, GetMyCfnStackTemplateQueryVariables>;
export const GetMyDbEngineVersionsDocument = gql`
    query getMyDbEngineVersions($deploymentId: String!, $dbType: String!, $family: String!) {
  getMyDbEngineVersions(
    deploymentId: $deploymentId
    dbType: $dbType
    family: $family
  )
}
    `;

/**
 * __useGetMyDbEngineVersionsQuery__
 *
 * To run a query within a React component, call `useGetMyDbEngineVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDbEngineVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDbEngineVersionsQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      dbType: // value for 'dbType'
 *      family: // value for 'family'
 *   },
 * });
 */
export function useGetMyDbEngineVersionsQuery(baseOptions: Apollo.QueryHookOptions<GetMyDbEngineVersionsQuery, GetMyDbEngineVersionsQueryVariables> & ({ variables: GetMyDbEngineVersionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyDbEngineVersionsQuery, GetMyDbEngineVersionsQueryVariables>(GetMyDbEngineVersionsDocument, options);
      }
export function useGetMyDbEngineVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyDbEngineVersionsQuery, GetMyDbEngineVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyDbEngineVersionsQuery, GetMyDbEngineVersionsQueryVariables>(GetMyDbEngineVersionsDocument, options);
        }
export function useGetMyDbEngineVersionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyDbEngineVersionsQuery, GetMyDbEngineVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyDbEngineVersionsQuery, GetMyDbEngineVersionsQueryVariables>(GetMyDbEngineVersionsDocument, options);
        }
export type GetMyDbEngineVersionsQueryHookResult = ReturnType<typeof useGetMyDbEngineVersionsQuery>;
export type GetMyDbEngineVersionsLazyQueryHookResult = ReturnType<typeof useGetMyDbEngineVersionsLazyQuery>;
export type GetMyDbEngineVersionsSuspenseQueryHookResult = ReturnType<typeof useGetMyDbEngineVersionsSuspenseQuery>;
export type GetMyDbEngineVersionsQueryResult = Apollo.QueryResult<GetMyDbEngineVersionsQuery, GetMyDbEngineVersionsQueryVariables>;
export const GetMyDbFamiliesDocument = gql`
    query getMyDbFamilies($deploymentId: String!, $dbType: String!) {
  getMyDbFamilies(deploymentId: $deploymentId, dbType: $dbType)
}
    `;

/**
 * __useGetMyDbFamiliesQuery__
 *
 * To run a query within a React component, call `useGetMyDbFamiliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDbFamiliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDbFamiliesQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      dbType: // value for 'dbType'
 *   },
 * });
 */
export function useGetMyDbFamiliesQuery(baseOptions: Apollo.QueryHookOptions<GetMyDbFamiliesQuery, GetMyDbFamiliesQueryVariables> & ({ variables: GetMyDbFamiliesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyDbFamiliesQuery, GetMyDbFamiliesQueryVariables>(GetMyDbFamiliesDocument, options);
      }
export function useGetMyDbFamiliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyDbFamiliesQuery, GetMyDbFamiliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyDbFamiliesQuery, GetMyDbFamiliesQueryVariables>(GetMyDbFamiliesDocument, options);
        }
export function useGetMyDbFamiliesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyDbFamiliesQuery, GetMyDbFamiliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyDbFamiliesQuery, GetMyDbFamiliesQueryVariables>(GetMyDbFamiliesDocument, options);
        }
export type GetMyDbFamiliesQueryHookResult = ReturnType<typeof useGetMyDbFamiliesQuery>;
export type GetMyDbFamiliesLazyQueryHookResult = ReturnType<typeof useGetMyDbFamiliesLazyQuery>;
export type GetMyDbFamiliesSuspenseQueryHookResult = ReturnType<typeof useGetMyDbFamiliesSuspenseQuery>;
export type GetMyDbFamiliesQueryResult = Apollo.QueryResult<GetMyDbFamiliesQuery, GetMyDbFamiliesQueryVariables>;
export const GetMyDbInstanceClassesDocument = gql`
    query getMyDbInstanceClasses($deploymentId: String!, $dbType: String!, $engineVersion: String!) {
  getMyDbInstanceClasses(
    deploymentId: $deploymentId
    dbType: $dbType
    engineVersion: $engineVersion
  )
}
    `;

/**
 * __useGetMyDbInstanceClassesQuery__
 *
 * To run a query within a React component, call `useGetMyDbInstanceClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDbInstanceClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDbInstanceClassesQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      dbType: // value for 'dbType'
 *      engineVersion: // value for 'engineVersion'
 *   },
 * });
 */
export function useGetMyDbInstanceClassesQuery(baseOptions: Apollo.QueryHookOptions<GetMyDbInstanceClassesQuery, GetMyDbInstanceClassesQueryVariables> & ({ variables: GetMyDbInstanceClassesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyDbInstanceClassesQuery, GetMyDbInstanceClassesQueryVariables>(GetMyDbInstanceClassesDocument, options);
      }
export function useGetMyDbInstanceClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyDbInstanceClassesQuery, GetMyDbInstanceClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyDbInstanceClassesQuery, GetMyDbInstanceClassesQueryVariables>(GetMyDbInstanceClassesDocument, options);
        }
export function useGetMyDbInstanceClassesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyDbInstanceClassesQuery, GetMyDbInstanceClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyDbInstanceClassesQuery, GetMyDbInstanceClassesQueryVariables>(GetMyDbInstanceClassesDocument, options);
        }
export type GetMyDbInstanceClassesQueryHookResult = ReturnType<typeof useGetMyDbInstanceClassesQuery>;
export type GetMyDbInstanceClassesLazyQueryHookResult = ReturnType<typeof useGetMyDbInstanceClassesLazyQuery>;
export type GetMyDbInstanceClassesSuspenseQueryHookResult = ReturnType<typeof useGetMyDbInstanceClassesSuspenseQuery>;
export type GetMyDbInstanceClassesQueryResult = Apollo.QueryResult<GetMyDbInstanceClassesQuery, GetMyDbInstanceClassesQueryVariables>;
export const GetMyDbServiceStackStatusDocument = gql`
    query GetMyDbServiceStackStatus($deploymentId: String!, $databaseId: String!, $stackId: String) {
  getMyDbServiceStackStatus(
    deploymentId: $deploymentId
    databaseId: $databaseId
    stackId: $stackId
  ) {
    status
    parametersJSON
    stackId
  }
}
    `;

/**
 * __useGetMyDbServiceStackStatusQuery__
 *
 * To run a query within a React component, call `useGetMyDbServiceStackStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDbServiceStackStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDbServiceStackStatusQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      databaseId: // value for 'databaseId'
 *      stackId: // value for 'stackId'
 *   },
 * });
 */
export function useGetMyDbServiceStackStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMyDbServiceStackStatusQuery, GetMyDbServiceStackStatusQueryVariables> & ({ variables: GetMyDbServiceStackStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyDbServiceStackStatusQuery, GetMyDbServiceStackStatusQueryVariables>(GetMyDbServiceStackStatusDocument, options);
      }
export function useGetMyDbServiceStackStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyDbServiceStackStatusQuery, GetMyDbServiceStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyDbServiceStackStatusQuery, GetMyDbServiceStackStatusQueryVariables>(GetMyDbServiceStackStatusDocument, options);
        }
export function useGetMyDbServiceStackStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyDbServiceStackStatusQuery, GetMyDbServiceStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyDbServiceStackStatusQuery, GetMyDbServiceStackStatusQueryVariables>(GetMyDbServiceStackStatusDocument, options);
        }
export type GetMyDbServiceStackStatusQueryHookResult = ReturnType<typeof useGetMyDbServiceStackStatusQuery>;
export type GetMyDbServiceStackStatusLazyQueryHookResult = ReturnType<typeof useGetMyDbServiceStackStatusLazyQuery>;
export type GetMyDbServiceStackStatusSuspenseQueryHookResult = ReturnType<typeof useGetMyDbServiceStackStatusSuspenseQuery>;
export type GetMyDbServiceStackStatusQueryResult = Apollo.QueryResult<GetMyDbServiceStackStatusQuery, GetMyDbServiceStackStatusQueryVariables>;
export const GetMyDbSnapshotIdsDocument = gql`
    query getMyDbSnapshotIds($deploymentId: String!) {
  getMyDbSnapshotIds(deploymentId: $deploymentId)
}
    `;

/**
 * __useGetMyDbSnapshotIdsQuery__
 *
 * To run a query within a React component, call `useGetMyDbSnapshotIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDbSnapshotIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDbSnapshotIdsQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *   },
 * });
 */
export function useGetMyDbSnapshotIdsQuery(baseOptions: Apollo.QueryHookOptions<GetMyDbSnapshotIdsQuery, GetMyDbSnapshotIdsQueryVariables> & ({ variables: GetMyDbSnapshotIdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyDbSnapshotIdsQuery, GetMyDbSnapshotIdsQueryVariables>(GetMyDbSnapshotIdsDocument, options);
      }
export function useGetMyDbSnapshotIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyDbSnapshotIdsQuery, GetMyDbSnapshotIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyDbSnapshotIdsQuery, GetMyDbSnapshotIdsQueryVariables>(GetMyDbSnapshotIdsDocument, options);
        }
export function useGetMyDbSnapshotIdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyDbSnapshotIdsQuery, GetMyDbSnapshotIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyDbSnapshotIdsQuery, GetMyDbSnapshotIdsQueryVariables>(GetMyDbSnapshotIdsDocument, options);
        }
export type GetMyDbSnapshotIdsQueryHookResult = ReturnType<typeof useGetMyDbSnapshotIdsQuery>;
export type GetMyDbSnapshotIdsLazyQueryHookResult = ReturnType<typeof useGetMyDbSnapshotIdsLazyQuery>;
export type GetMyDbSnapshotIdsSuspenseQueryHookResult = ReturnType<typeof useGetMyDbSnapshotIdsSuspenseQuery>;
export type GetMyDbSnapshotIdsQueryResult = Apollo.QueryResult<GetMyDbSnapshotIdsQuery, GetMyDbSnapshotIdsQueryVariables>;
export const GetMyEcsServiceStackStatusDocument = gql`
    query GetMyEcsServiceStackStatus($deploymentId: String!, $pipelineId: String!, $stackId: String) {
  getMyEcsServiceStackStatus(
    deploymentId: $deploymentId
    pipelineId: $pipelineId
    stackId: $stackId
  ) {
    status
    parametersJSON
    stackId
  }
}
    `;

/**
 * __useGetMyEcsServiceStackStatusQuery__
 *
 * To run a query within a React component, call `useGetMyEcsServiceStackStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyEcsServiceStackStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyEcsServiceStackStatusQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      pipelineId: // value for 'pipelineId'
 *      stackId: // value for 'stackId'
 *   },
 * });
 */
export function useGetMyEcsServiceStackStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMyEcsServiceStackStatusQuery, GetMyEcsServiceStackStatusQueryVariables> & ({ variables: GetMyEcsServiceStackStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyEcsServiceStackStatusQuery, GetMyEcsServiceStackStatusQueryVariables>(GetMyEcsServiceStackStatusDocument, options);
      }
export function useGetMyEcsServiceStackStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyEcsServiceStackStatusQuery, GetMyEcsServiceStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyEcsServiceStackStatusQuery, GetMyEcsServiceStackStatusQueryVariables>(GetMyEcsServiceStackStatusDocument, options);
        }
export function useGetMyEcsServiceStackStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyEcsServiceStackStatusQuery, GetMyEcsServiceStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyEcsServiceStackStatusQuery, GetMyEcsServiceStackStatusQueryVariables>(GetMyEcsServiceStackStatusDocument, options);
        }
export type GetMyEcsServiceStackStatusQueryHookResult = ReturnType<typeof useGetMyEcsServiceStackStatusQuery>;
export type GetMyEcsServiceStackStatusLazyQueryHookResult = ReturnType<typeof useGetMyEcsServiceStackStatusLazyQuery>;
export type GetMyEcsServiceStackStatusSuspenseQueryHookResult = ReturnType<typeof useGetMyEcsServiceStackStatusSuspenseQuery>;
export type GetMyEcsServiceStackStatusQueryResult = Apollo.QueryResult<GetMyEcsServiceStackStatusQuery, GetMyEcsServiceStackStatusQueryVariables>;
export const GetMyEcsWsServiceStackStatusDocument = gql`
    query GetMyEcsWsServiceStackStatus($deploymentId: String!, $pipelineId: String!, $stackId: String) {
  getMyEcsWsServiceStackStatus(
    deploymentId: $deploymentId
    pipelineId: $pipelineId
    stackId: $stackId
  ) {
    status
    parametersJSON
    stackId
  }
}
    `;

/**
 * __useGetMyEcsWsServiceStackStatusQuery__
 *
 * To run a query within a React component, call `useGetMyEcsWsServiceStackStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyEcsWsServiceStackStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyEcsWsServiceStackStatusQuery({
 *   variables: {
 *      deploymentId: // value for 'deploymentId'
 *      pipelineId: // value for 'pipelineId'
 *      stackId: // value for 'stackId'
 *   },
 * });
 */
export function useGetMyEcsWsServiceStackStatusQuery(baseOptions: Apollo.QueryHookOptions<GetMyEcsWsServiceStackStatusQuery, GetMyEcsWsServiceStackStatusQueryVariables> & ({ variables: GetMyEcsWsServiceStackStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyEcsWsServiceStackStatusQuery, GetMyEcsWsServiceStackStatusQueryVariables>(GetMyEcsWsServiceStackStatusDocument, options);
      }
export function useGetMyEcsWsServiceStackStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyEcsWsServiceStackStatusQuery, GetMyEcsWsServiceStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyEcsWsServiceStackStatusQuery, GetMyEcsWsServiceStackStatusQueryVariables>(GetMyEcsWsServiceStackStatusDocument, options);
        }
export function useGetMyEcsWsServiceStackStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyEcsWsServiceStackStatusQuery, GetMyEcsWsServiceStackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyEcsWsServiceStackStatusQuery, GetMyEcsWsServiceStackStatusQueryVariables>(GetMyEcsWsServiceStackStatusDocument, options);
        }
export type GetMyEcsWsServiceStackStatusQueryHookResult = ReturnType<typeof useGetMyEcsWsServiceStackStatusQuery>;
export type GetMyEcsWsServiceStackStatusLazyQueryHookResult = ReturnType<typeof useGetMyEcsWsServiceStackStatusLazyQuery>;
export type GetMyEcsWsServiceStackStatusSuspenseQueryHookResult = ReturnType<typeof useGetMyEcsWsServiceStackStatusSuspenseQuery>;
export type GetMyEcsWsServiceStackStatusQueryResult = Apollo.QueryResult<GetMyEcsWsServiceStackStatusQuery, GetMyEcsWsServiceStackStatusQueryVariables>;
export const GetMyServiceStacksDocument = gql`
    query GetMyServiceStacks {
  getMyServiceStacks {
    id
    name
    type
  }
}
    `;

/**
 * __useGetMyServiceStacksQuery__
 *
 * To run a query within a React component, call `useGetMyServiceStacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyServiceStacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyServiceStacksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyServiceStacksQuery(baseOptions?: Apollo.QueryHookOptions<GetMyServiceStacksQuery, GetMyServiceStacksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyServiceStacksQuery, GetMyServiceStacksQueryVariables>(GetMyServiceStacksDocument, options);
      }
export function useGetMyServiceStacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyServiceStacksQuery, GetMyServiceStacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyServiceStacksQuery, GetMyServiceStacksQueryVariables>(GetMyServiceStacksDocument, options);
        }
export function useGetMyServiceStacksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyServiceStacksQuery, GetMyServiceStacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyServiceStacksQuery, GetMyServiceStacksQueryVariables>(GetMyServiceStacksDocument, options);
        }
export type GetMyServiceStacksQueryHookResult = ReturnType<typeof useGetMyServiceStacksQuery>;
export type GetMyServiceStacksLazyQueryHookResult = ReturnType<typeof useGetMyServiceStacksLazyQuery>;
export type GetMyServiceStacksSuspenseQueryHookResult = ReturnType<typeof useGetMyServiceStacksSuspenseQuery>;
export type GetMyServiceStacksQueryResult = Apollo.QueryResult<GetMyServiceStacksQuery, GetMyServiceStacksQueryVariables>;
export const RemoveMyCdnStackDocument = gql`
    mutation RemoveMyCdnStack($stackId: String, $deploymentId: String!, $pipelineId: String!) {
  removeMyCdnStack(
    stackId: $stackId
    deploymentId: $deploymentId
    pipelineId: $pipelineId
  )
}
    `;
export type RemoveMyCdnStackMutationFn = Apollo.MutationFunction<RemoveMyCdnStackMutation, RemoveMyCdnStackMutationVariables>;

/**
 * __useRemoveMyCdnStackMutation__
 *
 * To run a mutation, you first call `useRemoveMyCdnStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMyCdnStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMyCdnStackMutation, { data, loading, error }] = useRemoveMyCdnStackMutation({
 *   variables: {
 *      stackId: // value for 'stackId'
 *      deploymentId: // value for 'deploymentId'
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useRemoveMyCdnStackMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMyCdnStackMutation, RemoveMyCdnStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMyCdnStackMutation, RemoveMyCdnStackMutationVariables>(RemoveMyCdnStackDocument, options);
      }
export type RemoveMyCdnStackMutationHookResult = ReturnType<typeof useRemoveMyCdnStackMutation>;
export type RemoveMyCdnStackMutationResult = Apollo.MutationResult<RemoveMyCdnStackMutation>;
export type RemoveMyCdnStackMutationOptions = Apollo.BaseMutationOptions<RemoveMyCdnStackMutation, RemoveMyCdnStackMutationVariables>;
export const RemoveMyCfnStackDocument = gql`
    mutation RemoveMyCfnStack($stackId: String, $deploymentId: String!, $pipelineId: String!) {
  removeMyCfnStack(
    stackId: $stackId
    deploymentId: $deploymentId
    pipelineId: $pipelineId
  )
}
    `;
export type RemoveMyCfnStackMutationFn = Apollo.MutationFunction<RemoveMyCfnStackMutation, RemoveMyCfnStackMutationVariables>;

/**
 * __useRemoveMyCfnStackMutation__
 *
 * To run a mutation, you first call `useRemoveMyCfnStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMyCfnStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMyCfnStackMutation, { data, loading, error }] = useRemoveMyCfnStackMutation({
 *   variables: {
 *      stackId: // value for 'stackId'
 *      deploymentId: // value for 'deploymentId'
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useRemoveMyCfnStackMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMyCfnStackMutation, RemoveMyCfnStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMyCfnStackMutation, RemoveMyCfnStackMutationVariables>(RemoveMyCfnStackDocument, options);
      }
export type RemoveMyCfnStackMutationHookResult = ReturnType<typeof useRemoveMyCfnStackMutation>;
export type RemoveMyCfnStackMutationResult = Apollo.MutationResult<RemoveMyCfnStackMutation>;
export type RemoveMyCfnStackMutationOptions = Apollo.BaseMutationOptions<RemoveMyCfnStackMutation, RemoveMyCfnStackMutationVariables>;
export const RemoveMyDbStackDocument = gql`
    mutation RemoveMyDbStack($stackId: String, $deploymentId: String!, $databaseId: String!) {
  removeMyDbStack(
    stackId: $stackId
    deploymentId: $deploymentId
    databaseId: $databaseId
  )
}
    `;
export type RemoveMyDbStackMutationFn = Apollo.MutationFunction<RemoveMyDbStackMutation, RemoveMyDbStackMutationVariables>;

/**
 * __useRemoveMyDbStackMutation__
 *
 * To run a mutation, you first call `useRemoveMyDbStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMyDbStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMyDbStackMutation, { data, loading, error }] = useRemoveMyDbStackMutation({
 *   variables: {
 *      stackId: // value for 'stackId'
 *      deploymentId: // value for 'deploymentId'
 *      databaseId: // value for 'databaseId'
 *   },
 * });
 */
export function useRemoveMyDbStackMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMyDbStackMutation, RemoveMyDbStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMyDbStackMutation, RemoveMyDbStackMutationVariables>(RemoveMyDbStackDocument, options);
      }
export type RemoveMyDbStackMutationHookResult = ReturnType<typeof useRemoveMyDbStackMutation>;
export type RemoveMyDbStackMutationResult = Apollo.MutationResult<RemoveMyDbStackMutation>;
export type RemoveMyDbStackMutationOptions = Apollo.BaseMutationOptions<RemoveMyDbStackMutation, RemoveMyDbStackMutationVariables>;
export const RemoveMyEcsFargateStackDocument = gql`
    mutation RemoveMyEcsFargateStack($stackId: String, $deploymentId: String!, $pipelineId: String!) {
  removeMyEcsFargateStack(
    stackId: $stackId
    deploymentId: $deploymentId
    pipelineId: $pipelineId
  )
}
    `;
export type RemoveMyEcsFargateStackMutationFn = Apollo.MutationFunction<RemoveMyEcsFargateStackMutation, RemoveMyEcsFargateStackMutationVariables>;

/**
 * __useRemoveMyEcsFargateStackMutation__
 *
 * To run a mutation, you first call `useRemoveMyEcsFargateStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMyEcsFargateStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMyEcsFargateStackMutation, { data, loading, error }] = useRemoveMyEcsFargateStackMutation({
 *   variables: {
 *      stackId: // value for 'stackId'
 *      deploymentId: // value for 'deploymentId'
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useRemoveMyEcsFargateStackMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMyEcsFargateStackMutation, RemoveMyEcsFargateStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMyEcsFargateStackMutation, RemoveMyEcsFargateStackMutationVariables>(RemoveMyEcsFargateStackDocument, options);
      }
export type RemoveMyEcsFargateStackMutationHookResult = ReturnType<typeof useRemoveMyEcsFargateStackMutation>;
export type RemoveMyEcsFargateStackMutationResult = Apollo.MutationResult<RemoveMyEcsFargateStackMutation>;
export type RemoveMyEcsFargateStackMutationOptions = Apollo.BaseMutationOptions<RemoveMyEcsFargateStackMutation, RemoveMyEcsFargateStackMutationVariables>;
export const RemoveMyEcsWsFargateStackDocument = gql`
    mutation RemoveMyEcsWsFargateStack($stackId: String, $deploymentId: String!, $pipelineId: String!) {
  removeMyEcsWsFargateStack(
    stackId: $stackId
    deploymentId: $deploymentId
    pipelineId: $pipelineId
  )
}
    `;
export type RemoveMyEcsWsFargateStackMutationFn = Apollo.MutationFunction<RemoveMyEcsWsFargateStackMutation, RemoveMyEcsWsFargateStackMutationVariables>;

/**
 * __useRemoveMyEcsWsFargateStackMutation__
 *
 * To run a mutation, you first call `useRemoveMyEcsWsFargateStackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMyEcsWsFargateStackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMyEcsWsFargateStackMutation, { data, loading, error }] = useRemoveMyEcsWsFargateStackMutation({
 *   variables: {
 *      stackId: // value for 'stackId'
 *      deploymentId: // value for 'deploymentId'
 *      pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useRemoveMyEcsWsFargateStackMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMyEcsWsFargateStackMutation, RemoveMyEcsWsFargateStackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMyEcsWsFargateStackMutation, RemoveMyEcsWsFargateStackMutationVariables>(RemoveMyEcsWsFargateStackDocument, options);
      }
export type RemoveMyEcsWsFargateStackMutationHookResult = ReturnType<typeof useRemoveMyEcsWsFargateStackMutation>;
export type RemoveMyEcsWsFargateStackMutationResult = Apollo.MutationResult<RemoveMyEcsWsFargateStackMutation>;
export type RemoveMyEcsWsFargateStackMutationOptions = Apollo.BaseMutationOptions<RemoveMyEcsWsFargateStackMutation, RemoveMyEcsWsFargateStackMutationVariables>;
export const GetMyTeamMembersDocument = gql`
    query GetMyTeamMembers {
  getMyTeamMembers {
    id
    name
    email
    status
  }
}
    `;

/**
 * __useGetMyTeamMembersQuery__
 *
 * To run a query within a React component, call `useGetMyTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyTeamMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyTeamMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetMyTeamMembersQuery, GetMyTeamMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyTeamMembersQuery, GetMyTeamMembersQueryVariables>(GetMyTeamMembersDocument, options);
      }
export function useGetMyTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyTeamMembersQuery, GetMyTeamMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyTeamMembersQuery, GetMyTeamMembersQueryVariables>(GetMyTeamMembersDocument, options);
        }
export function useGetMyTeamMembersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyTeamMembersQuery, GetMyTeamMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyTeamMembersQuery, GetMyTeamMembersQueryVariables>(GetMyTeamMembersDocument, options);
        }
export type GetMyTeamMembersQueryHookResult = ReturnType<typeof useGetMyTeamMembersQuery>;
export type GetMyTeamMembersLazyQueryHookResult = ReturnType<typeof useGetMyTeamMembersLazyQuery>;
export type GetMyTeamMembersSuspenseQueryHookResult = ReturnType<typeof useGetMyTeamMembersSuspenseQuery>;
export type GetMyTeamMembersQueryResult = Apollo.QueryResult<GetMyTeamMembersQuery, GetMyTeamMembersQueryVariables>;
export const InviteMyTeamMemberDocument = gql`
    mutation InviteMyTeamMember($email: String!) {
  inviteMyTeamMember(email: $email) {
    id
    name
    email
    status
  }
}
    `;
export type InviteMyTeamMemberMutationFn = Apollo.MutationFunction<InviteMyTeamMemberMutation, InviteMyTeamMemberMutationVariables>;

/**
 * __useInviteMyTeamMemberMutation__
 *
 * To run a mutation, you first call `useInviteMyTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMyTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMyTeamMemberMutation, { data, loading, error }] = useInviteMyTeamMemberMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInviteMyTeamMemberMutation(baseOptions?: Apollo.MutationHookOptions<InviteMyTeamMemberMutation, InviteMyTeamMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteMyTeamMemberMutation, InviteMyTeamMemberMutationVariables>(InviteMyTeamMemberDocument, options);
      }
export type InviteMyTeamMemberMutationHookResult = ReturnType<typeof useInviteMyTeamMemberMutation>;
export type InviteMyTeamMemberMutationResult = Apollo.MutationResult<InviteMyTeamMemberMutation>;
export type InviteMyTeamMemberMutationOptions = Apollo.BaseMutationOptions<InviteMyTeamMemberMutation, InviteMyTeamMemberMutationVariables>;
export const SetMyOrganizationDocument = gql`
    mutation SetMyOrganization($organizationName: String!) {
  setMyOrganization(organizationName: $organizationName) {
    id
    name
    features {
      singleDeployment
    }
    onboardingComplete
  }
}
    `;
export type SetMyOrganizationMutationFn = Apollo.MutationFunction<SetMyOrganizationMutation, SetMyOrganizationMutationVariables>;

/**
 * __useSetMyOrganizationMutation__
 *
 * To run a mutation, you first call `useSetMyOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMyOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMyOrganizationMutation, { data, loading, error }] = useSetMyOrganizationMutation({
 *   variables: {
 *      organizationName: // value for 'organizationName'
 *   },
 * });
 */
export function useSetMyOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<SetMyOrganizationMutation, SetMyOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMyOrganizationMutation, SetMyOrganizationMutationVariables>(SetMyOrganizationDocument, options);
      }
export type SetMyOrganizationMutationHookResult = ReturnType<typeof useSetMyOrganizationMutation>;
export type SetMyOrganizationMutationResult = Apollo.MutationResult<SetMyOrganizationMutation>;
export type SetMyOrganizationMutationOptions = Apollo.BaseMutationOptions<SetMyOrganizationMutation, SetMyOrganizationMutationVariables>;
export const WhoAmIDocument = gql`
    query WhoAmI {
  whoAmI {
    id
    name
    email
    phoneNumber
    picture
    role
    organizationId
  }
}
    `;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(baseOptions?: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
      }
export function useWhoAmILazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export function useWhoAmISuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmISuspenseQueryHookResult = ReturnType<typeof useWhoAmISuspenseQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<WhoAmIQuery, WhoAmIQueryVariables>;
export const namedOperations = {
  Query: {
    FindMyAlertsStackValues: 'FindMyAlertsStackValues',
    FindMyApiStackValues: 'FindMyApiStackValues',
    FindMyAuthenticationStackValues: 'FindMyAuthenticationStackValues',
    FindMyDeploymentStackValues: 'FindMyDeploymentStackValues',
    FindMyHttpApiStackValues: 'FindMyHttpApiStackValues',
    FindMyVpcStackValues: 'FindMyVpcStackValues',
    FindMyWsApiStackValues: 'FindMyWsApiStackValues',
    GetMyAlertsStackStatus: 'GetMyAlertsStackStatus',
    GetMyApiStackStatus: 'GetMyApiStackStatus',
    GetMyAuthenticationStackStatus: 'GetMyAuthenticationStackStatus',
    GetMyCertificate: 'GetMyCertificate',
    GetMyCertificateCnameRecordStatus: 'GetMyCertificateCnameRecordStatus',
    GetMyDeploymentStackStatus: 'GetMyDeploymentStackStatus',
    GetMyDeployments: 'GetMyDeployments',
    GetMyFullDomainNameHostedZoneStatus: 'GetMyFullDomainNameHostedZoneStatus',
    GetMyFullDomainNameHostedZones: 'GetMyFullDomainNameHostedZones',
    GetMyGitConnections: 'GetMyGitConnections',
    GetMyHttpApiStackStatus: 'GetMyHttpApiStackStatus',
    GetMyVpcStackStatus: 'GetMyVpcStackStatus',
    GetMyWaffleCfnTemplate: 'GetMyWaffleCfnTemplate',
    GetMyWaffleFullDomainNameNsRecord: 'GetMyWaffleFullDomainNameNsRecord',
    GetMyWaffleFullDomainNameNsRecordStatus: 'GetMyWaffleFullDomainNameNsRecordStatus',
    GetMyWsApiStackStatus: 'GetMyWsApiStackStatus',
    TestMyWaffleRole: 'TestMyWaffleRole',
    HelloWorld: 'HelloWorld',
    GetAllOrganizations: 'GetAllOrganizations',
    GetMyOrganization: 'GetMyOrganization',
    GetOrganization: 'GetOrganization',
    GetMyCdnServiceStackStatus: 'GetMyCdnServiceStackStatus',
    GetMyCfnServiceStackStatus: 'GetMyCfnServiceStackStatus',
    getMyCfnStackIds: 'getMyCfnStackIds',
    getMyCfnStackParameters: 'getMyCfnStackParameters',
    getMyCfnStackStatus: 'getMyCfnStackStatus',
    getMyCfnStackTemplate: 'getMyCfnStackTemplate',
    getMyDbEngineVersions: 'getMyDbEngineVersions',
    getMyDbFamilies: 'getMyDbFamilies',
    getMyDbInstanceClasses: 'getMyDbInstanceClasses',
    GetMyDbServiceStackStatus: 'GetMyDbServiceStackStatus',
    getMyDbSnapshotIds: 'getMyDbSnapshotIds',
    GetMyEcsServiceStackStatus: 'GetMyEcsServiceStackStatus',
    GetMyEcsWsServiceStackStatus: 'GetMyEcsWsServiceStackStatus',
    GetMyServiceStacks: 'GetMyServiceStacks',
    GetMyTeamMembers: 'GetMyTeamMembers',
    WhoAmI: 'WhoAmI'
  },
  Mutation: {
    AddMyDeployment: 'AddMyDeployment',
    CreateMyAlertsStack: 'CreateMyAlertsStack',
    CreateMyApiStack: 'CreateMyApiStack',
    CreateMyAuthenticationStack: 'CreateMyAuthenticationStack',
    CreateMyCertificateCnameRecord: 'CreateMyCertificateCnameRecord',
    CreateMyDeploymentStack: 'CreateMyDeploymentStack',
    CreateMyFullDomainNameHostedZone: 'CreateMyFullDomainNameHostedZone',
    CreateMyGitConnection: 'CreateMyGitConnection',
    CreateMyHttpApiStack: 'CreateMyHttpApiStack',
    CreateMyVpcStack: 'CreateMyVpcStack',
    CreateMyWaffleFullDomainNameHostedZone: 'CreateMyWaffleFullDomainNameHostedZone',
    CreateMyWaffleFullDomainNameNsRecord: 'CreateMyWaffleFullDomainNameNsRecord',
    CreateMyWsApiStack: 'CreateMyWsApiStack',
    DeployMyApiGatewayStage: 'DeployMyApiGatewayStage',
    DeployMyApiStack: 'DeployMyApiStack',
    DeployMyAuthenticationStack: 'DeployMyAuthenticationStack',
    DeployMyHttpApiStack: 'DeployMyHttpApiStack',
    DeployMyWsApiStack: 'DeployMyWsApiStack',
    EditMyDeployment: 'EditMyDeployment',
    RequestMyCertificate: 'RequestMyCertificate',
    AddOrganization: 'AddOrganization',
    EditOrganization: 'EditOrganization',
    AddMyServiceStack: 'AddMyServiceStack',
    deployMyAnyCfnStack: 'deployMyAnyCfnStack',
    DeployMyCdnStack: 'DeployMyCdnStack',
    DeployMyCfnStack: 'DeployMyCfnStack',
    DeployMyDbStack: 'DeployMyDbStack',
    DeployMyEcsFargateStack: 'DeployMyEcsFargateStack',
    DeployMyEcsWsFargateStack: 'DeployMyEcsWsFargateStack',
    EditMyServiceStack: 'EditMyServiceStack',
    RemoveMyCdnStack: 'RemoveMyCdnStack',
    RemoveMyCfnStack: 'RemoveMyCfnStack',
    RemoveMyDbStack: 'RemoveMyDbStack',
    RemoveMyEcsFargateStack: 'RemoveMyEcsFargateStack',
    RemoveMyEcsWsFargateStack: 'RemoveMyEcsWsFargateStack',
    InviteMyTeamMember: 'InviteMyTeamMember',
    SetMyOrganization: 'SetMyOrganization'
  }
}