import { useContext, useEffect, useState } from "react";
import {
  useGetMyWaffleCfnTemplateQuery,
  useTestMyWaffleRoleLazyQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { AwsConnectionComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";

export const AwsConnectionContainer = (props: {
  deploymentId: string;
  navigateToNext: null | (() => void);
  navigateToPrevious: null | (() => void);
  navigateToHelp: null | (() => void);
}) => {
  const { deploymentId, navigateToNext, navigateToPrevious, navigateToHelp } =
    props;
  const { bgwService } = useContext(BgwContext);

  const {
    data: getCfnData,
    loading: getCfnLoading,
    error: getCfnError,
  } = useGetMyWaffleCfnTemplateQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId,
    },
  });
  const [queryError, setQueryError] = useState<string | null>(null);

  useEffect(() => {
    setQueryError(getCfnError?.message || null);
  }, [getCfnError]);

  const resetQueryError = () => {
    setQueryError(null);
  };

  const downloadCfnJsonBlob = (): void => {
    const jsonContent = getCfnData?.getMyWaffleCfnTemplate || null;
    if (jsonContent === null) {
      return;
    }
    const blob = new Blob([jsonContent], {
      type: "application/json;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", "waffle-role-cloudformation.json");
    pom.click();
    pom.remove();
  };

  const [
    testMyWaffleRole,
    { loading: testMyWaffleRoleLoading, error: testMyWaffleRoleError },
  ] = useTestMyWaffleRoleLazyQuery({
    client: bgwService.getClient(),
  });

  useEffect(() => {
    setQueryError(testMyWaffleRoleError?.message || null);
  }, [testMyWaffleRoleError]);

  const [connectionWorks, setConnectionWorks] = useState<boolean | null>(null);
  const [connectionDetails, setConnectionDetails] = useState<string | null>(
    null
  );

  const checkConnection = () => {
    testMyWaffleRole({
      variables: {
        deploymentId,
      },
      onCompleted: (data) => {
        const passed = data.testMyWaffleRole?.passed;
        setConnectionWorks(passed === undefined ? null : passed);
        const details = data.testMyWaffleRole?.details;
        setConnectionDetails(details === undefined ? null : details);
      },
    });
  };

  return (
    <AwsConnectionComponent
      loading={getCfnLoading || testMyWaffleRoleLoading}
      error={queryError}
      resetError={resetQueryError}
      downloadCfnJsonBlob={downloadCfnJsonBlob}
      connectionWorks={connectionWorks}
      connectionDetails={connectionDetails}
      checkConnection={checkConnection}
      done={connectionWorks || false}
      navigateToNext={navigateToNext}
      navigateToPrevious={navigateToPrevious}
      navigateToHelp={navigateToHelp}
    />
  );
};
