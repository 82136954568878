import { useContext, useState } from "react";
import { PrivateNetworkingComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import { AlertType } from "../../../contexts/alerts/type";
import { useGetMyDeploymentsQuery } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { ApiDeployer } from "./api_deployer";
import { VpcDeployer } from "./vpc_deployer";
import { WsApiDeployer } from "./ws_api_deployer";
import { HttpApiDeployer } from "./http_api_deployer";
import { FeaturesContext } from "../../../contexts/features/context";

export const PrivateNetworkingContainer = (props: {
  deploymentId: string;
  navigateToNext: null | (() => void);
  navigateToPrevious: null | (() => void);
  navigateToHelp: null | (() => void);
}) => {
  const { deploymentId, navigateToNext, navigateToPrevious, navigateToHelp } =
    props;

  const [apiV1Selected, setApiV1Selected] = useState(false);
  const [wsApiSelected, setWsApiSelected] = useState(false);
  const [httpApiSelected, setHttpApiSelected] = useState(false);

  const [deploymentEnabled, setDeploymentEnabled] = useState<boolean>(false);

  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);
  const { apiV1Enabled, wsApiEnabled, httpApiEnabled } =
    useContext(FeaturesContext);

  const { data: getMyDeploymentsData, loading: getMyDeploymentsLoading } =
    useGetMyDeploymentsQuery({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });

  const deployment = getMyDeploymentsData?.getMyDeployments?.find(
    (d) => d.id === deploymentId
  );

  const [vpcStackCompleted, setVpcStackCompleted] = useState<boolean>(false);
  const [apiV1StackCompleted, setApiV1StackCompleted] =
    useState<boolean>(false);
  const [wsApiStackCompleted, setWsApiStackCompleted] =
    useState<boolean>(false);
  const [httpApiStackCompleted, setHttpApiStackCompleted] =
    useState<boolean>(false);

  const percentDone =
    (((vpcStackCompleted ? 1 : 0) +
      (apiV1StackCompleted ? 1 : 0) +
      (wsApiStackCompleted ? 1 : 0) +
      (httpApiStackCompleted ? 1 : 0)) /
      (1 +
        (apiV1Selected ? 1 : 0) +
        (wsApiSelected ? 1 : 0) +
        (httpApiSelected ? 1 : 0))) *
    100;

  const deploymentCompleted =
    vpcStackCompleted &&
    (apiV1Selected ? apiV1StackCompleted : true) &&
    (wsApiSelected ? wsApiStackCompleted : true) &&
    (httpApiSelected ? httpApiStackCompleted : true);

  return (
    <PrivateNetworkingComponent
      loading={getMyDeploymentsLoading}
      deployers={[
        <VpcDeployer
          deploymentId={deploymentId}
          deployment={deployment || null}
          deploymentEnabled={deploymentEnabled}
          setStackCompleted={(status: boolean) => setVpcStackCompleted(status)}
        />,
        apiV1Selected ? (
          <ApiDeployer
            deploymentId={deploymentId}
            deployment={deployment || null}
            deploymentEnabled={deploymentEnabled}
            setStackCompleted={(status: boolean) =>
              setApiV1StackCompleted(status)
            }
          />
        ) : null,
        wsApiSelected ? (
          <WsApiDeployer
            deploymentId={deploymentId}
            deployment={deployment || null}
            deploymentEnabled={deploymentEnabled}
            setStackCompleted={(status: boolean) =>
              setWsApiStackCompleted(status)
            }
          />
        ) : null,
        httpApiSelected ? (
          <HttpApiDeployer
            deploymentId={deploymentId}
            deployment={deployment || null}
            deploymentEnabled={deploymentEnabled}
            setStackCompleted={(status: boolean) =>
              setHttpApiStackCompleted(status)
            }
          />
        ) : null,
      ]}
      apiV1Enabled={apiV1Enabled}
      wsApiEnabled={wsApiEnabled}
      httpApiEnabled={httpApiEnabled}
      apiV1Selected={apiV1Selected}
      setApiV1Selected={setApiV1Selected}
      wsApiSelected={wsApiSelected}
      setWsApiSelected={setWsApiSelected}
      httpApiSelected={httpApiSelected}
      setHttpApiSelected={setHttpApiSelected}
      navigateToNext={navigateToNext}
      navigateToPrevious={navigateToPrevious}
      navigateToHelp={navigateToHelp}
      deploymentCompleted={deploymentCompleted}
      percentDone={percentDone}
      deploymentEnabled={deploymentEnabled}
      setDeploymentEnabled={setDeploymentEnabled}
    />
  );
};
