import Alert from "@mui/joy/Alert";
import Button from "@mui/joy/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import Grid from "@mui/joy/Grid";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import { PageFrame } from "../../components/page_frame";
import { PageLoading } from "../../components/page_loading";
import { PageTitle } from "../../components/page_title";
import Textarea from "@mui/joy/Textarea";

export const MyAnyStackComponent = (props: {
  loading: boolean;
  deploymentIds: string[];
  selectedDeploymentId: string | null;
  setSelectedDeploymentId: (deploymentId: string) => void;
  stackIds: string[];
  selectedStackId: string | null;
  setSelectedStackId: (stackId: string) => void;
  stackTemplate: string;
  setStackTemplate: (stackTemplate: string) => void;
  stackParameters: string;
  setStackParameters: (stackParameters: string) => void;
  stackStatus: string | null;
  refreshStackStatus: () => void;
  deployStack: () => void;
}) => {
  const {
    loading,
    deploymentIds,
    selectedDeploymentId,
    setSelectedDeploymentId,
    stackIds,
    selectedStackId,
    setSelectedStackId,
    stackTemplate,
    setStackTemplate,
    stackParameters,
    setStackParameters,
    stackStatus,
    refreshStackStatus,
    deployStack,
  } = props;
  return (
    <PageFrame>
      <PageTitle>My Any Stack</PageTitle>
      <PageLoading loading={loading} />
      <Grid
        container
        spacing={2}
        sx={{ alignItems: "flex-start", flexGrow: 1 }}
      >
        <Grid xs={12}>
          <FormControl>
            <FormLabel>Deployment ID</FormLabel>
            <Select value={selectedDeploymentId || ""}>
              {deploymentIds.map((id) => (
                <Option
                  key={id}
                  value={id}
                  onClick={() => setSelectedDeploymentId(id)}
                >
                  {id}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <FormControl>
            <FormLabel>Stack ID</FormLabel>
            <Select value={selectedStackId || ""}>
              {stackIds.map((id) => (
                <Option
                  key={id}
                  value={id}
                  onClick={() => setSelectedStackId(id)}
                >
                  {id}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <Alert
            color={
              stackStatus === "CREATE_COMPLETE" ||
              stackStatus === "UPDATE_COMPLETE"
                ? "success"
                : (stackStatus || "").indexOf("CREATE") > -1 ||
                  (stackStatus || "").indexOf("UPDATE") > -1
                ? "primary"
                : (stackStatus || "").indexOf("ROLLBACK") > -1
                ? "danger"
                : "neutral"
            }
            variant="soft"
            sx={{ mb: 2 }}
            startDecorator={
              (stackStatus || "").indexOf("IN_PROGRESS") > -1 ? (
                <CircularProgress
                  size="sm"
                  color={
                    stackStatus === "CREATE_COMPLETE" ||
                    stackStatus === "UPDATE_COMPLETE"
                      ? "success"
                      : (stackStatus || "").indexOf("CREATE") > -1 ||
                        (stackStatus || "").indexOf("UPDATE") > -1
                      ? "primary"
                      : (stackStatus || "").indexOf("ROLLBACK") > -1
                      ? "danger"
                      : "neutral"
                  }
                />
              ) : null
            }
            endDecorator={
              (stackStatus || "").indexOf("IN_PROGRESS") === -1 ? (
                <Button
                  size="sm"
                  variant="soft"
                  color={
                    stackStatus === "CREATE_COMPLETE" ||
                    stackStatus === "UPDATE_COMPLETE"
                      ? "success"
                      : (stackStatus || "").indexOf("CREATE") > -1 ||
                        (stackStatus || "").indexOf("UPDATE") > -1
                      ? "primary"
                      : (stackStatus || "").indexOf("ROLLBACK") > -1
                      ? "danger"
                      : "neutral"
                  }
                  onClick={() => {
                    refreshStackStatus();
                  }}
                >
                  Refresh
                </Button>
              ) : null
            }
          >
            Deployment status: {stackStatus}
          </Alert>
        </Grid>

        <Grid xs={12}>
          <FormControl>
            <FormLabel>Stack Template</FormLabel>
            <Textarea
              value={stackTemplate}
              onChange={(e) => setStackTemplate(e.target.value)}
              minRows={3}
            />
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <FormControl>
            <FormLabel>Stack Parameters</FormLabel>
            <Textarea
              value={stackParameters}
              onChange={(e) => setStackParameters(e.target.value)}
              minRows={3}
            />
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <Button
            disabled={
              loading ||
              (stackStatus || "").indexOf("IN_PROGRESS") > -1 ||
              (stackStatus || "").indexOf("DELETE_FAILED") > -1
            }
            onClick={() => deployStack()}
          >
            {(stackStatus || null) === null ? "Deploy" : "Update"} Stack
          </Button>
        </Grid>
      </Grid>
    </PageFrame>
  );
};
