import { AwsConnectionContainer } from "./aws_connection/container";
import { AwsConnectionHelpPane } from "./aws_connection/help_pane";
import { DomainNamesAndCertsContainer } from "./domain_names_and_certs/container";
import { DomainNamesAndCertsHelpPane } from "./domain_names_and_certs/help_pane";
import { GitConnectionsContainer } from "./git_connections/container";
import { PrivateNetworkingContainer } from "./private_networking/container";

export type StepDef = {
  id: string;
  title: string;
  component: (props: {
    deploymentId: string;
    navigateToNext: null | (() => void);
    navigateToPrevious: null | (() => void);
    navigateWhenFinished: () => void;
    navigateToHelp: null | (() => void);
  }) => JSX.Element;
  helpComponent?: (props: { navigateBack: () => void }) => JSX.Element;
};

/*
- aws connection
- domain names and certs
- vpc and api gateway
- git connection
- alerts with slack notifications
- environment secrets
- authentication
*/

export const MY_ENVIRONMENT_STEPS: StepDef[] = [
  {
    id: "aws_connection",
    title: "AWS Connection",
    component: AwsConnectionContainer,
    helpComponent: AwsConnectionHelpPane,
  },
  {
    id: "domain_names",
    title: "Domain Names",
    component: DomainNamesAndCertsContainer,
    helpComponent: DomainNamesAndCertsHelpPane,
  },
  {
    id: "private_networking",
    title: "Private Networking",
    component: PrivateNetworkingContainer,
  },
  {
    id: "git_connection",
    title: "Git Connection",
    component: GitConnectionsContainer,
  },
  // {
  //   id: "alerts",
  //   title: "Alerts",
  //   component: AlertsContainer,
  // },
  // {
  //   id: "environment_secrets",
  //   title: "Environment Secrets",
  //   component: EnvironmentSecretsContainer,
  // },
  // {
  //   id: "authentication",
  //   title: "Authentication",
  //   component: AuthenticationContainer,
  // },
];
