import GitHubIcon from "@mui/icons-material/GitHub";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Alert from "@mui/joy/Alert";
import Avatar from "@mui/joy/Avatar";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Grid from "@mui/joy/Grid";
import { useEffect, useRef, useState } from "react";
import {
  Deployment,
  GitConnection,
  ServiceStack,
  StackStatus,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import GitLabIcon from "../../../components/logos/gitlab-logo-500.svg";
import BitBucketIcon from "../../../components/logos/bitbucket-logo.svg";
import { PageLoading } from "../../../components/page_loading";
import Link from "@mui/joy/Link";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import Switch from "@mui/joy/Switch";
import { LogRetentionDays } from "../const";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/joy/DialogActions";
import Divider from "@mui/joy/Divider";

export const DeployEcsServiceComponent = (props: {
  loading: boolean;
  stack: ServiceStack;
  deployment: Deployment | null;
  stackStatus: StackStatus | null;
  gitConnectionArns: GitConnection[];
  queryError: string[];
  removeQueryError: (i: number) => void;
  deployEcsService: (
    instanceCount: number,
    gitConnectionArn: string,
    gitRepositoryId: string,
    gitBranch: string,
    buildspecPath: string,
    dockerfilePath: string,
    runtimeJson: string,
    buildtimeJson: string,
    ecsTaskCpu: number,
    ecsTaskRam: number,
    servicePort: number,
    healthCheckPath: string,
    healthCheckPort: number,
    logRetentionDays: number,
    alarmsEnabled: boolean,
    apiResourceEnabled: boolean,
    apiV2ResourceEnabled: boolean,
    apiV2WsResourceEnabled: boolean,
    apiKeyRequired: boolean,
    iamAuthRequired: boolean,
    cicdManualApproval: boolean,
    mapToRootPath: boolean,
    stickySessionEnabled: boolean,
    stickySessionType: string,
    autoscaleEnabled: boolean,
    maxInstanceCount: number
  ) => void;
  refetchStackStatus: () => void;
  removeEcsService: () => void;
  amIRoot: boolean;
  apiV1Enabled: boolean;
  httpApiEnabled: boolean;
  wsApiEnabled: boolean;
}) => {
  const {
    loading,
    stack,
    deployment,
    stackStatus,
    gitConnectionArns,
    queryError,
    removeQueryError,
    deployEcsService,
    refetchStackStatus,
    removeEcsService,
    amIRoot,
    apiV1Enabled,
    httpApiEnabled,
    wsApiEnabled,
  } = props;
  const [instanceCount, setInstanceCount] = useState<number>(0);
  const [gitConnectionArn, setGitConnectionArn] = useState<string>("");
  const [gitRepositoryId, setGitRepositoryId] = useState<string>("");
  const [gitBranch, setGitBranch] = useState<string>("");
  const [dockerfilePath, setDockerfilePath] = useState<string>("");
  const [buildspecPath, setBuildspecPath] = useState<string>("");
  const [runtimeJson, setRuntimeJson] = useState<string>("");
  const [buildtimeJson, setBuildtimeJson] = useState<string>("");
  const [ecsTaskCpu, setEcsTaskCpu] = useState<number>(256);
  const [ecsTaskRam, setEcsTaskRam] = useState<number>(512);
  const [servicePort, setServicePort] = useState<number>(80);
  const [healthCheckPath, setHealthCheckPath] =
    useState<string>("/health_check");
  const [healthCheckPort, setHealthCheckPort] = useState<number>(80);
  const [logRetentionDays, setLogRetentionDays] = useState<number>(365);
  const [alarmsEnabled, setAlarmsEnabled] = useState<boolean>(true);
  const [apiResourceEnabled, setApiResourceEnabled] = useState<boolean>(true);
  const [apiV2ResourceEnabled, setApiV2ResourceEnabled] =
    useState<boolean>(false);
  const [apiV2WsResourceEnabled, setApiV2WsResourceEnabled] =
    useState<boolean>(false);
  const [apiKeyRequired, setApiKeyRequired] = useState<boolean>(true);
  const [iamAuthRequired, setIamAuthRequired] = useState<boolean>(true);
  const [cicdManualApproval, setCicdManualApproval] = useState<boolean>(true);
  const [mapToRootPath, setMapToRootPath] = useState<boolean>(false);
  const [stickySessionEnabled, setStickySessionEnabled] =
    useState<boolean>(false);
  const [stickySessionType, setStickySessionType] =
    useState<string>("lb_cookie");
  const [autoscaleEnabled, setAutoscaleEnabled] = useState<boolean>(false);
  const [maxInstanceCount, setMaxInstanceCount] = useState<number>(1);

  const [deployedApiResourceEnabled, setDeployedApiResourceEnabled] =
    useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  useEffect(() => {
    if (stackStatus !== null && (stackStatus.parametersJSON || null) !== null) {
      try {
        const params = JSON.parse(stackStatus.parametersJSON!);
        setInstanceCount(
          params.InstanceCount !== undefined
            ? parseInt(params.InstanceCount)
            : 0
        );
        setGitConnectionArn(params.GitConnectionArn || "");
        setGitRepositoryId(params.GitRepositoryId || "");
        setGitBranch(params.GitBranch || "");
        setDockerfilePath(params.DockerfilePath || "");
        setBuildspecPath(params.BuildspecPath || "");
        setRuntimeJson(params.RuntimeJson || "");
        setBuildtimeJson(params.BuildEnvVarsJson || "");
        setEcsTaskCpu(
          params.EcsTaskCPU !== undefined ? parseInt(params.EcsTaskCPU) : 256
        );
        setEcsTaskRam(
          params.EcsTaskRAM !== undefined ? parseInt(params.EcsTaskRAM) : 512
        );
        setServicePort(parseInt(params.ServicePort) || 80);
        setHealthCheckPath(params.HealthCheckPath || "/health_check");
        setHealthCheckPort(parseInt(params.HealthCheckPort) || 80);
        setLogRetentionDays(
          params.LogRetentionDays !== undefined
            ? parseInt(params.LogRetentionDays)
            : 365
        );
        setAlarmsEnabled(params.AlarmsEnabled === "True");
        setApiResourceEnabled(params.ApiResourceEnabled === "True");
        setApiV2ResourceEnabled(params.ApiV2ResourceEnabled === "True");
        setApiV2WsResourceEnabled(params.ApiV2WsResourceEnabled === "True");
        setDeployedApiResourceEnabled(params.ApiResourceEnabled === "True");
        setApiKeyRequired(params.ApiKeyRequired === "True");
        setIamAuthRequired(params.IamAuthRequired === "True");
        setCicdManualApproval(params.CICDManualApproval === "True");
        setMapToRootPath(params.MapToRootPath === "True");
        setStickySessionEnabled(params.StickySessionEnabled === "True");
        setStickySessionType(params.StickySessionType || "lb_cookie");
        setAutoscaleEnabled(params.AutoscaleEnabled === "True");
        setMaxInstanceCount(parseInt(params.MaxInstanceCount) || 1);
        // AuthUserPoolArn
        // AlertsSnsTopicRef
      } catch (err) {
        console.info("Failed to parse parametersJSON");
      }
    }
  }, [stackStatus]);

  const cpuInputRef = useRef<HTMLInputElement | null>(null);
  const ramInputRef = useRef<HTMLInputElement | null>(null);
  const instanceCountInputRef = useRef<HTMLInputElement | null>(null);
  const servicePortInputRef = useRef<HTMLInputElement | null>(null);
  const logRetentionDaysInputRef = useRef<HTMLInputElement | null>(null);
  const healthCheckPortInputRef = useRef<HTMLInputElement | null>(null);

  const validLogRetentionDays = LogRetentionDays.includes(logRetentionDays);

  return (
    <>
      <PageLoading loading={loading} />
      {queryError.map((e, i) => (
        <Alert
          key={i}
          color="danger"
          variant="soft"
          endDecorator={
            <Button
              color="danger"
              variant="soft"
              onClick={() => removeQueryError(i)}
            >
              DISMISS
            </Button>
          }
          sx={{ mb: 2 }}
        >
          Error: {e}
        </Alert>
      ))}
      <Grid
        container
        spacing={2}
        sx={{ alignItems: "flex-start", flexGrow: 1 }}
      >
        {!loading && (stackStatus?.status || null) === null && (
          <Grid xs={12}>
            <Alert color="neutral" variant="soft" sx={{ mb: 2 }}>
              The service has not been deployed to this environment yet.
            </Alert>
          </Grid>
        )}
        {!loading &&
          stackStatus?.status !== null &&
          stackStatus?.status !== undefined && (
            <Grid xs={12}>
              <Alert
                color={
                  stackStatus?.status === "CREATE_COMPLETE" ||
                  stackStatus?.status === "UPDATE_COMPLETE"
                    ? "success"
                    : (stackStatus?.status || "").indexOf("CREATE") > -1 ||
                      (stackStatus?.status || "").indexOf("UPDATE") > -1
                    ? "primary"
                    : (stackStatus?.status || "").indexOf("ROLLBACK") > -1
                    ? "danger"
                    : "neutral"
                }
                variant="soft"
                sx={{ mb: 2 }}
                startDecorator={
                  (stackStatus?.status || "").indexOf("IN_PROGRESS") > -1 ? (
                    <CircularProgress
                      size="sm"
                      color={
                        stackStatus?.status === "CREATE_COMPLETE" ||
                        stackStatus?.status === "UPDATE_COMPLETE"
                          ? "success"
                          : (stackStatus?.status || "").indexOf("CREATE") >
                              -1 ||
                            (stackStatus?.status || "").indexOf("UPDATE") > -1
                          ? "primary"
                          : (stackStatus?.status || "").indexOf("ROLLBACK") > -1
                          ? "danger"
                          : "neutral"
                      }
                    />
                  ) : null
                }
                endDecorator={
                  (stackStatus?.status || "").indexOf("IN_PROGRESS") === -1 ? (
                    <Button
                      size="sm"
                      variant="soft"
                      color={
                        stackStatus?.status === "CREATE_COMPLETE" ||
                        stackStatus?.status === "UPDATE_COMPLETE"
                          ? "success"
                          : (stackStatus?.status || "").indexOf("CREATE") >
                              -1 ||
                            (stackStatus?.status || "").indexOf("UPDATE") > -1
                          ? "primary"
                          : (stackStatus?.status || "").indexOf("ROLLBACK") > -1
                          ? "danger"
                          : "neutral"
                      }
                      onClick={() => {
                        refetchStackStatus();
                      }}
                    >
                      Refresh
                    </Button>
                  ) : null
                }
              >
                Deployment status: {stackStatus?.status}
                <Link
                  href={`https://${deployment?.awsRegion}.console.aws.amazon.com/cloudformation/home?region=${deployment?.awsRegion}#/stacks/stackinfo?filteringText=&filteringStatus=active&viewNested=true&stackId=waffle-${deployment?.id}-${stack?.id}-ecs-cicd`}
                >
                  Details
                </Link>
              </Alert>
            </Grid>
          )}
        <Grid xs={12} sm={12}>
          <Typography level="title-lg">Source code</Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Select your git connection</FormLabel>
            <Select
              placeholder="Select Git Connection"
              value={gitConnectionArn}
              slotProps={{
                listbox: {
                  sx: {
                    maxHeight: 300,
                    overflow: "auto", // required for scrolling
                    zIndex: 1201, // Because of the floating drawer on mobile
                  },
                },
              }}
            >
              {gitConnectionArns?.map((conn, i) => (
                <Option
                  value={conn.id}
                  key={conn.id || i}
                  onClick={() => {
                    setGitConnectionArn(conn.id || "");
                  }}
                >
                  <ListItemDecorator>
                    {conn.providerType === "GitHub" ||
                    conn.providerType === "GitHubEnterpriseServer" ? (
                      <GitHubIcon />
                    ) : conn.providerType === "Bitbucket" ? (
                      <Avatar src={BitBucketIcon} />
                    ) : (
                      <Avatar src={GitLabIcon} />
                    )}
                  </ListItemDecorator>
                  {conn.name}
                </Option>
              ))}
            </Select>
            <FormHelperText>
              Select the connection to your git provider that has the repository
              that you want to deploy
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Git Repository</FormLabel>
            <Input
              value={gitRepositoryId}
              onChange={(event) => {
                setGitRepositoryId(event.target.value);
              }}
            />
            <FormHelperText>
              The owner and name of the repo expected in the following format:
              some-user/my-repo
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormLabel>Git Branch</FormLabel>
          <Input
            value={gitBranch}
            onChange={(event) => {
              setGitBranch(event.target.value);
            }}
          />
          <FormHelperText>The branch in the repo to deploy</FormHelperText>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Dockerfile path</FormLabel>
            <Input
              value={dockerfilePath}
              onChange={(event) => {
                setDockerfilePath(event.target.value);
              }}
            />
            <FormHelperText>
              The relative path in the repo to the Dockerfile. Leave this field
              empty if your prefer to specify a buildspec.yml file instead, see
              next field. For example: {stack.id}/Dockerfile
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Buildpsec path (optional)</FormLabel>
            <Input
              value={buildspecPath}
              onChange={(event) => {
                setBuildspecPath(event.target.value);
              }}
            />
            <FormHelperText>
              Instead of providing a Dockerfile, you can specify the path in the
              repo to a buildspec.yml file, to give detailed build instructions
              to the CICD pipeline's build phase. The path has to include the
              filename as well. For example: {stack.id}/buildspec.yml
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={12}>
          <Typography level="title-lg">Container settings</Typography>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>CPU per instance</FormLabel>
            <Input
              type="number"
              slotProps={{
                input: {
                  ref: cpuInputRef,
                  min: 256,
                  max: 4092,
                  step: 256,
                },
              }}
              value={ecsTaskCpu}
              endDecorator={<Typography fontSize="xs">CPU</Typography>}
              onChange={(event) => {
                setEcsTaskCpu(parseInt(event.target.value));
              }}
            />
            <FormHelperText>
              Amount of CPU capcity available for each container. 1024 refers to
              a single CPU.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>RAM per instance</FormLabel>
            <Input
              type="number"
              slotProps={{
                input: {
                  ref: ramInputRef,
                  min: 512,
                  max: 3072,
                  step: 512,
                },
              }}
              value={ecsTaskRam}
              endDecorator={<Typography fontSize="xs">MB</Typography>}
              onChange={(event) => {
                setEcsTaskRam(parseInt(event.target.value));
              }}
            />
            <FormHelperText>
              Amount of RAM available for each container, in MB.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Service port</FormLabel>
            <Input
              type="number"
              slotProps={{
                input: {
                  ref: servicePortInputRef,
                  min: 0,
                  max: 65535,
                  step: 1,
                },
              }}
              value={servicePort}
              onChange={(event) => {
                setServicePort(parseInt(event.target.value));
              }}
            />
            <FormHelperText>
              The port, which is exposed on the Docker container and accepts
              HTTP requests.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Number of instances</FormLabel>
            <Input
              type="number"
              slotProps={{
                input: {
                  ref: instanceCountInputRef,
                  min: 0,
                  step: 1,
                },
              }}
              value={instanceCount}
              onChange={(event) => {
                setInstanceCount(parseInt(event.target.value));
              }}
            />
            <FormHelperText>
              Number of continuously running instances. Recommended to deploy
              first with 0, to have the CICD and all else set up, and only then
              to increase the instance count to the desired number
            </FormHelperText>
          </FormControl>
        </Grid>

        {(apiV1Enabled || httpApiEnabled) && (
          <Grid xs={12} sm={12}>
            <Typography level="title-lg">API Access</Typography>
            <Typography level="body-sm">
              When the service is deployed with API Gateway resource enabled,
              then in order for the newly created or changed HTTP endpoint to
              start working, the API Gateway needs to be deployed manually. This
              is how AWS API Gateway v1 works. in order for the newly created or
              changed HTTP endpoint to start working, the API Gateway needs to
              be deployed manually. This is how AWS API Gateway v1 works.
            </Typography>
          </Grid>
        )}

        {apiV1Enabled && (
          <Grid xs={12} sm={6}>
            <FormControl>
              <FormLabel>HTTP API with API Keys Enabled</FormLabel>
              <Typography
                component="label"
                startDecorator={
                  <Switch
                    checked={apiResourceEnabled}
                    onChange={(event) =>
                      setApiResourceEnabled(event.target.checked)
                    }
                  />
                }
              >
                {apiResourceEnabled ? "Enabled" : "Disabled"}
              </Typography>
              <FormHelperText>
                Enables HTTP access by creating an endpoint on the API Gateway
                V1. The V1 API Gateway is capable of handling api keys with
                plans, and has a mandatory deployment step before changes go
                live. It also supports one service to be deployed to the top
                level of the API Gateway, which is ideal for SSR frontend
                applications.
              </FormHelperText>
            </FormControl>
          </Grid>
        )}

        {httpApiEnabled && (
          <Grid xs={12} sm={6}>
            <FormControl>
              <FormLabel>HTTP API with Auto-deployment Enabled</FormLabel>
              <Typography
                component="label"
                startDecorator={
                  <Switch
                    checked={apiV2ResourceEnabled}
                    onChange={(event) =>
                      setApiV2ResourceEnabled(event.target.checked)
                    }
                  />
                }
              >
                {apiV2ResourceEnabled ? "Enabled" : "Disabled"}
              </Typography>
              <FormHelperText>
                Enables HTTP access by creating an endpoint on the API Gateway
                V2. V2 doesn't require manual deployments, but it is not capable
                of handling api keys automatically.
              </FormHelperText>
            </FormControl>
          </Grid>
        )}

        {wsApiEnabled && (
          <Grid xs={12} sm={6}>
            <FormControl>
              <FormLabel>WebSocket API Enabled</FormLabel>
              <Typography
                component="label"
                startDecorator={
                  <Switch
                    checked={apiV2WsResourceEnabled}
                    onChange={(event) =>
                      setApiV2WsResourceEnabled(event.target.checked)
                    }
                  />
                }
              >
                {apiV2WsResourceEnabled ? "Enabled" : "Disabled"}
              </Typography>
              <FormHelperText>
                Enables this specific service to be accessed with a WebSocket
                connection by creating a API Gateway V2 WebSocket specific to
                this service. Only IAM authentication is supported.
              </FormHelperText>
            </FormControl>
          </Grid>
        )}

        {apiV1Enabled && apiResourceEnabled && (
          <Grid xs={12} sm={6}>
            <FormControl>
              <FormLabel>API Key Authentication</FormLabel>
              <Typography
                component="label"
                startDecorator={
                  <Switch
                    checked={apiKeyRequired}
                    onChange={(event) =>
                      setApiKeyRequired(event.target.checked)
                    }
                  />
                }
              >
                {apiKeyRequired ? "Enabled" : "Disabled"}
              </Typography>
              <FormHelperText>
                Enables this specific service to be accessed with API Key
                authentication. This is intended for serving backend services
                hosted at 3rd parties.
              </FormHelperText>
            </FormControl>
          </Grid>
        )}

        {(apiV1Enabled || httpApiEnabled) &&
          (apiResourceEnabled ||
            apiV2ResourceEnabled ||
            apiV2WsResourceEnabled) && (
            <Grid xs={12} sm={6}>
              <FormControl>
                <FormLabel>AWS IAM Authentication</FormLabel>
                <Typography
                  component="label"
                  startDecorator={
                    <Switch
                      checked={iamAuthRequired}
                      onChange={(event) =>
                        setIamAuthRequired(event.target.checked)
                      }
                    />
                  }
                >
                  {iamAuthRequired ? "Enabled" : "Disabled"}
                </Typography>
                <FormHelperText>
                  Enables this specific service to be accessed with AWS IAM
                  authentication. This is intended for serving frontend
                  applications.
                </FormHelperText>
              </FormControl>
            </Grid>
          )}

        <Grid xs={12} sm={12}>
          <Typography level="title-lg">Health Checking</Typography>
          <Typography level="body-sm">
            You have specifiy an url path that the service in the Docker
            container handles and responds with HTTP 200 OK to HTTP GET
            requests. There's an automated pulse-check every minute: If there is
            no answer from the specified url path or the response is not 2xx
            then the container is automatically killed and a new one is
            deployed.
          </Typography>
          <Typography level="body-sm">
            Heads-up: The CICD only considers the deployment successful if the
            specified health-check passes for a few times in a row. And if it
            fails then it retires to deploy the container several times before
            raising an error. It's recommended to firrst deploy the stack with 0
            instances, so the CICD pipeline is set up, and only then to increase
            the instance count to the desired number. This way if the docker
            build should fail, the stack creation will still be successful, and
            you can focus on fixing the build.
          </Typography>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Health-check path</FormLabel>
            <Input
              value={healthCheckPath}
              onChange={(event) => {
                setHealthCheckPath(event.target.value);
              }}
            />
            <FormHelperText>
              The URL path that responds with 2xx to HTTP GET requests
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Health-check port</FormLabel>
            <Input
              type="number"
              slotProps={{
                input: {
                  ref: healthCheckPortInputRef,
                  min: 0,
                  max: 65535,
                  step: 1,
                },
              }}
              value={healthCheckPort}
              onChange={(event) => {
                setHealthCheckPort(parseInt(event.target.value));
              }}
            />
            <FormHelperText>
              The port where the health-check is available. (Typically same as
              the service-port.)
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={12}>
          <Typography level="title-lg">Security and compliance</Typography>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Log retention</FormLabel>
            <Input
              type="number"
              slotProps={{
                input: {
                  ref: logRetentionDaysInputRef,
                  min: 0,
                  max: 365,
                  step: 1,
                },
              }}
              value={logRetentionDays}
              error={!validLogRetentionDays}
              endDecorator={<Typography fontSize="xs">days</Typography>}
              onChange={(event) => {
                setLogRetentionDays(parseInt(event.target.value));
              }}
            />
            <FormHelperText>
              Allowed values: {LogRetentionDays.join(", ")}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Alarms enabled</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={alarmsEnabled}
                  onChange={(event) => setAlarmsEnabled(event.target.checked)}
                />
              }
            >
              {alarmsEnabled ? "Enabled" : "Disabled"}
            </Typography>
            <FormHelperText>
              Automated alarms for being over a RAM and CPU threshold,
              experiencing a health-check blackout, or HTTP 5xx errors from the
              load balancer.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Manual CICD Approval</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={cicdManualApproval}
                  onChange={(event) =>
                    setCicdManualApproval(event.target.checked)
                  }
                />
              }
            >
              {cicdManualApproval ? "Enabled" : "Disabled"}
            </Typography>
            <FormHelperText>
              If enabled, there will be a step required in the CICD to manually
              approve every deployment.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={12}>
          <Typography level="title-lg">Advanced settings</Typography>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Run-time JSON</FormLabel>
            <Input
              value={runtimeJson}
              onChange={(event) => {
                setRuntimeJson(event.target.value);
              }}
              endDecorator={<Typography fontSize="xs">JSON</Typography>}
            />
            <FormHelperText>
              An optional JSON string that you can access from the runnning
              containers as an environmental variable. Unsure? Leave it empty.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Build-time JSON</FormLabel>
            <Input
              value={buildtimeJson}
              onChange={(event) => {
                setBuildtimeJson(event.target.value);
              }}
              endDecorator={<Typography fontSize="xs">JSON</Typography>}
            />
            <FormHelperText>
              An optional JSON string that you can send access when building the
              container as an environmental variable. Unsure? Leave it empty.
            </FormHelperText>
          </FormControl>
        </Grid>

        {apiResourceEnabled && (
          <Grid xs={12} sm={6}>
            <FormControl>
              <FormLabel>Map to root API path</FormLabel>
              <Typography
                component="label"
                startDecorator={
                  <Switch
                    checked={mapToRootPath}
                    onChange={(event) => setMapToRootPath(event.target.checked)}
                  />
                }
              >
                {mapToRootPath ? "Enabled" : "Disabled"}
              </Typography>
              <FormHelperText>
                If enabled then accessing the /Prod or / path of the api will
                point to this service. This setting can only be turned on for
                one service at a time, otherwise deployment will fail.
              </FormHelperText>
            </FormControl>
          </Grid>
        )}

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Sticky Session</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={stickySessionEnabled}
                  onChange={(event) =>
                    setStickySessionEnabled(event.target.checked)
                  }
                />
              }
            >
              {stickySessionEnabled ? "Enabled" : "Disabled"}
            </Typography>
            <FormHelperText>
              Enables sticky sessions on the load balancer.
            </FormHelperText>
          </FormControl>
        </Grid>

        {stickySessionEnabled && (
          <Grid xs={12} sm={6}>
            <FormControl>
              <FormLabel>Sticky Session Type</FormLabel>
              <Select
                placeholder="Select Sticky Session Type"
                disabled={!stickySessionEnabled}
                value={stickySessionType}
                slotProps={{
                  listbox: {
                    sx: {
                      maxHeight: 300,
                      overflow: "auto", // required for scrolling
                      zIndex: 1201, // Because of the floating drawer on mobile
                    },
                  },
                }}
              >
                {["lb_cookie", "source_ip"].map((type, i) => (
                  <Option
                    value={type}
                    key={type || i}
                    onClick={() => {
                      setStickySessionType(type);
                    }}
                  >
                    {type}
                  </Option>
                ))}
              </Select>
              <FormHelperText>
                Sets how stickyness is handled on the load balancer.
              </FormHelperText>
            </FormControl>
          </Grid>
        )}

        <Grid xs={12}>
          <Button
            onClick={() =>
              deployEcsService(
                instanceCount,
                gitConnectionArn,
                gitRepositoryId,
                gitBranch,
                buildspecPath,
                dockerfilePath,
                runtimeJson,
                buildtimeJson,
                ecsTaskCpu,
                ecsTaskRam,
                servicePort,
                healthCheckPath,
                healthCheckPort,
                logRetentionDays,
                alarmsEnabled,
                apiResourceEnabled,
                apiV2ResourceEnabled,
                apiV2WsResourceEnabled,
                apiKeyRequired,
                iamAuthRequired,
                cicdManualApproval,
                mapToRootPath,
                stickySessionEnabled,
                stickySessionType,
                autoscaleEnabled,
                maxInstanceCount
              )
            }
            disabled={
              loading ||
              (stackStatus?.status || "").indexOf("IN_PROGRESS") > -1 ||
              !validLogRetentionDays
            }
          >
            {(stackStatus?.status || null) === null ? "Deploy" : "Update"}{" "}
            Service
          </Button>
          {(stackStatus?.status || null) !== null && amIRoot && (
            <Button
              sx={{ ml: 2 }}
              onClick={() => setOpenModal(true)}
              disabled={
                loading ||
                (stackStatus?.status || "").indexOf("IN_PROGRESS") > -1 ||
                (stackStatus?.status || "").indexOf("DELETE_FAILED") > -1
              }
              variant="plain"
              color="neutral"
            >
              Remove Service
            </Button>
          )}
        </Grid>
      </Grid>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            {deployedApiResourceEnabled
              ? "Service is mapped to an API resource"
              : "Are you sure to remove the service?"}
          </DialogTitle>
          <Divider />
          <DialogContent>
            {deployedApiResourceEnabled
              ? `
                This service is currently mapped to an API resource. That would
                make the removal of the service fail. Please update the service
                first disabling the API resource, and only then remove the
                service.
                `
              : `
                This action will remove the service and all associated resources
                including the CICD pipeline.
                `}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                removeEcsService();
                setOpenModal(false);
              }}
              disabled={loading || deployedApiResourceEnabled}
              variant="solid"
              color="danger"
            >
              Remove Service
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};
