import { useContext, useEffect, useState } from "react";
import {
  Deployment,
  ServiceStack,
  useDeployMyEcsWsFargateStackMutation,
  useGetMyEcsWsServiceStackStatusQuery,
  useGetMyGitConnectionsQuery,
  useRemoveMyEcsWsFargateStackMutation,
  useWhoAmIQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { DeployEcsWsServiceComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import { AlertType } from "../../../contexts/alerts/type";

export const DeployEcsWsServiceContainer = (props: {
  deployment: Deployment;
  stack: ServiceStack;
}) => {
  const { deployment, stack } = props;
  const [queryError, setQueryError] = useState<string[]>([]);
  const [mutationInProgress, setMutationInProgress] = useState<boolean>(false);
  const removeQueryError = (i: number) => {
    setQueryError((e) => {
      const newErrors = [...e];
      newErrors.splice(i, 1);
      return newErrors;
    });
  };

  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const {
    data: getMyGitConnectionsData,
    loading: getMyGitConnectionsLoading,
    error: getMyGitConnectionsError,
  } = useGetMyGitConnectionsQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId: deployment.id,
    },
  });

  useEffect(() => {
    if ((getMyGitConnectionsError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyGitConnectionsError!.message]);
    }
  }, [getMyGitConnectionsError]);

  const {
    data: getMyEcsWsServiceStackStatusData,
    loading: getMyEcsWsServiceStackStatusLoading,
    error: getMyEcsWsServiceStackStatusError,
    refetch: getMyEcsWsServiceStackStatusRefetch,
  } = useGetMyEcsWsServiceStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId: deployment.id,
      pipelineId: stack.id,
    },
  });

  useEffect(() => {
    if ((getMyEcsWsServiceStackStatusError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyEcsWsServiceStackStatusError!.message]);
    }
  }, [getMyEcsWsServiceStackStatusError]);

  const [
    deployMyEcsWsFargateStackMutation,
    { loading: deployMyEcsWsFargateStackLoading },
  ] = useDeployMyEcsWsFargateStackMutation({
    client: bgwService.getClient(),
  });

  const deployEcsWsService = (
    instanceCount: number,
    gitConnectionArn: string,
    gitRepositoryId: string,
    gitBranch: string,
    buildspecPath: string,
    dockerfilePath: string,
    runtimeJson: string,
    buildtimeJson: string,
    ecsTaskCpu: number,
    ecsTaskRam: number,
    servicePort: number,
    healthCheckPath: string,
    healthCheckPort: number,
    logRetentionDays: number,
    alarmsEnabled: boolean,
    cicdManualApproval: boolean,
    stickySessionEnabled: boolean,
    stickySessionType: string,
    autoscaleEnabled: boolean,
    maxInstanceCount: number
  ) => {
    setMutationInProgress(true);
    deployMyEcsWsFargateStackMutation({
      variables: {
        stackDeploymentInput: {
          id: stack.id,
          deploymentId: deployment.id,
          instanceCount,
          gitConnectionArn,
          gitRepositoryId,
          gitBranch,
          buildspecPath,
          dockerfilePath,
          runtimeJson,
          buildtimeJson,
          ecsTaskCpu,
          ecsTaskRam,
          servicePort,
          healthCheckPath,
          healthCheckPort,
          logRetentionDays,
          alarmsEnabled,
          cicdManualApproval,
          stickySessionEnabled,
          stickySessionType,
          autoscaleEnabled,
          maxInstanceCount,
        },
      },
      // update(cache, { data }) {
      //   cache.modify({
      //     fields: {
      //       getMyEcsServiceStackStatus(existingnewStackStatuses = []) {
      //         const newStackStatuskRef = cache.writeFragment({
      //           data: data?.deployMyEcsFargateStack,
      //           fragment: gql`
      //             fragment NewStackStatus on StackStatus {
      //               status
      //               parametersJSON
      //             }
      //           `,
      //         });
      //         return [...existingnewStackStatuses, newStackStatuskRef];
      //       },
      //     },
      //   });
      // },
      onCompleted: (data) => {
        getMyEcsWsServiceStackStatusRefetch();
        setMutationInProgress(false);
      },
      onError: (error) => {
        setQueryError((e) => [
          ...e,
          `Failed to deploy stack to ${deployment.id}.`,
        ]);
        console.error(error);
        setMutationInProgress(false);
      },
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        (
          getMyEcsWsServiceStackStatusData?.getMyEcsWsServiceStackStatus
            ?.status || ""
        ).indexOf("IN_PROGRESS") > -1
      ) {
        getMyEcsWsServiceStackStatusRefetch();
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [getMyEcsWsServiceStackStatusData, getMyEcsWsServiceStackStatusRefetch]);

  const [
    removeMyEcsWsFargateStack,
    { loading: removeMyEcsWsFargateStackLoading },
  ] = useRemoveMyEcsWsFargateStackMutation({
    client: bgwService.getClient(),
  });

  const removeEcsWsService = () => {
    setMutationInProgress(true);
    removeMyEcsWsFargateStack({
      variables: {
        stackId: null,
        deploymentId: deployment.id,
        pipelineId: stack.id,
      },
      onCompleted: () => {
        getMyEcsWsServiceStackStatusRefetch();
        setMutationInProgress(false);
      },
      onError: (error) => {
        setQueryError((e) => [
          ...e,
          `Failed to remove stack from ${deployment.id}.`,
        ]);
        console.error(error);
        setMutationInProgress(false);
      },
    });
  };

  const { data: whoAmIData, loading: whoAmILoading } = useWhoAmIQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });
  const amIRoot = whoAmIData?.whoAmI?.role === "ROOT" || false;

  return (
    <DeployEcsWsServiceComponent
      loading={
        getMyGitConnectionsLoading ||
        getMyEcsWsServiceStackStatusLoading ||
        deployMyEcsWsFargateStackLoading ||
        removeMyEcsWsFargateStackLoading ||
        mutationInProgress ||
        whoAmILoading
      }
      stack={stack}
      deployment={deployment}
      stackStatus={
        getMyEcsWsServiceStackStatusData?.getMyEcsWsServiceStackStatus || null
      }
      gitConnectionArns={getMyGitConnectionsData?.getMyGitConnections || []}
      queryError={queryError}
      removeQueryError={removeQueryError}
      deployEcsWsService={deployEcsWsService}
      refetchStackStatus={() => {
        getMyEcsWsServiceStackStatusRefetch();
      }}
      removeEcsWsService={removeEcsWsService}
      amIRoot={amIRoot}
    />
  );
};
