import { useContext, useEffect, useState } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import {
  FullDomainNameHostedZone,
  useCreateMyFullDomainNameHostedZoneMutation,
  useGetMyDeploymentsQuery,
  useGetMyFullDomainNameHostedZoneStatusLazyQuery,
  useGetMyFullDomainNameHostedZonesLazyQuery,
  useWhoAmIQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { DomainNamesAndCertsComponent } from "./component";
import { waffleConfig } from "../../../configs/waffle";
import { AlertType } from "../../../contexts/alerts/type";
import { AlertsContext } from "../../../contexts/alerts/context";
import { FeaturesContext } from "../../../contexts/features/context";

export const DomainNamesAndCertsContainer = (props: {
  deploymentId: string;
  navigateToNext: null | (() => void);
  navigateToPrevious: null | (() => void);
  navigateToHelp: null | (() => void);
}) => {
  const { deploymentId, navigateToNext, navigateToPrevious, navigateToHelp } =
    props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);
  const { customDomainEnabled } = useContext(FeaturesContext);
  const [changeId, setChangeId] = useState<string | null>(null);
  const [changeStatus, setChangeStatus] = useState<string | null>(null);

  const {
    data: getMyDeploymentsData,
    loading: getMyDeploymentsLoading,
    error: getMyDeploymentsError,
  } = useGetMyDeploymentsQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [
    createMyFullDomainNameHostedZone,
    { loading: createMyFullDomainNameHostedZoneLoading },
  ] = useCreateMyFullDomainNameHostedZoneMutation({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [
    getMyFullDomainNameHostedZoneStatus,
    { loading: getMyFullDomainNameHostedZoneStatusLoading },
  ] = useGetMyFullDomainNameHostedZoneStatusLazyQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [
    getMyFullDomainNameHostedZones,
    { loading: getMyFullDomainNameHostedZonesLoading },
  ] = useGetMyFullDomainNameHostedZonesLazyQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const [queryError, setQueryError] = useState<string[]>([]);

  const removeQueryError = (i: number) => {
    setQueryError((e) => {
      const newErrors = [...e];
      newErrors.splice(i, 1);
      return newErrors;
    });
  };

  const deployment = getMyDeploymentsData?.getMyDeployments?.find(
    (d) => d.id === deploymentId
  );

  const { data: whoAmIData, loading: whoAmILoading } = useWhoAmIQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });
  const selfHostedTopDomainName = `${whoAmIData?.whoAmI?.organizationId}-${deploymentId}.${waffleConfig.topDomain}`;

  const selfHostedDomain =
    selfHostedTopDomainName !== deployment?.fullDomainName;

  const createHostedZone = (fullDomainName: string) => {
    createMyFullDomainNameHostedZone({
      variables: {
        deploymentId,
        fullDomainName,
      },
      onCompleted: (data) => {
        setChangeId(data?.createMyFullDomainNameHostedZone || null);
        setChangeStatus("CREATING");
      },
      refetchQueries: ["GetMyDeployments"],
      onError: (error) => {
        setQueryError((e) => [...e, `Failed to update ${deploymentId}.`]);
        console.error(error);
      },
    });
  };

  const [hostedZones, setHostedZones] = useState<FullDomainNameHostedZone[]>(
    []
  );

  useEffect(() => {
    if (deploymentId) {
      getMyFullDomainNameHostedZones({
        variables: { deploymentId },
        onCompleted(data) {
          setHostedZones(data?.getMyFullDomainNameHostedZones || []);
        },
        onError(error) {
          setQueryError((e) => [...e, error.message]);
        },
      });
    }
  }, [deploymentId, getMyFullDomainNameHostedZones]);

  useEffect(() => {
    let intervalId = setInterval(() => {
      if (changeId) {
        getMyFullDomainNameHostedZoneStatus({
          variables: {
            deploymentId,
            changeInfoId: changeId,
          },
          onCompleted: (data) => {
            setChangeStatus(data.getMyFullDomainNameHostedZoneStatus || "NONE");
            if (data.getMyFullDomainNameHostedZoneStatus === "DONE") {
              setChangeId(null);
              getMyFullDomainNameHostedZones({
                variables: { deploymentId },
                onCompleted(data) {
                  setHostedZones(data?.getMyFullDomainNameHostedZones || []);
                },
                onError(error) {
                  setQueryError((e) => [...e, error.message]);
                },
              });
            } else if (
              data.getMyFullDomainNameHostedZoneStatus === "CREATING"
            ) {
              // pass
            } else if (data.getMyFullDomainNameHostedZoneStatus === "FAILED") {
              setChangeId(null);
              setQueryError((e) => [...e, "Failed to create hosted zone"]);
            } else {
              // pass
            }
          },
          onError: (error) => {
            setChangeId(null);
            setChangeStatus("FAILED");
            setQueryError((e) => [...e, error.message]);
          },
        });
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [
    changeId,
    deploymentId,
    getMyFullDomainNameHostedZoneStatus,
    getMyFullDomainNameHostedZones,
  ]);

  if (getMyDeploymentsError) {
    console.error(getMyDeploymentsError);
  }

  return (
    <DomainNamesAndCertsComponent
      loading={
        getMyDeploymentsLoading ||
        createMyFullDomainNameHostedZoneLoading ||
        getMyFullDomainNameHostedZoneStatusLoading ||
        getMyFullDomainNameHostedZonesLoading ||
        whoAmILoading
      }
      deployment={deployment || null}
      customDomainEnabled={customDomainEnabled}
      selfHostedDomain={selfHostedDomain}
      selfHostedTopDomainName={selfHostedTopDomainName}
      queryError={queryError}
      removeQueryError={removeQueryError}
      createHostedZone={createHostedZone}
      changeStatus={changeStatus}
      hostedZones={hostedZones || []}
      navigateToNext={navigateToNext}
      navigateToPrevious={navigateToPrevious}
      navigateToHelp={navigateToHelp}
    />
  );
};
