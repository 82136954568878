import Add from "@mui/icons-material/Add";
import Business from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import PersonIcon from "@mui/icons-material/Person";
import Security from "@mui/icons-material/Security";

import Box from "@mui/joy/Box";
import Avatar from "@mui/joy/Avatar";
import LinearProgress from "@mui/joy/LinearProgress";
import List from "@mui/joy/List";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListSubheader from "@mui/joy/ListSubheader";
import Typography from "@mui/joy/Typography";

import {
  Deployment,
  ServiceStack,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { OrganizationSelector } from "./organization_selector";
import { LogoText } from "./logo_text";
import { HEADER_HEIGHT } from "../layout/constants";
import { colors } from "../colors";
import SvgIcon from "@mui/joy/SvgIcon";

export const DrawerComponent = (props: {
  navigateToRoot: () => void;
  navigateToDashboard: (deploymentId: string) => void;
  navigateToDeploymentStatus: (newDeploymentId: string) => void;
  navigateToMyEnvironment: (deploymentId: string) => void;
  navigateToServiceStatus: (stackId: string) => void;
  navigateToAddDeployment: () => void;
  navigateToAddService: () => void;
  navigateToMyTeam: () => void;
  navigateToMyAnyStack: () => void;
  navigateToOrganizations: () => void;
  navigateToProfile: () => void;
  currentPathName: string | null;
  selectedOrganizationId: string | null;
  deploymentIdInPath: string | null;
  serviceIdInPath: string | null;
  userName: string | null;
  email: string | null;
  picture: string | null;
  role: string | null;
  loading: boolean;
  drawerWidth: number;
  workInProgressFeaturesEnabled: boolean;
  deployments: Deployment[];
  serviceStacks: ServiceStack[];
}) => {
  const {
    navigateToRoot,
    navigateToDashboard,
    navigateToDeploymentStatus,
    navigateToMyEnvironment,
    navigateToServiceStatus,
    navigateToAddDeployment,
    navigateToAddService,
    navigateToMyTeam,
    navigateToMyAnyStack,
    navigateToOrganizations,
    navigateToProfile,
    currentPathName,
    selectedOrganizationId,
    deploymentIdInPath,
    serviceIdInPath,
    userName,
    email,
    picture,
    role,
    loading,
    drawerWidth,
    workInProgressFeaturesEnabled,
    deployments,
    serviceStacks,
  } = props;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto",
        gridTemplateRows: `auto 89px ${HEADER_HEIGHT}px`,
        gap: 0,
        boxSizing: "border-box",
        minHeight: "100vh",
        maxHeight: "100vh",
        overflow: "hidden",
        backgroundColor: colors.grey2,
      }}
    >
      <Box
        sx={{
          px: 2,
          mt: 5,
          overflowX: "hidden",
          overflowY: "auto",
          minHeight: `calc(100vh - ${HEADER_HEIGHT}px - 200px)`,
          maxHeight: `calc(100vh - ${HEADER_HEIGHT}px - 200px)`,
          minWidth: `${drawerWidth}px`,
          maxWidth: `${drawerWidth}px`,
        }}
      >
        <OrganizationSelector navigateToRoot={navigateToRoot} />
        <List
          size="sm"
          sx={{
            "--List-item-radius": "8px",
            "--ListItemDecorator-size": "50px",
            "--List-nestedInsetStart": "25px",
            mt: 2,
            '& [role="button"]': {
              borderRadius: "5px",
            },
          }}
        >
          {selectedOrganizationId && (
            <>
              {deployments.length > 0 && (
                <>
                  <ListSubheader>My Dashboards</ListSubheader>
                  {deployments.map((deployment) => (
                    <ListItemButton
                      key={deployment.id}
                      selected={
                        ["dashboard_with_deployment"].includes(
                          currentPathName || ""
                        ) && deploymentIdInPath === deployment.id
                      }
                      onClick={() => navigateToDashboard(deployment.id)}
                    >
                      <ListItemDecorator>
                        <DashboardIcon />
                      </ListItemDecorator>
                      <ListItemContent>
                        {deployment.name || deployment.id} Dashboard
                      </ListItemContent>
                    </ListItemButton>
                  ))}
                </>
              )}
              <ListSubheader>My Environments</ListSubheader>
              {deployments.map((deployment) => (
                <ListItemButton
                  key={deployment.id}
                  selected={
                    ["deployment_steps_with_step"].includes(
                      currentPathName || ""
                    ) && deploymentIdInPath === deployment.id
                  }
                  onClick={() => navigateToDeploymentStatus(deployment.id)}
                >
                  <ListItemDecorator>
                    <Security />
                  </ListItemDecorator>
                  <ListItemContent>
                    {deployment.name || deployment.id}
                  </ListItemContent>
                </ListItemButton>
              ))}
              {workInProgressFeaturesEnabled &&
                deployments.map((deployment) => (
                  <ListItemButton
                    key={deployment.id}
                    selected={
                      ["my_environment_with_step"].includes(
                        currentPathName || ""
                      ) && deploymentIdInPath === deployment.id
                    }
                    onClick={() => navigateToMyEnvironment(deployment.id)}
                  >
                    <ListItemDecorator>
                      <Security />
                    </ListItemDecorator>
                    <ListItemContent>
                      {deployment.name || deployment.id}
                    </ListItemContent>
                  </ListItemButton>
                ))}
              <ListItemButton
                selected={["deployment_steps"].includes(currentPathName || "")}
                onClick={navigateToAddDeployment}
              >
                <ListItemDecorator>
                  <Add />
                </ListItemDecorator>
                <ListItemContent>Create a New Environment</ListItemContent>
              </ListItemButton>

              <ListSubheader>My Services</ListSubheader>
              {serviceStacks.map((stack) => (
                <ListItemButton
                  key={stack.id}
                  selected={
                    ["service_statuses_with_stack"].includes(
                      currentPathName || ""
                    ) && serviceIdInPath === stack.id
                  }
                  onClick={() => navigateToServiceStatus(stack.id)}
                >
                  <ListItemDecorator>
                    <SvgIcon>
                      {["ECS", "ECS_WS"].includes(stack.type) && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                          style={{ width: "70%", height: "70%" }}
                        >
                          <path d="M349.9 236.3h-66.1v-59.4h66.1v59.4zm0-204.3h-66.1v60.7h66.1V32zm78.2 144.8H362v59.4h66.1v-59.4zm-156.3-72.1h-66.1v60.1h66.1v-60.1zm78.1 0h-66.1v60.1h66.1v-60.1zm276.8 100c-14.4-9.7-47.6-13.2-73.1-8.4-3.3-24-16.7-44.9-41.1-63.7l-14-9.3-9.3 14c-18.4 27.8-23.4 73.6-3.7 103.8-8.7 4.7-25.8 11.1-48.4 10.7H2.4c-8.7 50.8 5.8 116.8 44 162.1 37.1 43.9 92.7 66.2 165.4 66.2 157.4 0 273.9-72.5 328.4-204.2 21.4 .4 67.6 .1 91.3-45.2 1.5-2.5 6.6-13.2 8.5-17.1l-13.3-8.9zm-511.1-27.9h-66v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm-78.1-72.1h-66.1v60.1h66.1v-60.1z" />
                        </svg>
                      )}
                      {stack.type === "CDN" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          style={{ width: "70%", height: "70%" }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M14.447 3.026a.75.75 0 0 1 .527.921l-4.5 16.5a.75.75 0 0 1-1.448-.394l4.5-16.5a.75.75 0 0 1 .921-.527ZM16.72 6.22a.75.75 0 0 1 1.06 0l5.25 5.25a.75.75 0 0 1 0 1.06l-5.25 5.25a.75.75 0 1 1-1.06-1.06L21.44 12l-4.72-4.72a.75.75 0 0 1 0-1.06Zm-9.44 0a.75.75 0 0 1 0 1.06L2.56 12l4.72 4.72a.75.75 0 0 1-1.06 1.06L.97 12.53a.75.75 0 0 1 0-1.06l5.25-5.25a.75.75 0 0 1 1.06 0Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                      {stack.type === "DB" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          style={{ width: "70%", height: "70%" }}
                        >
                          <path d="M21 6.375c0 2.692-4.03 4.875-9 4.875S3 9.067 3 6.375 7.03 1.5 12 1.5s9 2.183 9 4.875Z" />
                          <path d="M12 12.75c2.685 0 5.19-.586 7.078-1.609a8.283 8.283 0 0 0 1.897-1.384c.016.121.025.244.025.368C21 12.817 16.97 15 12 15s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.285 8.285 0 0 0 1.897 1.384C6.809 12.164 9.315 12.75 12 12.75Z" />
                          <path d="M12 16.5c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 0 0 1.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 0 0 1.897 1.384C6.809 15.914 9.315 16.5 12 16.5Z" />
                          <path d="M12 20.25c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 0 0 1.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 0 0 1.897 1.384C6.809 19.664 9.315 20.25 12 20.25Z" />
                        </svg>
                      )}
                      {stack.type === "CFN" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          style={{ width: "70%", height: "70%" }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M12 6.75a5.25 5.25 0 0 1 6.775-5.025.75.75 0 0 1 .313 1.248l-3.32 3.319c.063.475.276.934.641 1.299.365.365.824.578 1.3.64l3.318-3.319a.75.75 0 0 1 1.248.313 5.25 5.25 0 0 1-5.472 6.756c-1.018-.086-1.87.1-2.309.634L7.344 21.3A3.298 3.298 0 1 1 2.7 16.657l8.684-7.151c.533-.44.72-1.291.634-2.309A5.342 5.342 0 0 1 12 6.75ZM4.117 19.125a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Z"
                            clipRule="evenodd"
                          />
                          <path d="m10.076 8.64-2.201-2.2V4.874a.75.75 0 0 0-.364-.643l-3.75-2.25a.75.75 0 0 0-.916.113l-.75.75a.75.75 0 0 0-.113.916l2.25 3.75a.75.75 0 0 0 .643.364h1.564l2.062 2.062 1.575-1.297Z" />
                          <path
                            fillRule="evenodd"
                            d="m12.556 17.329 4.183 4.182a3.375 3.375 0 0 0 4.773-4.773l-3.306-3.305a6.803 6.803 0 0 1-1.53.043c-.394-.034-.682-.006-.867.042a.589.589 0 0 0-.167.063l-3.086 3.748Zm3.414-1.36a.75.75 0 0 1 1.06 0l1.875 1.876a.75.75 0 1 1-1.06 1.06L15.97 17.03a.75.75 0 0 1 0-1.06Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                    </SvgIcon>
                  </ListItemDecorator>
                  <ListItemContent>{stack.name || stack.id}</ListItemContent>
                </ListItemButton>
              ))}
              <ListItemButton
                selected={["service_statuses"].includes(currentPathName || "")}
                onClick={navigateToAddService}
              >
                <ListItemDecorator>
                  <Add />
                </ListItemDecorator>
                <ListItemContent>Create a New Service</ListItemContent>
              </ListItemButton>

              <ListSubheader>My Organization</ListSubheader>
              <ListItemButton
                selected={["my_team", "my_team_with_user"].includes(
                  currentPathName || ""
                )}
                onClick={navigateToMyTeam}
              >
                <ListItemDecorator>
                  <PersonIcon />
                </ListItemDecorator>
                <ListItemContent>My Team</ListItemContent>
              </ListItemButton>
            </>
          )}

          {role === "ROOT" && (
            <>
              {/* <ListDivider inset="gutter" sx={{ my: 2 }} /> */}
              <ListSubheader>ROOT Zone</ListSubheader>

              <ListItemButton
                selected={[
                  "organizations",
                  "organizations_with_organization",
                ].includes(currentPathName || "")}
                onClick={navigateToOrganizations}
              >
                <ListItemDecorator>
                  <Business />
                </ListItemDecorator>
                <ListItemContent>Organizations</ListItemContent>
              </ListItemButton>

              <ListItemButton
                selected={["my_any_stack"].includes(currentPathName || "")}
                onClick={navigateToMyAnyStack}
              >
                <ListItemDecorator>
                  <MiscellaneousServicesIcon />
                </ListItemDecorator>
                <ListItemContent>Any Stack in Deployment</ListItemContent>
              </ListItemButton>
            </>
          )}
        </List>
      </Box>

      <Box
        sx={{
          px: 2,
          minHeight: "89px", // 100px + 13px divider + add if more needed
          maxHeight: "89px",
          minWidth: `${drawerWidth}px`,
          maxWidth: `${drawerWidth}px`,
          overflow: "hidden",
        }}
      >
        <List
          size="sm"
          sx={{
            "--List-item-radius": "8px",
            "--ListItemDecorator-size": "50px",
            mt: 2,
            '& [role="button"]': {
              borderRadius: "5px",
            },
          }}
        >
          <ListItemButton
            selected={["profile"].includes(currentPathName || "")}
            onClick={navigateToProfile}
          >
            <ListItemDecorator sx={{ alignSelf: "flex-start" }}>
              <Avatar
                color="primary"
                size="md"
                {...(picture && { src: picture })}
              />
            </ListItemDecorator>
            <ListItemContent>
              {loading ? (
                <LinearProgress size="lg" />
              ) : (
                <>
                  <Typography level="body-sm" noWrap>
                    {userName}
                  </Typography>
                  <Typography level="body-xs" noWrap>
                    {email}
                  </Typography>
                </>
              )}
            </ListItemContent>
            <KeyboardArrowRight />
          </ListItemButton>
        </List>
      </Box>
      <Box
        sx={{
          px: 2,
          minHeight: HEADER_HEIGHT,
          maxHeight: HEADER_HEIGHT,
          minWidth: `${drawerWidth}px`,
          maxWidth: `${drawerWidth}px`,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          alignContent: "stretch",
          justifyContent: "center",
          borderTop: `1px solid ${colors.grey3}`,
          backgroundColor: colors.grey2,
        }}
      >
        <LogoText />
      </Box>
    </Box>
  );
};
