import { WelcomeComponent } from "./component";

export const WelcomeContainer = () => {
  return (
    <WelcomeComponent
      hasAnEnvironment={true}
      navigateAddEnvironment={() => {}}
      hasAService={false}
      navigateAddService={() => {}}
      hasATeamMember={false}
      navigateAddTeamMember={() => {}}
    />
  );
};
