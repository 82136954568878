import Snackbar from "@mui/joy/Snackbar";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import FormHelperText from "@mui/joy/FormHelperText";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import Switch from "@mui/joy/Switch";
import { useEffect, useState } from "react";
import { Organization } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { PageLoading } from "../../../components/page_loading";
import { RightPaneBackButton } from "../../../components/right_pane_back_button";
import { RightPaneFrame } from "../../../components/right_pane_frame";
import { RightPaneTitle } from "../../../components/right_pane_title";

export const EditOrganizationComponent = (props: {
  loading: boolean;
  organization: Organization | null;
  editOrganization: (
    id: string,
    name: string,
    workInProgressFeaturesEnabled: boolean,
    singleDeployment: boolean,
    databaseEnabled: boolean,
    apiV1Enabled: boolean,
    customDomainEnabled: boolean,
    httpApiEnabled: boolean,
    wsApiEnabled: boolean,
    onboardingComplete: boolean
  ) => void;
  editOrganizationError: string | null;
  resetEditOrganizationError: () => void;
  navigateToOrganizations: () => void;
}) => {
  const {
    loading,
    organization,
    editOrganization,
    editOrganizationError,
    resetEditOrganizationError,
    navigateToOrganizations,
  } = props;
  const [name, setName] = useState<string>("");
  const [singleDeployment, setSingleDeployment] = useState<boolean>(false);
  const [databaseEnabled, setDatabaseEnabled] = useState<boolean>(false);
  const [workInProgressFeaturesEnabled, setWorkInProgressFeaturesEnabled] =
    useState<boolean>(false);
  const [onboardingComplete, setOnboardingComplete] = useState<boolean>(false);
  const [apiV1Enabled, setApiV1Enabled] = useState<boolean>(false);
  const [customDomainEnabled, setCustomDomainEnabled] =
    useState<boolean>(false);
  const [httpApiEnabled, setHttpApiEnabled] = useState<boolean>(false);
  const [wsApiEnabled, setWsApiEnabled] = useState<boolean>(false);
  useEffect(() => {
    if (organization !== null) {
      setName(organization.name);
      setSingleDeployment(organization.features?.singleDeployment || false);
      setDatabaseEnabled(organization.features?.databaseEnabled || false);
      setWorkInProgressFeaturesEnabled(
        organization.features?.workInProgressFeaturesEnabled || false
      );
      setOnboardingComplete(organization.onboardingComplete || false);
      setApiV1Enabled(organization.features?.apiV1Enabled || false);
      setCustomDomainEnabled(
        organization.features?.customDomainEnabled || false
      );
      setHttpApiEnabled(organization.features?.httpApiEnabled || false);
      setWsApiEnabled(organization.features?.wsApiEnabled || false);
    }
  }, [organization]);
  return (
    <RightPaneFrame>
      <RightPaneBackButton
        onClick={navigateToOrganizations}
        labelText="Close"
      />
      <RightPaneTitle>Edit Organization</RightPaneTitle>
      <PageLoading loading={loading} />

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={editOrganizationError !== null}
        onClose={resetEditOrganizationError}
      >
        {editOrganizationError}
      </Snackbar>

      {organization && (
        <>
          <FormControl>
            <FormLabel>Organization ID</FormLabel>
            <Input value={organization.id} disabled />
            <FormHelperText>Readonly</FormHelperText>
          </FormControl>

          <FormControl>
            <FormLabel>Organization Name</FormLabel>
            <Input
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <FormHelperText>Human readable name</FormHelperText>
          </FormControl>

          <Typography level="body-lg" sx={{ pb: 1 }}>
            Features
          </Typography>

          <FormControl>
            <FormLabel>Work in Progress Features Enabled</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={workInProgressFeaturesEnabled}
                  onChange={(event) =>
                    setWorkInProgressFeaturesEnabled(event.target.checked)
                  }
                />
              }
            >
              {workInProgressFeaturesEnabled ? "Enabled" : "Disabled"}
            </Typography>
          </FormControl>

          <FormControl>
            <FormLabel>Single Deployment</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={singleDeployment}
                  onChange={(event) =>
                    setSingleDeployment(event.target.checked)
                  }
                />
              }
            >
              {singleDeployment ? "Enabled" : "Disabled"}
            </Typography>
            <FormHelperText>
              Enables deploying into a single environment
            </FormHelperText>
          </FormControl>

          <FormControl>
            <FormLabel>Database Enabled</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={databaseEnabled}
                  onChange={(event) => setDatabaseEnabled(event.target.checked)}
                />
              }
            >
              {databaseEnabled ? "Enabled" : "Disabled"}
            </Typography>
            <FormHelperText>Enables deploying databases</FormHelperText>
          </FormControl>

          <FormControl>
            <FormLabel>API V1 Enabled</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={apiV1Enabled}
                  onChange={(event) => setApiV1Enabled(event.target.checked)}
                />
              }
            >
              {apiV1Enabled ? "Enabled" : "Disabled"}
            </Typography>
          </FormControl>

          <FormControl>
            <FormLabel>Custom Domain Enabled</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={customDomainEnabled}
                  onChange={(event) =>
                    setCustomDomainEnabled(event.target.checked)
                  }
                />
              }
            >
              {customDomainEnabled ? "Enabled" : "Disabled"}
            </Typography>
          </FormControl>

          <FormControl>
            <FormLabel>HTTP API Enabled</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={httpApiEnabled}
                  onChange={(event) => setHttpApiEnabled(event.target.checked)}
                />
              }
            >
              {httpApiEnabled ? "Enabled" : "Disabled"}
            </Typography>
          </FormControl>

          <FormControl>
            <FormLabel>WS API Enabled</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={wsApiEnabled}
                  onChange={(event) => setWsApiEnabled(event.target.checked)}
                />
              }
            >
              {wsApiEnabled ? "Enabled" : "Disabled"}
            </Typography>
          </FormControl>

          <FormControl>
            <FormLabel>Onboarding complete</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  checked={onboardingComplete}
                  onChange={(event) =>
                    setOnboardingComplete(event.target.checked)
                  }
                />
              }
            >
              {onboardingComplete ? "Complete" : "Not done yet"}
            </Typography>
            <FormHelperText>
              If not complete then the onboarding screen shows up
            </FormHelperText>
          </FormControl>
          <Button
            onClick={() =>
              editOrganization(
                organization.id,
                name,
                workInProgressFeaturesEnabled,
                singleDeployment,
                databaseEnabled,
                apiV1Enabled,
                customDomainEnabled,
                httpApiEnabled,
                wsApiEnabled,
                onboardingComplete
              )
            }
          >
            Update
          </Button>
        </>
      )}
    </RightPaneFrame>
  );
};
