import Markdown, { ExtraProps } from "react-markdown";
import { RightPaneBackButton } from "../../../../components/right_pane_back_button";
import { RightPaneFrame } from "../../../../components/right_pane_frame";

const markdown = `
# Domain Names and Certificates

Waffle's concept is that each environment has its own domain name. Different subdomains are automatically created for the domain: for example HTTP endpoints are created as \`api.my_domain\` by default.

You can add additional domain names to specific services, for example if you want to add a www.my-different-domain.com domain to a frontend web application, that's possible.

In this step of the deployment, either Waffle generates a domain name for you or you can set up your own domain name and Waffle will use that. The domain name has to be created in your AWS account.

## Waffle Generated Domain Name

It's the simplest option. Waffle will generate a domain name for you: \`my_organization-my_environment.app.wafflecode.com\`.

Waffle also automatically creates a certificate for this domain name.

## Custom Domain Name

You can create your own domain name in your AWS account. The typical way to do this is if you have a domain name - for example \`my_domain.com\` - registered with a domain registrar like GoDaddy or Google Domains.

`;

const ImageRenderer = (
  props: React.ClassAttributes<HTMLImageElement> &
    React.ImgHTMLAttributes<HTMLImageElement> &
    ExtraProps
) => <img src={props.src} alt={props.alt} style={{ maxWidth: "100%" }} />;

export const DomainNamesAndCertsHelpPane = (props: {
  navigateBack: () => void;
}) => {
  const { navigateBack } = props;
  return (
    <RightPaneFrame>
      <RightPaneBackButton onClick={navigateBack} labelText="Close" />
      <Markdown components={{ img: ImageRenderer }}>{markdown}</Markdown>
    </RightPaneFrame>
  );
};
