import { useNavigate } from "react-router-dom";
import { MyEnvironmentComponent } from "./component";
import { MY_ENVIRONMENT_STEPS } from "./steps";
import { StepperComponent } from "./stepper";
import { useTestMyWaffleRoleQuery } from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { useContext } from "react";
import { BgwContext } from "../../contexts/backend_gateway/context";
import { AlertsContext } from "../../contexts/alerts/context";
import { AlertType } from "../../contexts/alerts/type";

export const MyEnvironmentContainer = (props: {
  stepId: string;
  deploymentId: string;
}) => {
  const { stepId, deploymentId } = props;
  const stepDef = MY_ENVIRONMENT_STEPS.find((s) => s.id === stepId);
  const stepIx = MY_ENVIRONMENT_STEPS.findIndex((s) => s.id === stepId);

  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const { data: testMyWaffleRoleData, loading: testMyWaffleRoleLoading } =
    useTestMyWaffleRoleQuery({
      client: bgwService.getClient(),
      variables: {
        deploymentId,
      },
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
    });

  const navigate = useNavigate();
  let navigateToPrevious: (() => void) | null = null;
  if (stepIx > 0) {
    navigateToPrevious = () =>
      navigate(
        `/my_environment/${deploymentId}/${
          MY_ENVIRONMENT_STEPS[stepIx - 1].id
        }`,
        {
          replace: true,
        }
      );
  }
  let navigateToNext: (() => void) | null = null;
  if (stepIx < MY_ENVIRONMENT_STEPS.length - 1) {
    navigateToNext = () =>
      navigate(
        `/my_environment/${deploymentId}/${
          MY_ENVIRONMENT_STEPS[stepIx + 1].id
        }`,
        {
          replace: true,
        }
      );
  }
  const naviateToStep = (stepId: string) => {
    navigate(`/my_environment/${deploymentId}/${stepId}`, {
      replace: true,
    });
  };
  const navigateToHelp = () => {
    navigate(`/my_environment/${deploymentId}/${stepId}/help`, {
      replace: true,
    });
  };

  const StepComponent = stepDef!.component;
  return (
    <MyEnvironmentComponent
      deploymentId={deploymentId}
      loading={testMyWaffleRoleLoading}
      stepComponent={
        <StepComponent
          deploymentId={deploymentId}
          navigateToNext={navigateToNext}
          navigateToPrevious={navigateToPrevious}
          navigateWhenFinished={() => {
            navigate(`/deployment_steps/${deploymentId}`, {
              replace: true,
            });
          }}
          navigateToHelp={navigateToHelp}
        />
      }
      stepperComponent={
        <StepperComponent stepId={stepId} navigateToStep={naviateToStep} />
      }
    />
  );
};
