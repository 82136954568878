import { useContext, useState } from "react";
import { EditOrganizationComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import {
  useEditOrganizationMutation,
  useGetOrganizationQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";

export const EditOrganizationContainer = (props: {
  organizationId: string;
}) => {
  const { organizationId } = props;
  const { bgwService } = useContext(BgwContext);

  const {
    data: getOrganizationData,
    loading: getOrganizationLoading,
    error: getOrganizationError,
  } = useGetOrganizationQuery({
    client: bgwService.getClient(),
    variables: {
      organizationId,
    },
  });

  const [
    editOrganizationMutation,
    {
      loading: editOrganizationMutationLoading,
      error: editOrganizationMutationError,
    },
  ] = useEditOrganizationMutation({
    client: bgwService.getClient(),
  });

  const [editOrganizationError, setEditOrganizationError] = useState<
    string | null
  >(null);

  const resetEditOrganizationError = () => {
    setEditOrganizationError(null);
  };

  const editOrganization = (
    id: string,
    name: string,
    workInProgressFeaturesEnabled: boolean,
    singleDeployment: boolean,
    databaseEnabled: boolean,
    apiV1Enabled: boolean,
    customDomainEnabled: boolean,
    httpApiEnabled: boolean,
    wsApiEnabled: boolean,
    onboardingComplete: boolean
  ) => {
    editOrganizationMutation({
      variables: {
        organizationInput: {
          id,
          name,
          workInProgressFeaturesEnabled,
          singleDeployment,
          databaseEnabled,
          apiV1Enabled,
          customDomainEnabled,
          httpApiEnabled,
          wsApiEnabled,
          onboardingComplete,
        },
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            getAllOrganizations(existingOrganizations = []) {
              const updatedOrganizationRef = cache.writeFragment({
                data: data?.editOrganization,
                fragment: gql`
                  fragment UpdatedOrganization on Organization {
                    id
                    name
                    onboardingComplete
                  }
                `,
              });
              return existingOrganizations.map((o: { id: string }) =>
                o.id === id ? updatedOrganizationRef : o
              );
            },
          },
        });
      },
      refetchQueries: ["GetAllOrganizations"],
      onError: (error) => {
        setEditOrganizationError(`Failed to update ${organizationId}.`);
        console.error(error);
      },
    });
  };

  const navigate = useNavigate();

  const navigateToOrganizations = () => {
    navigate(`/organizations`, { replace: true });
  };

  if (getOrganizationError) {
    console.error(getOrganizationError);
  }
  if (editOrganizationMutationError) {
    console.error(editOrganizationMutationError);
  }
  if (editOrganizationError) {
    console.error(editOrganizationError);
  }

  return (
    <EditOrganizationComponent
      loading={getOrganizationLoading || editOrganizationMutationLoading}
      organization={getOrganizationData?.getOrganization || null}
      editOrganization={editOrganization}
      editOrganizationError={editOrganizationError}
      resetEditOrganizationError={resetEditOrganizationError}
      navigateToOrganizations={navigateToOrganizations}
    />
  );
};
