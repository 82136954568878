import { useNavigate, useParams } from "react-router-dom";
import { MY_ENVIRONMENT_STEPS } from "./steps";

export const MyEnvironmentHelpPane = (props: {}) => {
  const params = useParams();
  const navigate = useNavigate();
  const { deploymentId, stepId } = params;
  if (deploymentId === undefined) return null;
  const stepDef = MY_ENVIRONMENT_STEPS.find((s) => s.id === stepId);
  if (stepDef === undefined) return null;
  const HelpComponent = stepDef!.helpComponent;
  if (HelpComponent === undefined) return null;
  const navigateBack = () => {
    navigate(`/my_environment/${deploymentId}/${stepId}`);
  };

  return <HelpComponent navigateBack={navigateBack} />;
};
