import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DownloadIcon from "@mui/icons-material/Download";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ReportIcon from "@mui/icons-material/Report";
import Chip from "@mui/joy/Chip";
import Button from "@mui/joy/Button";
import Grid from "@mui/joy/Grid";
import Link from "@mui/joy/Link";
import Snackbar from "@mui/joy/Snackbar";
import Typography from "@mui/joy/Typography";
import { PageLoading } from "../../../components/page_loading";
import IconButton from "@mui/joy/IconButton";

export const AwsConnectionComponent = (props: {
  loading: boolean;
  error: string | null;
  resetError: () => void;
  downloadCfnJsonBlob: () => void;
  connectionWorks: boolean | null;
  connectionDetails: string | null;
  checkConnection: () => void;
  done: boolean;
  navigateToNext: (() => void) | null;
  navigateToPrevious: (() => void) | null;
  navigateToHelp: null | (() => void);
}) => {
  const {
    loading,
    error,
    resetError,
    downloadCfnJsonBlob,
    connectionWorks,
    connectionDetails,
    checkConnection,
    done,
    navigateToNext,
    navigateToPrevious,
    navigateToHelp,
  } = props;
  return (
    <>
      <PageLoading loading={loading} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={error !== null}
        onClose={resetError}
      >
        {error}
      </Snackbar>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography
            level="h2"
            sx={{ pb: 2 }}
            endDecorator={
              navigateToHelp && (
                <IconButton onClick={navigateToHelp}>
                  <HelpOutlineIcon />
                </IconButton>
              )
            }
          >
            AWS Connection
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            This step is about connecting your AWS account to Waffle. This is
            done by creating an IAM role in your AWS account that Waffle can
            assume to create environments and stacks for you. Using
            cross-account IAM Roles is AWS's preferred method for cross-account
            interactions, providing a secure and reliable connection.
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            This role grants the necessary permissions to Waffle to create and
            update resources in your AWS account. This role's trust relationship
            also specifies that it only can be assumed by Waffle.
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            Below you're asked to download a CloudFormation template that has a
            cross-account role definition, and deploy it to your AWS account.
          </Typography>
          <Typography level="h3" sx={{ py: 2 }}>
            Step 1: Download the CloudFormation Template
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            The template that you're asked to download is a JSON file that has
            instructions for AWS CloudFormation, AWS's infastructure-as-code
            solution.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Button
            size="lg"
            startDecorator={<DownloadIcon />}
            onClick={downloadCfnJsonBlob}
            disabled={loading}
          >
            Download template
          </Button>
        </Grid>
        <Grid xs={12}>
          <Typography level="h3" sx={{ py: 2 }}>
            Step 2: Deploy the CloudFormation Template
          </Typography>
          <Typography level="body-sm">
            Go to{" "}
            <Link href="https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create">
              CloudFormation in the AWS Console
            </Link>{" "}
            and create a stack with the template you downloaded.
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Typography level="h3" sx={{ py: 2 }}>
            Step 3: Test the Connection
          </Typography>
        </Grid>
        {connectionWorks !== true && (
          <Grid xs={12}>
            <Typography level="body-md" sx={{ py: 1 }}>
              By clicking the button below, Waffle will try to connect to your
              AWS account, to confirm that the deployment of the IAM role has
              been successful.
            </Typography>
            <Button size="lg" disabled={loading} onClick={checkConnection}>
              Check connection
            </Button>
          </Grid>
        )}
        <Grid xs={12}>
          {connectionWorks === true && (
            <Chip
              variant="solid"
              color="success"
              size="lg"
              startDecorator={<CheckCircleIcon />}
            >
              Connection works
            </Chip>
          )}
          {connectionWorks === false && (
            <Chip variant="soft" color="danger" startDecorator={<ReportIcon />}>
              Connection failed
            </Chip>
          )}
          {connectionDetails && (
            <Typography level="body-md" color="danger" sx={{ py: 1 }}>
              {connectionDetails}
            </Typography>
          )}
        </Grid>
        <Grid xs={12} sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {navigateToPrevious !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToPrevious()}
              startDecorator={<ChevronLeftIcon />}
            >
              Back
            </Button>
          )}

          {navigateToNext !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToNext()}
              disabled={!done}
              endDecorator={<ChevronRightIcon />}
            >
              Next
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
