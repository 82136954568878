// import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
// import Grid from "@mui/joy/Grid";
// import Step from "@mui/joy/Step";
// import StepButton from "@mui/joy/StepButton";
// import StepIndicator from "@mui/joy/StepIndicator";
// import Stepper from "@mui/joy/Stepper";
// import Typography from "@mui/joy/Typography";
// import { PageFrame } from "../../components/page_frame";
// import { PageTitle } from "../../components/page_title";

export const WelcomeComponent = (props: {
  hasAnEnvironment: boolean;
  navigateAddEnvironment: () => void;
  hasAService: boolean;
  navigateAddService: () => void;
  hasATeamMember: boolean;
  navigateAddTeamMember: () => void;
}) => {
  return null;
  // const {
  //   hasAnEnvironment,
  //   navigateAddEnvironment,
  //   hasAService,
  //   navigateAddService,
  //   hasATeamMember,
  //   navigateAddTeamMember,
  // } = props;

  // return (
  //   <PageFrame>
  //     <PageTitle>Welcome</PageTitle>
  //     <Typography sx={{ mt: 4 }}>
  //       To get started, you need to create an environment and deploy a service
  //       into it. You can also invite a team member.
  //     </Typography>
  //     <Grid container spacing={2} sx={{ flexGrow: 1, width: "100%", mt: 4 }}>
  //       <Grid xs={12} sm={6}>
  //         <Stepper orientation="vertical">
  //           <Step
  //             completed={hasAnEnvironment}
  //             indicator={
  //               hasAnEnvironment ? (
  //                 <StepIndicator variant="solid" color="success">
  //                   <CheckRoundedIcon />
  //                 </StepIndicator>
  //               ) : (
  //                 <StepIndicator>1</StepIndicator>
  //               )
  //             }
  //           >
  //             <StepButton
  //               onClick={() => navigateAddEnvironment()}
  //               sx={{
  //                 textDecoration: hasAnEnvironment ? "line-through" : "none",
  //               }}
  //             >
  //               Create an Environment
  //             </StepButton>
  //           </Step>
  //           <Step
  //             completed={hasAService}
  //             disabled={!hasAnEnvironment}
  //             indicator={
  //               hasAService ? (
  //                 <StepIndicator variant="solid" color="success">
  //                   <CheckRoundedIcon />
  //                 </StepIndicator>
  //               ) : (
  //                 <StepIndicator>2</StepIndicator>
  //               )
  //             }
  //           >
  //             <StepButton
  //               onClick={() => navigateAddService()}
  //               sx={{ textDecoration: hasAService ? "line-through" : "none" }}
  //             >
  //               Deploy a Service into the Environment
  //             </StepButton>
  //           </Step>
  //           <Step
  //             completed={hasATeamMember}
  //             indicator={
  //               hasATeamMember ? (
  //                 <StepIndicator variant="solid" color="success">
  //                   <CheckRoundedIcon />
  //                 </StepIndicator>
  //               ) : (
  //                 <StepIndicator>3</StepIndicator>
  //               )
  //             }
  //           >
  //             <StepButton
  //               onClick={() => navigateAddTeamMember()}
  //               sx={{
  //                 textDecoration: hasATeamMember ? "line-through" : "none",
  //               }}
  //             >
  //               Invite a Team Member
  //             </StepButton>
  //           </Step>
  //         </Stepper>
  //       </Grid>
  //     </Grid>
  //   </PageFrame>
  // );
};
