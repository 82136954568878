import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Page } from "../../layouts/page";
import { PageWithRightPane } from "../../layouts/page_with_right_pane";
import { MY_ENVIRONMENT_STEPS } from "./steps";
import { SummaryContainer } from "./summary/container";
import { MyEnvironmentContainer } from "./container";

export const MyEnvironmentPage = (props: {}) => {
  const params = useParams();
  const { deploymentId, stepId } = params;

  const navigate = useNavigate();
  const validStep = MY_ENVIRONMENT_STEPS.map((s) => s.id).includes(
    stepId || ""
  );
  useEffect(() => {
    if (deploymentId && !validStep) {
      navigate(`/my_environment/${deploymentId}/aws_connection`, {
        replace: true,
      });
    }
  }, [validStep, deploymentId, navigate]);

  if (deploymentId === undefined) {
    return null;
  }
  if (!validStep) {
    return (
      <Page>
        <SummaryContainer />
      </Page>
    );
  }

  return (
    <PageWithRightPane>
      <MyEnvironmentContainer stepId={stepId!} deploymentId={deploymentId!} />
    </PageWithRightPane>
  );
};
