import { useContext, useEffect, useMemo, useState } from "react";
import { BgwContext } from "../../contexts/backend_gateway/context";
import { ServiceStatusesComponent } from "./component";
import {
  useGetMyDeploymentsQuery,
  useGetMyServiceStacksQuery,
} from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { FeaturesContext } from "../../contexts/features/context";

export const ServiceStatusesContainer = (props: { stackId: string }) => {
  const { stackId } = props;

  const [queryError, setQueryError] = useState<string[]>([]);

  const removeQueryError = (i: number) => {
    setQueryError((e) => {
      const newErrors = [...e];
      newErrors.splice(i, 1);
      return newErrors;
    });
  };

  const { bgwService } = useContext(BgwContext);
  const { wsApiEnabled } = useContext(FeaturesContext);
  const {
    data: getMyDeploymentsData,
    loading: getMyDeploymentsLoading,
    error: getMyDeploymentsError,
  } = useGetMyDeploymentsQuery({
    client: bgwService.getClient(),
  });

  useEffect(() => {
    if ((getMyDeploymentsError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyDeploymentsError!.message]);
    }
  }, [getMyDeploymentsError]);

  const {
    data: getMyServiceStacksData,
    loading: getMyServiceStacksLoading,
    error: getMyServiceStacksError,
  } = useGetMyServiceStacksQuery({
    client: bgwService.getClient(),
  });

  useEffect(() => {
    if ((getMyServiceStacksError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyServiceStacksError!.message]);
    }
  }, [getMyServiceStacksError]);

  const stack = useMemo(
    () =>
      getMyServiceStacksData?.getMyServiceStacks?.find((s) => s.id === stackId),
    [getMyServiceStacksData, stackId]
  );

  return (
    <ServiceStatusesComponent
      loading={getMyDeploymentsLoading || getMyServiceStacksLoading}
      stackId={stackId}
      stack={stack || null}
      deployments={getMyDeploymentsData?.getMyDeployments || []}
      queryError={queryError}
      removeQueryError={removeQueryError}
      wsApiEnabled={wsApiEnabled}
    />
  );
};
