import Markdown, { ExtraProps } from "react-markdown";
import { RightPaneBackButton } from "../../../../components/right_pane_back_button";
import { RightPaneFrame } from "../../../../components/right_pane_frame";
import gotoCfn from "./1-goto-cfn.png";
import clickCreateStack from "./2-click_create_stack.png";
import createStack from "./3-create-stack.png";
import stackDetails from "./4-stack-details.png";
import acknowledge from "./5-acknowledge-iam.png";
import viewProgress from "./6-view-progress.png";

const markdown = `
# Waffle - AWS Connection

Waffle sets up your environment in your AWS account.

It's a common pattern to have multiple environments for different purposes in the software development lifecycle.

For example, you might have a \`staging\` environment for testing and development, and a \`production\` environment for your live site.

Waffle supports this pattern by allowing you to create multiple environments. Although these environments can live in the same AWS account, it's much easier to control access permissions if you have separate AWS accounts for each environment.

There are different ways to do this. The easiest is to use the AWS Organizations feature. To have a complex, multi-account, opinionated setup that doesn't only isolate your environments, but it also isolates logs and infrastrucutre related services from workloads, you can use the AWS Control Tower feature.

## Working with a single AWS account

This is the simplest setup. However it's not recommended as both Waffle and any users will have access to a lot of things in the account, unless you take steps to limit access on your own. Although Waffle doesn't access anything that's not relevant for it, it's a good practice to follow the least privilege approach, only giving necessary access.

If you decide to use a single AWS account, it's still highly recommended to create separate IAM users instead of using the root user.

## Creating AWS Sub-Accounts with AWS Organizations

AWS Organizations enables you to create a hierarchy of AWS accounts for free. IAM users of your root account can switch roles to access the sub-accounts.

Waffle's recommendation is to create a separate AWS account for each environment. And to create IAM users either in the root account or in the AWS Identity Center. In theory, this can be set up in a couple of minutes.

## Creating AWS Sub-Accounts with AWS Control Tower

AWS Control Tower allows you to create a hierarchy of AWS accounts based on blueprints with detailed configurations and permission presets. It's a significantly more complex setup than AWS Organizations, suitable for larger organizations and post-MVP projects.

The Control Tower can be used together with the AWS Identity Center to manage users and permissions. Setting this up likely takes a couple of hours.

## The Waffle Role

Waffle creates a dedicated IAM role in your AWS account. This role is used by Waffle to manage the resources in your account.

Once you are done with setting up your AWS account hierarchy, you need to get the account number of the AWS account that you want to deploy the environment to. Enter this account number in the input on the Waffle UI. This will generate an infrastructure as code specificaly for this account to generate the Waffle Role.

Download the template to your local machine.

Open the CloudFormation service in the AWS console with account and the region that you want to deploy the environment to.

![Open CloudFormation](${gotoCfn})

Click on "Create stack".

![Click Create stack](${clickCreateStack})

Upload the template file that you downloaded.

![Upload template](${createStack})

Give the stack a name and click "Next". Please don't edit the WaffleRoleArn parameter, it's provided by Waffle.

![Give stack a name](${stackDetails})

Check the Acknowledge checkbox and then click "Submit".

![Acknowledge](${acknowledge})

You can view the progress of the stack in the CloudFormation console.

![Create stack](${viewProgress})

Once the stack is created, you can verify it in the Waffle UI if the connection is successful.
`;

const ImageRenderer = (
  props: React.ClassAttributes<HTMLImageElement> &
    React.ImgHTMLAttributes<HTMLImageElement> &
    ExtraProps
) => <img src={props.src} alt={props.alt} style={{ maxWidth: "100%" }} />;

export const AwsConnectionHelpPane = (props: { navigateBack: () => void }) => {
  const { navigateBack } = props;
  return (
    <RightPaneFrame>
      <RightPaneBackButton onClick={navigateBack} labelText="Close" />
      <Markdown components={{ img: ImageRenderer }}>{markdown}</Markdown>
    </RightPaneFrame>
  );
};
