import Step from "@mui/joy/Step";
import StepIndicator from "@mui/joy/StepIndicator";
import Stepper from "@mui/joy/Stepper";
import { MY_ENVIRONMENT_STEPS } from "./steps";
import StepButton from "@mui/joy/StepButton";

export const StepperComponent = (props: {
  stepId: string;
  navigateToStep: (stepId: string) => void;
}) => {
  const { stepId, navigateToStep } = props;
  return (
    <Stepper orientation="vertical" sx={{ pt: 1 }}>
      {MY_ENVIRONMENT_STEPS.map((step, i) => (
        <Step
          key={step.id}
          orientation="vertical"
          indicator={
            <StepIndicator
              variant={stepId === step.id ? "solid" : "outlined"}
              color="neutral"
            >
              {i + 1}
            </StepIndicator>
          }
        >
          <StepButton onClick={() => navigateToStep(step.id)}>
            {step.title}
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};
