import {
  Deployment,
  ServiceStack,
  StackStatus,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { PageLoading } from "../../../components/page_loading";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Alert from "@mui/joy/Alert";
import Button from "@mui/joy/Button";
import Grid from "@mui/joy/Grid";
import CircularProgress from "@mui/joy/CircularProgress";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Switch from "@mui/joy/Switch";
import Input from "@mui/joy/Input";
import FormHelperText from "@mui/joy/FormHelperText";
import { useRef, useState } from "react";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/joy/DialogActions";

export const DeployDbServiceComponent = (props: {
  loading: boolean;
  stack: ServiceStack;
  deployment: Deployment | null;
  stackStatus: StackStatus | null;
  queryError: string[];
  removeQueryError: (i: number) => void;
  deployDbService: () => void;
  refetchStackStatus: () => void;
  availableSnapshotIds: string[];
  availableFamilies: string[];
  availableEngineVersions: string[];
  availableInstanceClasses: string[];
  dbType: string;
  setDbType: (dbType: string) => void;
  family: string;
  setFamily: (family: string) => void;
  engineVersion: string;
  setEngineVersion: (engineVersion: string) => void;
  instanceClass: string;
  setInstanceClass: (instanceClass: string) => void;
  snapshotId: string;
  setSnapshotId: (snapshotId: string) => void;
  multiAz: boolean;
  setMultiAz: (multiAz: boolean) => void;
  alarmsEnabled: boolean;
  setAlarmsEnabled: (alarmsEnabled: boolean) => void;
  allocatedStorage: number;
  setAllocatedStorage: (allocatedStorage: number) => void;
  backupRetentionPeriod: number;
  setBackupRetentionPeriod: (backupRetentionPeriod: number) => void;
  logRetentionDays: number;
  setLogRetentionDays: (logRetentionDays: number) => void;
  createReplica: boolean;
  setCreateReplica: (createReplica: boolean) => void;
  removeDbService: () => void;
  amIRoot: boolean;
}) => {
  const {
    loading,
    stack,
    deployment,
    stackStatus,
    queryError,
    removeQueryError,
    deployDbService,
    refetchStackStatus,
    availableSnapshotIds,
    availableFamilies,
    availableEngineVersions,
    availableInstanceClasses,
    dbType,
    setDbType,
    family,
    setFamily,
    engineVersion,
    setEngineVersion,
    instanceClass,
    setInstanceClass,
    snapshotId,
    setSnapshotId,
    multiAz,
    setMultiAz,
    alarmsEnabled,
    setAlarmsEnabled,
    allocatedStorage,
    setAllocatedStorage,
    backupRetentionPeriod,
    setBackupRetentionPeriod,
    logRetentionDays,
    setLogRetentionDays,
    createReplica,
    setCreateReplica,
    removeDbService,
    amIRoot,
  } = props;

  const backupRetentionPeriodRef = useRef<HTMLInputElement>(null);
  const allocatedStorageRef = useRef<HTMLInputElement>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <PageLoading loading={loading} />
      {queryError.map((e, i) => (
        <Alert
          key={i}
          color="danger"
          variant="soft"
          endDecorator={
            <Button
              color="danger"
              variant="soft"
              onClick={() => removeQueryError(i)}
            >
              DISMISS
            </Button>
          }
          sx={{ mb: 2 }}
        >
          Error: {e}
        </Alert>
      ))}
      <Grid
        container
        spacing={2}
        sx={{ alignItems: "flex-start", flexGrow: 1 }}
      >
        {!loading && (stackStatus?.status || null) === null && (
          <Grid xs={12}>
            <Alert color="neutral" variant="soft" sx={{ mb: 2 }}>
              The database has not been deployed to this environment yet.
            </Alert>
          </Grid>
        )}
        {!loading &&
          stackStatus?.status !== null &&
          stackStatus?.status !== undefined && (
            <Grid xs={12}>
              <Alert
                color={
                  stackStatus?.status === "CREATE_COMPLETE" ||
                  stackStatus?.status === "UPDATE_COMPLETE"
                    ? "success"
                    : "neutral"
                }
                variant="soft"
                sx={{ mb: 2 }}
                startDecorator={
                  (stackStatus.status || "").indexOf("IN_PROGRESS") > -1 ? (
                    <CircularProgress
                      size="sm"
                      color={
                        stackStatus?.status === "CREATE_COMPLETE" ||
                        stackStatus?.status === "UPDATE_COMPLETE"
                          ? "success"
                          : "neutral"
                      }
                    />
                  ) : null
                }
                endDecorator={
                  (stackStatus.status || "").indexOf("IN_PROGRESS") === -1 ? (
                    <Button
                      size="sm"
                      variant="soft"
                      color={
                        stackStatus?.status === "CREATE_COMPLETE" ||
                        stackStatus?.status === "UPDATE_COMPLETE"
                          ? "success"
                          : "neutral"
                      }
                      onClick={() => {
                        refetchStackStatus();
                      }}
                    >
                      REFRESH
                    </Button>
                  ) : null
                }
              >
                Deployment status: {stackStatus.status}
                <Link
                  href={`https://${deployment?.awsRegion}.console.aws.amazon.com/cloudformation/home?region=${deployment?.awsRegion}#/stacks/stackinfo?filteringText=&filteringStatus=active&viewNested=true&stackId=waffle-${deployment?.id}-${stack?.id}-db`}
                >
                  Details
                </Link>
              </Alert>
            </Grid>
          )}
        <Grid xs={12} sm={12}>
          <Typography level="title-lg">Database details</Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Database type</FormLabel>
            <Select
              placeholder="Select database type"
              value={dbType}
              onChange={(event, newValue) => {
                setDbType(newValue || "");
              }}
            >
              <Option key="RDS" value="RDS">
                RDS
              </Option>
              <Option key="AURORA" value="AURORA">
                Aurora
              </Option>
            </Select>
            <FormHelperText>
              The type of database to deploy. Aurora is a managed database
              engine that combines the speed and availability of high-end
              commercial databases with the simplicity and cost-effectiveness of
              open source databases.
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Database family</FormLabel>
            <Select
              disabled={loading}
              placeholder="Select database family"
              value={family}
              onChange={(event, newValue) => {
                setFamily(newValue || "");
              }}
            >
              {availableFamilies.map((f) => (
                <Option key={f} value={f}>
                  {f}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Engine version</FormLabel>
            <Select
              disabled={loading}
              placeholder="Select engine version"
              value={engineVersion}
              onChange={(event, newValue) => {
                setEngineVersion(newValue || "");
              }}
            >
              {availableEngineVersions.map((e) => (
                <Option key={e} value={e}>
                  {e}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Multi-AZ</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  disabled={loading}
                  checked={multiAz}
                  onChange={(event) => {
                    setMultiAz(event.target.checked);
                  }}
                />
              }
            >
              {multiAz ? "Enabled" : "Disabled"}
            </Typography>
            <FormHelperText>
              If enabled, the database will be deployed in a multi availability
              zone configuration.
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Create replica</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  disabled={loading}
                  checked={createReplica}
                  onChange={(event) => setCreateReplica(event.target.checked)}
                />
              }
            >
              {createReplica ? "Enabled" : "Disabled"}
            </Typography>
            <FormHelperText>
              If enabled, a read-only replica will be created.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={12}>
          <Typography level="title-lg">Database capacity</Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Instance class</FormLabel>
            <Select
              placeholder="Select instance class"
              disabled={loading || availableInstanceClasses.length === 0}
              value={instanceClass}
              onChange={(event, newValue) => {
                setInstanceClass(newValue || "");
              }}
            >
              {availableInstanceClasses.map((i) => (
                <Option key={i} value={i}>
                  {i}
                </Option>
              ))}
            </Select>
            <FormHelperText>
              The instance class to use for the database. It determines the
              amount of CPU and memory available.
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Allocated storage</FormLabel>
            <Input
              disabled={loading}
              value={allocatedStorage}
              type="number"
              slotProps={{
                input: {
                  ref: allocatedStorageRef,
                  min: 5,
                  max: 1000,
                  step: 1,
                },
              }}
              endDecorator={<Typography fontSize="xs">GB</Typography>}
              onChange={(event) => {
                setAllocatedStorage(parseInt(event.target.value));
              }}
            />
          </FormControl>
          <FormHelperText>
            The amount of storage to allocate for the database.
          </FormHelperText>
        </Grid>
        <Grid xs={12} sm={12}>
          <Typography level="title-lg">
            Restoring a database from a snapshot
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Snapshot ID (optional)</FormLabel>
            <Select
              disabled={
                loading ||
                (stackStatus?.status !== null &&
                  stackStatus?.status !== undefined) ||
                availableSnapshotIds.length === 0
              }
              placeholder="Select snapshot"
              value={snapshotId}
              onChange={(event, newValue) => {
                setSnapshotId(newValue || "");
              }}
            >
              {availableSnapshotIds.map((s) => (
                <Option key={s} value={s}>
                  {s}
                </Option>
              ))}
            </Select>
            <FormHelperText>
              When creating a new database, if provided, the database will be
              restored from the snapshot. Only available if you have a snapshot
              in the same region.
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12}>
          <Typography level="title-lg">Security and compliance</Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Backup retention period</FormLabel>
            <Input
              disabled={loading}
              value={backupRetentionPeriod}
              type="number"
              endDecorator={<Typography fontSize="xs">days</Typography>}
              slotProps={{
                input: {
                  ref: backupRetentionPeriodRef,
                  min: 0,
                  max: 35,
                  step: 1,
                },
              }}
              onChange={(event) => {
                setBackupRetentionPeriod(parseInt(event.target.value));
              }}
            />
          </FormControl>
          <FormHelperText>0 to turn backups off</FormHelperText>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Performance insights retention</FormLabel>
            <Select
              disabled={loading}
              value={logRetentionDays}
              onChange={(event, newValue) => {
                setLogRetentionDays(newValue || 0);
              }}
            >
              <Option key={0} value={0}>
                0
              </Option>
              <Option key={7} value={7}>
                1 week
              </Option>
              <Option key={731} value={731}>
                2 years
              </Option>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Alarms enabled</FormLabel>
            <Typography
              component="label"
              startDecorator={
                <Switch
                  disabled={loading}
                  checked={alarmsEnabled}
                  onChange={(event) => setAlarmsEnabled(event.target.checked)}
                />
              }
            >
              {alarmsEnabled ? "Enabled" : "Disabled"}
            </Typography>
            <FormHelperText>
              Automated alarms for being over a RAM and CPU threshold, or
              running low on storage space.
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12}>
          <Button onClick={() => deployDbService()} disabled={loading}>
            {(stackStatus?.status || null) === null ? "Deploy" : "Update"}{" "}
            Database
          </Button>
          {(stackStatus?.status || null) !== null && amIRoot && (
            <Button
              onClick={() => setOpenModal(true)}
              disabled={
                loading ||
                (stackStatus?.status || "").indexOf("IN_PROGRESS") > -1 ||
                (stackStatus?.status || "").indexOf("DELETE_FAILED") > -1
              }
              variant="plain"
              color="neutral"
            >
              Remove Database
            </Button>
          )}
        </Grid>
      </Grid>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Are you sure to remove the database?
          </DialogTitle>
          <DialogContent>
            This action will remove the database and all associated resources.
          </DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="danger"
              onClick={() => {
                removeDbService();
                setOpenModal(false);
              }}
            >
              Remove Database
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};
