import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Alert from "@mui/joy/Alert";
import Avatar from "@mui/joy/Avatar";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Grid from "@mui/joy/Grid";
import List from "@mui/joy/List";
import Input from "@mui/joy/Input";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Stack from "@mui/joy/Stack";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import { useEffect, useMemo, useState } from "react";
import { PageLoading } from "../../../components/page_loading";
import { TableFrame } from "../../../components/table_frame";
import {
  Deployment,
  FullDomainNameHostedZone,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import Route53Icon from "./route_53.svg";
import IconButton from "@mui/joy/IconButton";

export const DomainNamesAndCertsComponent = (props: {
  loading: boolean;
  deployment: Deployment | null;
  customDomainEnabled: boolean;
  selfHostedDomain: boolean;
  selfHostedTopDomainName: string;
  queryError: string[];
  removeQueryError: (i: number) => void;
  createHostedZone: (fullDomainName: string) => void;
  changeStatus: string | null;
  hostedZones: FullDomainNameHostedZone[];
  navigateToNext: (() => void) | null;
  navigateToPrevious: (() => void) | null;
  navigateToHelp: null | (() => void);
}) => {
  const {
    loading,
    deployment,
    customDomainEnabled,
    selfHostedDomain,
    selfHostedTopDomainName,
    queryError,
    removeQueryError,
    createHostedZone,
    changeStatus,
    hostedZones,
    navigateToNext,
    navigateToPrevious,
    navigateToHelp,
  } = props;
  const [fullDomainName, setFullDomainName] = useState<string>(
    selfHostedTopDomainName
  );
  useEffect(() => {
    if (deployment !== null) {
      setFullDomainName(deployment.fullDomainName || selfHostedTopDomainName);
    }
  }, [deployment, selfHostedTopDomainName]);
  const locked = useMemo(
    () => deployment === null || deployment?.fullDomainName !== "",
    [deployment]
  );

  return (
    <>
      <PageLoading loading={loading} />
      {queryError.map((e, i) => (
        <Alert
          key={i}
          color="danger"
          variant="soft"
          endDecorator={
            <Button
              color="danger"
              variant="soft"
              onClick={() => removeQueryError(i)}
            >
              DISMISS
            </Button>
          }
          sx={{ mb: 2 }}
        >
          Error: {e}
        </Alert>
      ))}

      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography
            level="h2"
            sx={{ pb: 2 }}
            endDecorator={
              navigateToHelp && (
                <IconButton onClick={navigateToHelp}>
                  <HelpOutlineIcon />
                </IconButton>
              )
            }
          >
            Top DNS Record of this environment
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            This step is about registering a domain name for this specific
            environment in your AWS account using the AWS Route53 service.
          </Typography>
          {!customDomainEnabled && (
            <Typography level="body-md" sx={{ py: 1 }}>
              Waffle sets up the DNS records automatically. If you want to
              manage the DNS records manually, please contact the Waffle team.
            </Typography>
          )}
          {customDomainEnabled && (
            <Typography level="body-md" sx={{ py: 1 }}>
              Each environment has to have a top domain name. Services that have
              public hostnames will be set up to use a subdomain of the
              environment's top domain. You have to own the parent domain name
              of the environment's top domain name. the Some of the stacks
              enable to define additinal alternative domains too. For examaple,
              let's say you own: example.com, then you can set the environment
              root domain to be: development.example.com. This case HTTP REST
              endpoints will be automacitally pointed to:
              api.development.example.com. The be automacitally pointed to:
              api.development.example.com. The frontend will be:
              frontend.development.example.com. Custom alternative frontend
              hostnames (like www.example.app) can be added a later point.
            </Typography>
          )}
        </Grid>
        <Grid xs={12}>
          {!loading && locked && customDomainEnabled && (
            <Alert color="success" variant="soft" sx={{ mb: 2 }}>
              Domain name already set, only showing read-only.
            </Alert>
          )}

          <FormControl sx={{ pt: 2 }}>
            <FormLabel>Top domain name of the environment</FormLabel>
            <Stack spacing={0.5}>
              <Input
                value={fullDomainName}
                disabled={locked || !selfHostedDomain || !customDomainEnabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFullDomainName(event.target.value);
                }}
              />
              <Typography level="body-xs" sx={{ alignSelf: "flex-end" }}>
                {fullDomainName === "" ||
                /^[a-z,0-9,.,\-,_]+$/.test(fullDomainName)
                  ? ""
                  : "Invalid value"}
              </Typography>
            </Stack>
            {customDomainEnabled && (
              <FormHelperText>
                The suggested domain name is a subdomain under the top domain
                name of the related business, where the subdomain should refer
                to the envionment. Like for eaxmple: prod.example.com . You have
                to own the domain of the business (example.com in this example)
                in order to be able to complete this step.
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {customDomainEnabled && hostedZones.length > 0 && (
          <Grid xs={12}>
            <Typography level="h3" sx={{ py: 2 }}>
              You already have the Hosted Zone in place
            </Typography>
            <List>
              {hostedZones.map((hz, i) => (
                <ListItem key={hz.hostedZoneId || i}>
                  <ListItemButton
                    component="a"
                    href={`https://${deployment?.awsRegion}.console.aws.amazon.com/route53/v2/hostedzones?region=${deployment?.awsRegion}#ListRecordSets/${hz.hostedZoneId}`}
                  >
                    <ListItemDecorator>
                      <Avatar src={Route53Icon} />
                    </ListItemDecorator>
                    <ListItemContent sx={{ pl: 2 }}>
                      <Typography level="title-sm">
                        Route53 Hosted Zone: {hz.hostedZoneId}
                      </Typography>
                    </ListItemContent>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Grid>
        )}

        {customDomainEnabled && hostedZones.length === 0 && (
          <Grid xs={12}>
            <Typography level="h3" sx={{ py: 2 }}>
              Create DNS records
            </Typography>
            <Typography level="body-md" sx={{ py: 1 }}>
              Create the Route53 Hosted Zone for {fullDomainName}.
            </Typography>
            {changeStatus === null && (
              <Button
                size="lg"
                disabled={loading || changeStatus !== null}
                onClick={() => createHostedZone(fullDomainName)}
                sx={{ mt: 2 }}
              >
                Create DNS Records
              </Button>
            )}
            {changeStatus !== null && (
              <Typography level="body-md" sx={{ py: 1 }}>
                Creating the hosted zone now. Status:{" "}
                <Chip size="sm" color="warning">
                  {changeStatus}
                </Chip>
              </Typography>
            )}
          </Grid>
        )}

        {selfHostedDomain && hostedZones.length === 1 && (
          <Grid xs={12}>
            <Typography level="body-md" sx={{ py: 1 }}>
              If not done yet then please set the following records at the DNS
              registration of{" "}
              <Typography variant="outlined" color="primary" noWrap>
                {fullDomainName?.split(".").splice(1).join(".")}
              </Typography>{" "}
              - wherever it is hosted.
            </Typography>

            <TableFrame cellHeight={40} tableHeight={200}>
              <Table hoverRow stickyHeader>
                <thead>
                  <tr>
                    <th style={{ width: "100" }}>Host</th>
                    <th style={{ width: "100" }}>Type</th>
                    <th style={{ width: "100" }}>TTL</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {hostedZones[0].nameServers?.map((ns) => (
                    <tr key={ns}>
                      <td>{fullDomainName?.split(".").splice(0).join(".")}</td>
                      <td>NS</td>
                      <td>300</td>
                      <td>{ns}.</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TableFrame>
            <Alert color="neutral" variant="soft" sx={{ my: 2 }}>
              Heads-up: Waffle doesn't check if the NS records above are in
              place. But it's required for the services to work.
            </Alert>
          </Grid>
        )}
        <Grid xs={12} sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {navigateToPrevious !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToPrevious()}
              startDecorator={<ChevronLeftIcon />}
            >
              Back
            </Button>
          )}

          {navigateToNext !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToNext()}
              disabled={hostedZones.length === 0}
              endDecorator={<ChevronRightIcon />}
            >
              Next
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
