import Accordion from "@mui/joy/Accordion";
import AccordionDetails, {
  accordionDetailsClasses,
} from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary, {
  accordionSummaryClasses,
} from "@mui/joy/AccordionSummary";
import Alert from "@mui/joy/Alert";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import { accordionClasses } from "@mui/material/Accordion";
import { PageFrame } from "../../components/page_frame";
import { PageLoading } from "../../components/page_loading";
import { PageTitle } from "../../components/page_title";
import { colors } from "../../layouts/drawer_layout/colors";
import { DeployCdnServiceContainer } from "./deploy_cdn_service/container";
import { DeployCfnServiceContainer } from "./deploy_cfn_service/container";
import { DeployDbServiceContainer } from "./deploy_db_service/container";
import { DeployEcsServiceContainer } from "./deploy_ecs_service/container";
import {
  Deployment,
  ServiceStack,
} from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { DeployEcsWsServiceContainer } from "./deploy_ecs_ws_service/container";

export const ServiceStatusesComponent = (props: {
  loading: boolean;
  stackId: string;
  stack: ServiceStack | null;
  deployments: Deployment[];
  queryError: string[];
  removeQueryError: (i: number) => void;
  wsApiEnabled: boolean;
}) => {
  const {
    loading,
    stackId,
    stack,
    deployments,
    queryError,
    removeQueryError,
    wsApiEnabled,
  } = props;
  return (
    <PageFrame>
      <PageTitle>{stack?.name || stackId}</PageTitle>
      <PageLoading loading={loading} />
      {queryError.map((e, i) => (
        <Alert
          key={i}
          color="danger"
          variant="soft"
          endDecorator={
            <Button
              color="danger"
              variant="soft"
              onClick={() => removeQueryError(i)}
            >
              DISMISS
            </Button>
          }
          sx={{ mb: 2 }}
        >
          Error: {e}
        </Alert>
      ))}
      <Box sx={{ borderRadius: 6 }}>
        {stack &&
          deployments.map((deployment) => (
            <AccordionGroup
              key={deployment.id}
              variant="plain"
              transition="0.2s"
              sx={{
                // maxWidth: 400,
                [`& .${accordionDetailsClasses.content}.${accordionDetailsClasses.expanded}`]:
                  {
                    paddingBlock: "1rem",
                  },
                [`& .${accordionSummaryClasses.button}`]: {
                  paddingBlock: "1rem",
                },
                [`& .${accordionClasses.root}.${accordionClasses.expanded}`]: {
                  bgcolor: colors.grey2,
                  borderRadius: "md",
                  border: "1px solid",
                  borderColor: "background.level2",
                  mb: 2,
                },
                '& [aria-expanded="true"]': {
                  boxShadow: (theme) =>
                    `inset 0 -1px 0 ${theme.vars.palette.divider}`,
                },
              }}
            >
              <Accordion>
                <AccordionSummary>
                  <ListItemContent>
                    <Typography level="title-md">
                      {deployment.name !== ""
                        ? `${deployment.name} (${deployment.id})`
                        : deployment.id}{" "}
                      Environment
                    </Typography>
                    <Typography level="body-sm">
                      Deployment status and parameters in the {deployment.id}{" "}
                      environment
                    </Typography>
                  </ListItemContent>
                </AccordionSummary>
                <AccordionDetails>
                  {stack.type === "ECS" && (
                    <DeployEcsServiceContainer
                      deployment={deployment}
                      stack={stack}
                    />
                  )}
                  {stack.type === "ECS_WS" && wsApiEnabled && (
                    <DeployEcsWsServiceContainer
                      deployment={deployment}
                      stack={stack}
                    />
                  )}
                  {stack.type === "CDN" && (
                    <DeployCdnServiceContainer
                      deployment={deployment}
                      stack={stack}
                    />
                  )}
                  {stack.type === "CFN" && (
                    <DeployCfnServiceContainer
                      deployment={deployment}
                      stack={stack}
                    />
                  )}
                  {stack.type === "DB" && (
                    <DeployDbServiceContainer
                      deployment={deployment}
                      stack={stack}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </AccordionGroup>
          ))}
      </Box>
    </PageFrame>
  );
};
