import Security from "@mui/icons-material/Security";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Snackbar from "@mui/joy/Snackbar";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { ServiceStack } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { PageFrame } from "../../../components/page_frame";
import { PageTitle } from "../../../components/page_title";
import { PageLoading } from "../../../components/page_loading";
import Alert from "@mui/joy/Alert";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Avatar from "@mui/joy/Avatar";
import SvgIcon from "@mui/joy/SvgIcon";

export const AddNewServiceComponent = (props: {
  loading: boolean;
  stacks: ServiceStack[];
  addStackError: string | null;
  resetAddStackError: () => void;
  navigateToEditStack: (stackId: string) => void;
  addStackId: string;
  setAdddStackId: React.Dispatch<React.SetStateAction<string>>;
  addStackType: string;
  setAdddStackType: React.Dispatch<React.SetStateAction<string>>;
  addStack: (stackId: string) => void;
  hasDatabaseEnabled: boolean;
  hasSingleDeploymentEnabled: boolean;
  hasWsApiEnabled: boolean;
  canAddMoreStacks: boolean;
}) => {
  const {
    loading,
    stacks,
    addStackError,
    resetAddStackError,
    // navigateToEditStack,
    addStackId,
    setAdddStackId,
    addStackType,
    setAdddStackType,
    addStack,
    hasDatabaseEnabled,
    canAddMoreStacks,
    hasWsApiEnabled,
  } = props;
  const validId =
    addStackId === "" ||
    (stacks.map((s) => s.id).indexOf(addStackId) === -1 &&
      /^[a-z,0-9]+$/.test(addStackId));
  return (
    <PageFrame>
      <PageTitle>Create a New Service</PageTitle>
      {!canAddMoreStacks && (
        <Alert variant="soft" color="warning">
          Please upgrade your plan to add more services
        </Alert>
      )}
      <Typography>
        A services is a set of AWS resources including a CICD pipeline and some
        kind of a server that runs a backend code. These resources are deployed
        as a CloudFormation stack.
      </Typography>
      <PageLoading loading={loading} />

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={addStackError !== null}
        onClose={resetAddStackError}
      >
        {addStackError}
      </Snackbar>

      <Grid container spacing={2} sx={{ flexGrow: 1, pt: 4 }}>
        <Grid xs={12} sm={6}>
          <Typography level="h3" sx={{ pb: 2 }}>
            Choose a Service ID
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            The stack id can be any string of your choice. It refers to your
            service. You can use this id from your code to find this service.
            Typical examples would be{" "}
            <Typography variant="outlined">engine</Typography> or{" "}
            <Typography variant="outlined">api</Typography>.
          </Typography>
          <FormControl sx={{ maxWidth: 350, pt: 2 }}>
            <FormLabel>New Service ID</FormLabel>
            <Stack spacing={0.5}>
              <Input
                disabled={!canAddMoreStacks}
                startDecorator={<Security />}
                placeholder="eg. engine"
                value={addStackId}
                onChange={(event) => setAdddStackId(event.target.value)}
              />
              <Typography level="body-xs" sx={{ alignSelf: "flex-end" }}>
                {validId ? "" : "Invalid value"}
              </Typography>
            </Stack>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <Typography level="h3" sx={{ pb: 2 }}>
            Select a Service Type
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            You can select from 3 stack types: frontend, dockerized backend and
            SQL database. If you need a different type of stack, please don't
            hesitate to reach out to the Waffle team, there is likely a quick
            and feasible solution for you.
          </Typography>

          <FormControl sx={{ maxWidth: 350, pt: 2 }}>
            <FormLabel>New Service Type</FormLabel>
            <Select
              disabled={!canAddMoreStacks}
              placeholder="Select Service Type"
              value={addStackType}
              slotProps={{
                listbox: {
                  sx: {
                    // maxHeight: 300,
                    overflow: "auto", // required for scrolling
                    zIndex: 1201, // Because of the floating drawer on mobile
                  },
                },
              }}
            >
              <Option
                value="ECS"
                onClick={() => {
                  setAdddStackType("ECS");
                }}
              >
                <ListItemDecorator>
                  <Avatar size="sm" sx={{ padding: 0.1 }}>
                    <SvgIcon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                        style={{ width: "70%", height: "70%" }}
                      >
                        <path d="M349.9 236.3h-66.1v-59.4h66.1v59.4zm0-204.3h-66.1v60.7h66.1V32zm78.2 144.8H362v59.4h66.1v-59.4zm-156.3-72.1h-66.1v60.1h66.1v-60.1zm78.1 0h-66.1v60.1h66.1v-60.1zm276.8 100c-14.4-9.7-47.6-13.2-73.1-8.4-3.3-24-16.7-44.9-41.1-63.7l-14-9.3-9.3 14c-18.4 27.8-23.4 73.6-3.7 103.8-8.7 4.7-25.8 11.1-48.4 10.7H2.4c-8.7 50.8 5.8 116.8 44 162.1 37.1 43.9 92.7 66.2 165.4 66.2 157.4 0 273.9-72.5 328.4-204.2 21.4 .4 67.6 .1 91.3-45.2 1.5-2.5 6.6-13.2 8.5-17.1l-13.3-8.9zm-511.1-27.9h-66v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm-78.1-72.1h-66.1v60.1h66.1v-60.1z" />
                      </svg>
                    </SvgIcon>
                  </Avatar>
                </ListItemDecorator>
                Dockerized HTTP Server
              </Option>
              {hasWsApiEnabled && (
                <Option
                  value="ECS_WS"
                  onClick={() => {
                    setAdddStackType("ECS_WS");
                  }}
                >
                  <ListItemDecorator>
                    <Avatar size="sm" sx={{ padding: 0.1 }}>
                      <SvgIcon>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 640 512"
                          style={{ width: "70%", height: "70%" }}
                        >
                          <path d="M349.9 236.3h-66.1v-59.4h66.1v59.4zm0-204.3h-66.1v60.7h66.1V32zm78.2 144.8H362v59.4h66.1v-59.4zm-156.3-72.1h-66.1v60.1h66.1v-60.1zm78.1 0h-66.1v60.1h66.1v-60.1zm276.8 100c-14.4-9.7-47.6-13.2-73.1-8.4-3.3-24-16.7-44.9-41.1-63.7l-14-9.3-9.3 14c-18.4 27.8-23.4 73.6-3.7 103.8-8.7 4.7-25.8 11.1-48.4 10.7H2.4c-8.7 50.8 5.8 116.8 44 162.1 37.1 43.9 92.7 66.2 165.4 66.2 157.4 0 273.9-72.5 328.4-204.2 21.4 .4 67.6 .1 91.3-45.2 1.5-2.5 6.6-13.2 8.5-17.1l-13.3-8.9zm-511.1-27.9h-66v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm78.1 0h-66.1v59.4h66.1v-59.4zm-78.1-72.1h-66.1v60.1h66.1v-60.1z" />
                        </svg>
                      </SvgIcon>
                    </Avatar>
                  </ListItemDecorator>
                  Dockerized WebSocket Server
                </Option>
              )}
              <Option
                value="CDN"
                onClick={() => {
                  setAdddStackType("CDN");
                }}
              >
                <ListItemDecorator>
                  <Avatar size="sm" sx={{ padding: 0.1 }}>
                    <SvgIcon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        style={{ width: "70%", height: "70%" }}
                      >
                        <path
                          fillRule="evenodd"
                          d="M14.447 3.026a.75.75 0 0 1 .527.921l-4.5 16.5a.75.75 0 0 1-1.448-.394l4.5-16.5a.75.75 0 0 1 .921-.527ZM16.72 6.22a.75.75 0 0 1 1.06 0l5.25 5.25a.75.75 0 0 1 0 1.06l-5.25 5.25a.75.75 0 1 1-1.06-1.06L21.44 12l-4.72-4.72a.75.75 0 0 1 0-1.06Zm-9.44 0a.75.75 0 0 1 0 1.06L2.56 12l4.72 4.72a.75.75 0 0 1-1.06 1.06L.97 12.53a.75.75 0 0 1 0-1.06l5.25-5.25a.75.75 0 0 1 1.06 0Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </SvgIcon>
                  </Avatar>
                </ListItemDecorator>
                Web Frontend
              </Option>
              {hasDatabaseEnabled && (
                <Option
                  value="DB"
                  onClick={() => {
                    setAdddStackType("DB");
                  }}
                >
                  <ListItemDecorator>
                    <Avatar size="sm" sx={{ padding: 0.1 }}>
                      <SvgIcon>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          style={{ width: "70%", height: "70%" }}
                        >
                          <path d="M21 6.375c0 2.692-4.03 4.875-9 4.875S3 9.067 3 6.375 7.03 1.5 12 1.5s9 2.183 9 4.875Z" />
                          <path d="M12 12.75c2.685 0 5.19-.586 7.078-1.609a8.283 8.283 0 0 0 1.897-1.384c.016.121.025.244.025.368C21 12.817 16.97 15 12 15s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.285 8.285 0 0 0 1.897 1.384C6.809 12.164 9.315 12.75 12 12.75Z" />
                          <path d="M12 16.5c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 0 0 1.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 0 0 1.897 1.384C6.809 15.914 9.315 16.5 12 16.5Z" />
                          <path d="M12 20.25c2.685 0 5.19-.586 7.078-1.609a8.282 8.282 0 0 0 1.897-1.384c.016.121.025.244.025.368 0 2.692-4.03 4.875-9 4.875s-9-2.183-9-4.875c0-.124.009-.247.025-.368a8.284 8.284 0 0 0 1.897 1.384C6.809 19.664 9.315 20.25 12 20.25Z" />
                        </svg>
                      </SvgIcon>
                    </Avatar>
                  </ListItemDecorator>
                  PostgreSQL Database
                </Option>
              )}
              <Option
                value="CFN"
                onClick={() => {
                  setAdddStackType("CFN");
                }}
              >
                <ListItemDecorator>
                  <Avatar size="sm" sx={{ padding: 0.1 }}>
                    <SvgIcon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        style={{ width: "70%", height: "70%" }}
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 6.75a5.25 5.25 0 0 1 6.775-5.025.75.75 0 0 1 .313 1.248l-3.32 3.319c.063.475.276.934.641 1.299.365.365.824.578 1.3.64l3.318-3.319a.75.75 0 0 1 1.248.313 5.25 5.25 0 0 1-5.472 6.756c-1.018-.086-1.87.1-2.309.634L7.344 21.3A3.298 3.298 0 1 1 2.7 16.657l8.684-7.151c.533-.44.72-1.291.634-2.309A5.342 5.342 0 0 1 12 6.75ZM4.117 19.125a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Z"
                          clipRule="evenodd"
                        />
                        <path d="m10.076 8.64-2.201-2.2V4.874a.75.75 0 0 0-.364-.643l-3.75-2.25a.75.75 0 0 0-.916.113l-.75.75a.75.75 0 0 0-.113.916l2.25 3.75a.75.75 0 0 0 .643.364h1.564l2.062 2.062 1.575-1.297Z" />
                        <path
                          fillRule="evenodd"
                          d="m12.556 17.329 4.183 4.182a3.375 3.375 0 0 0 4.773-4.773l-3.306-3.305a6.803 6.803 0 0 1-1.53.043c-.394-.034-.682-.006-.867.042a.589.589 0 0 0-.167.063l-3.086 3.748Zm3.414-1.36a.75.75 0 0 1 1.06 0l1.875 1.876a.75.75 0 1 1-1.06 1.06L15.97 17.03a.75.75 0 0 1 0-1.06Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </SvgIcon>
                  </Avatar>
                </ListItemDecorator>
                Custom CloudFormation Stack
              </Option>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <Button
            onClick={() => {
              addStack(addStackId);
            }}
            disabled={!validId || addStackId === "" || !canAddMoreStacks}
          >
            Create Service
          </Button>
        </Grid>
      </Grid>
    </PageFrame>
  );
};
