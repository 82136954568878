import { useContext } from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { DrawerContext } from "../../../contexts/drawer/context";
import { ROUTES } from "../../../routes";
import { DrawerComponent } from "./component";
import {
  useGetMyDeploymentsQuery,
  useGetMyServiceStacksQuery,
  useWhoAmIQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { FeaturesContext } from "../../../contexts/features/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import { AlertType } from "../../../contexts/alerts/type";

export const DrawerContainer = (props: { drawerWidth: number }) => {
  const { drawerWidth } = props;
  const { bgwService, selectedOrganizationId } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);
  const { workInProgressFeaturesEnabled } = useContext(FeaturesContext);
  const { closeDrawer } = useContext(DrawerContext);
  const { data: whoAmIData, loading: whoAmILoading } = useWhoAmIQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const {
    data: getMyDeploymentsData,
    loading: getMyDeploymentsLoading,
    error: getMyDeploymentsError,
  } = useGetMyDeploymentsQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const {
    data: getMyServiceStacksQueryData,
    loading: getMyServiceStacksQueryLoading,
  } = useGetMyServiceStacksQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const navigate = useNavigate();
  const location = useLocation();

  const match = matchRoutes(Object.values(ROUTES), location);
  let currentPathName: string | null = null;
  let deploymentIdInPath: string | null = null;
  let serviceIdInPath: string | null = null;
  if (match !== null) {
    const [{ route, params }] = match;
    const matchedPathEntry = Object.entries(ROUTES).find(
      (i) => i[1].path === route.path
    );
    deploymentIdInPath = params.deploymentId || null;
    serviceIdInPath = params.stackId || null;
    currentPathName = matchedPathEntry ? matchedPathEntry[0] : null;
  }

  const navigateToRoot = () => {
    closeDrawer();
    navigate("/", { replace: true });
  };

  const navigateToDashboard = (deploymentId: string) => {
    navigate(`/dashboard/${deploymentId}`, { replace: true });
  };

  const navigateToDeploymentStatus = (newDeploymentId: string) => {
    navigate(`/deployment_steps/${newDeploymentId}/start`, { replace: true });
  };

  const navigateToMyEnvironment = (deploymentId: string) => {
    if (workInProgressFeaturesEnabled) {
      navigate(`/my_environment/${deploymentId}/aws_connection`, {
        replace: true,
      });
    }
  };

  const navigateToServiceStatus = (stackId: string) => {
    navigate(`/service_statuses/${stackId}`, { replace: true });
  };

  const navigateToAddDeployment = () => {
    closeDrawer();
    navigate("/deployment_steps", { replace: true });
  };

  const navigateToAddService = () => {
    navigate(`/service_statuses`, { replace: true });
  };

  const navigateToMyTeam = () => {
    navigate(`/my_team`, { replace: true });
  };

  const navigateToOrganizations = () => {
    closeDrawer();
    navigate("/organizations", { replace: true });
  };

  const navigateToProfile = () => {
    closeDrawer();
    navigate("/profile", { replace: true });
  };

  const navigateToMyAnyStack = () => {
    closeDrawer();
    navigate("/my_any_stack", { replace: true });
  };

  if (getMyDeploymentsError) {
    console.error(
      "DrawerContainer: getMyDeployments fetch error",
      getMyDeploymentsError
    );
  }

  const organizationId =
    whoAmIData?.whoAmI?.role === "ROOT"
      ? selectedOrganizationId
      : whoAmIData?.whoAmI?.organizationId || null;

  return (
    <DrawerComponent
      navigateToRoot={navigateToRoot}
      navigateToDashboard={navigateToDashboard}
      navigateToDeploymentStatus={navigateToDeploymentStatus}
      navigateToMyEnvironment={navigateToMyEnvironment}
      navigateToServiceStatus={navigateToServiceStatus}
      navigateToAddDeployment={navigateToAddDeployment}
      navigateToAddService={navigateToAddService}
      navigateToMyTeam={navigateToMyTeam}
      navigateToOrganizations={navigateToOrganizations}
      navigateToProfile={navigateToProfile}
      navigateToMyAnyStack={navigateToMyAnyStack}
      currentPathName={currentPathName}
      selectedOrganizationId={organizationId}
      deploymentIdInPath={deploymentIdInPath}
      serviceIdInPath={serviceIdInPath}
      userName={whoAmIData?.whoAmI?.name || null}
      email={whoAmIData?.whoAmI?.email || null}
      role={whoAmIData?.whoAmI?.role || null}
      picture={whoAmIData?.whoAmI?.picture || null}
      loading={
        whoAmILoading ||
        getMyDeploymentsLoading ||
        getMyServiceStacksQueryLoading
      }
      drawerWidth={drawerWidth}
      workInProgressFeaturesEnabled={workInProgressFeaturesEnabled}
      deployments={getMyDeploymentsData?.getMyDeployments || []}
      serviceStacks={getMyServiceStacksQueryData?.getMyServiceStacks || []}
    />
  );
};
