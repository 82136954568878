import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import Grid from "@mui/joy/Grid";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import { Fragment, ReactNode } from "react";
import { PageLoading } from "../../../components/page_loading";
import LinearProgress from "@mui/joy/LinearProgress";
import Sheet from "@mui/joy/Sheet";

export const PrivateNetworkingComponent = (props: {
  loading: boolean;
  navigateToHelp: null | (() => void);
  deployers: ReactNode[];
  apiV1Enabled: boolean;
  wsApiEnabled: boolean;
  httpApiEnabled: boolean;
  apiV1Selected: boolean;
  setApiV1Selected: (value: boolean) => void;
  wsApiSelected: boolean;
  setWsApiSelected: (value: boolean) => void;
  httpApiSelected: boolean;
  setHttpApiSelected: (value: boolean) => void;
  navigateToNext: (() => void) | null;
  navigateToPrevious: (() => void) | null;
  deploymentCompleted: boolean;
  percentDone: number;
  deploymentEnabled: boolean;
  setDeploymentEnabled: (value: boolean) => void;
}) => {
  const {
    loading,
    navigateToHelp,
    deployers,
    apiV1Enabled,
    wsApiEnabled,
    httpApiEnabled,
    apiV1Selected,
    setApiV1Selected,
    wsApiSelected,
    setWsApiSelected,
    httpApiSelected,
    setHttpApiSelected,
    navigateToNext,
    navigateToPrevious,
    deploymentCompleted,
    percentDone,
    deploymentEnabled,
    setDeploymentEnabled,
  } = props;
  return (
    <>
      <PageLoading loading={loading} />
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid xs={12}>
          <Typography
            level="h2"
            sx={{ pb: 2 }}
            endDecorator={
              navigateToHelp && (
                <IconButton onClick={navigateToHelp}>
                  <HelpOutlineIcon />
                </IconButton>
              )
            }
          >
            Private Networking
          </Typography>
          <Typography level="body-md" sx={{ py: 1 }}>
            This step is about setting up private networking for your
            environment and providing api gateways for public access
          </Typography>
        </Grid>
        {apiV1Enabled && (
          <Grid
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 300,
              "& > div": { p: 2, borderRadius: "md", display: "flex" },
            }}
          >
            <Sheet variant="outlined">
              <Checkbox
                overlay
                label="Enable HTTP API (V1)"
                checked={apiV1Selected}
                onChange={() => setApiV1Selected(!apiV1Selected)}
              />
            </Sheet>
          </Grid>
        )}
        {httpApiEnabled && (
          <Grid
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 300,
              "& > div": { p: 2, borderRadius: "md", display: "flex" },
            }}
          >
            <Sheet variant="outlined">
              <Checkbox
                overlay
                label="Enable HTTP API (V2)"
                checked={httpApiSelected}
                onChange={() => setHttpApiSelected(!httpApiSelected)}
              />
            </Sheet>
          </Grid>
        )}
        {wsApiEnabled && (
          <Grid
            xs={12}
            sm={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 300,
              "& > div": { p: 2, borderRadius: "md", display: "flex" },
            }}
          >
            <Sheet variant="outlined">
              <Checkbox
                overlay
                label="Enable WebSocket API"
                checked={wsApiSelected}
                onChange={() => setWsApiSelected(!wsApiSelected)}
              />
            </Sheet>
          </Grid>
        )}
        <Grid xs={12}>
          <Typography level="body-md" sx={{ pb: 3 }}>
            By clicking the button below, Waffle will start deploying the stacks
            and resources listed below. It takes about 6-9 minutes to complete.
          </Typography>
          <Button
            size="lg"
            disabled={loading || deploymentEnabled || deploymentCompleted}
            onClick={() => setDeploymentEnabled(true)}
          >
            Start deployment
          </Button>
        </Grid>

        <Grid xs={12}>
          <LinearProgress determinate value={percentDone} />
        </Grid>

        {deployers.map((deployer, index) => (
          <Fragment key={index}>{deployer}</Fragment>
        ))}

        <Grid xs={12} sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {navigateToPrevious !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToPrevious()}
              startDecorator={<ChevronLeftIcon />}
            >
              Back
            </Button>
          )}

          {navigateToNext !== null && (
            <Button
              sx={{ mt: 4 }}
              onClick={() => navigateToNext()}
              disabled={!deploymentCompleted}
              endDecorator={<ChevronRightIcon />}
            >
              Next
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};
