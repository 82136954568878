import { PageWithRightPane } from "../../layouts/page_with_right_pane";
import { MyAnyStackContainer } from "./container";

export const MyAnyStackPage = (props: {}) => {
  return (
    <PageWithRightPane>
      <MyAnyStackContainer />
    </PageWithRightPane>
  );
};
