import { createContext } from "react";
import { FeaturesType } from "./type";

const defaultValue: FeaturesType = {
  loading: true,
  workInProgressFeaturesEnabled: false,
  singleDeployment: false,
  databaseEnabled: false,
  apiV1Enabled: false,
  wsApiEnabled: false,
  httpApiEnabled: false,
  customDomainEnabled: false,
};

export const FeaturesContext = createContext(defaultValue);
